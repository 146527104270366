import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import '@datadog/browser-logs/bundle/datadog-logs'
import React from 'react';
import ReactDOM from 'react-dom';
import RootContainer from './containers/RootContainer';
import './index.css';


datadogLogs.init({
  clientToken: 'pub8d816e5930c21ab821c1bd18f518a859',
  site: 'datadoghq.com',
  service: 'orchard-frontend-rrt',
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  forwardErrorsToLogs: true,
  sampleRate: 100,
})
datadogRum.init({
  applicationId: 'd2f36afc-a15f-470e-84c0-083ffe709d94',
  clientToken: 'pub8d816e5930c21ab821c1bd18f518a859',
  site: 'datadoghq.com',
  service:'orchard-frontend-rrt',
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  sampleRate: 20,
  sessionReplaySampleRate: 5,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'allow'
});
    
datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <React.StrictMode>
    <RootContainer />
  </React.StrictMode>,
  document.getElementById('root'),
);
