import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles((theme) => ({
  arrow: {
    cursor: 'pointer',
    color: theme.palette.grey[400],
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: theme.spacing(6),
    opacity: 0.7,
    filter: 'drop-shadow(0px 2px 1px rgb(0 0 0 / 0.4))',
    [theme.breakpoints.up('mobile')]: {
      fontSize: theme.spacing(8),
    },
    '&:hover': {
      color: theme.palette.grey[800],
    },
  },
  nextArrow: {
    right: 0,
  },
  prevArrow: {
    left: 0,
  },
}));

interface Props {
  slides: React.ReactChild[];
}

const Carousel = ({ slides }: Props) => {
  const classes = useStyles();

  return (
    <ReactCarousel
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      renderArrowPrev={(clickHandler) => (
        <ArrowBackIosIcon
          className={classNames(classes.arrow, classes.prevArrow)}
          onClick={clickHandler}
        />
      )}
      renderArrowNext={(clickHandler) => (
        <ArrowForwardIosIcon
          className={classNames(classes.arrow, classes.nextArrow)}
          onClick={clickHandler}
        />
      )}
      autoPlay
      interval={5000}
      transitionTime={600}
    >
      {slides}
    </ReactCarousel>
  );
};

export default Carousel;
