const messages = {
  ourHistory: {
    en: 'Our History',
    fr: 'Notre Histoire',
  },
  orchardHistory1: {
    en: 'At Orchard, we believe that the modern smartphone is the most important communication tool since the printing press. Despite being lighter than a roll of quarters and occupying less space than a paperback book, smartphones are able to connect us to the things we care about most. We think you should spend more time actually using them and less time resenting how much they cost you.',
    fr: "Chez Orchard, nous pensons que le téléphone intelligent moderne est l'outil de communication le plus important depuis les produits imprimés. Bien qu'ils soient plus légers qu'un rouleau de pièces de monnaie et occupent moins d'espace qu'un livre de poche, les téléphones intelligents sont capables de nous connecter aux choses qui nous tiennent le plus à cœur. Nous pensons que vous devriez passer plus de temps à les utiliser réellement et moins de temps à vous en prendre à ce qu'ils vous coûtent.",
  },
  orchardHistory2: {
    en: 'Orchard was founded in 2013 to make premium devices accessible to more people.',
    fr: 'Orchard a été fondée en 2013 pour rendre les appareils haut de gamme accessibles à un plus grand nombre de personnes.',
  },
  orchardHistory3: {
    en: "It's no secret that used is a more affordable alternative to buying new. By enabling the used market, we bring down the cost of ownership for everyone: someone selling their old phone can get a great return on their used device while buyers can get a much more affordable option.",
    fr: "Ce n'est un secret pour personne : l'usagé est une alternative plus abordable que le neuf. En favorisant le marché de l'usagé, nous réduisons le coût de possession pour tout le monde : une personne qui vend son ancien téléphone peut obtenir un excellent retour sur son appareil usagé, tandis que les acheteurs peuvent bénéficier d'une option beaucoup plus abordable.",
  },
  '5starReviews': {
    en: '5-Star Customer Reviews',
    fr: 'Évaluations 5 étoiles de clients',
  },
  millions: {
    en: 'Millions',
    fr: 'Millions',
  },
  savedForCustomers: {
    en: 'Saved For Customers',
    fr: 'Réservé aux clients',
  },
  phonesPriced: {
    en: 'Phones Priced',
    fr: 'Prix des téléphones',
  },
  orchardBuys: {
    en: 'Orchard buys quality pre-owned devices and sells',
    fr: 'Orchard achète des appareils usagés de qualité et les vend à des êtres humains de qualité, comme vous.',
  },
  ourTeam: {
    en: 'Our Team',
    fr: 'Notre Équipe',
  },
  coFounder: {
    en: 'Co-Founder',
    fr: 'Cofondateur',
  },
  ceo: {
    en: 'CEO',
    fr: 'PDG',
  },
  coo: {
    en: 'COO',
    fr: 'DPT',
  },
  cfo: {
    en: 'CFO',
    fr: 'CFO',
  },
  cto: {
    en: 'CTO',
    fr: 'CTO',
  },
  fullStackDeveloper: {
    en: 'Full Stack Developer',
    fr: 'Développeur Full-Stack',
  },
  appDeveloper: {
    en: 'Application Developer',
    fr: "Développeur d'Applications",
  },
  customerManager: {
    en: 'Customer Advocacy Manager',
    fr: 'Gestionnaire Porte-Parole Clients',
  },
  customerAdvocate: {
    en: 'Customer Advocate',
    fr: 'Porte-Parole Clients',
  },
  logisticsManager: {
    en: 'Logistics Manager',
    fr: 'Gestionnaire Logistique',
  },
};

export default messages;
