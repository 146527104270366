import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Boundaries from '../../components/UI/Boundaries';
import Carousel from '../../components/UI/Carousel';
import LinkButton from '../../components/UI/LinkButton';
import Picture from '../../components/UI/Picture';
import { InventoryContext } from '../../contexts/InventoryContext';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import ProductTypes from '../../types/ProductTypes';
import enMessages from '../../intl/messages';

const phones = [
  {
    model: 'Pixel 5',
    slug: 'pixel-5',
    type: ProductTypes.android,
  },
  {
    model: 'Galaxy S21 5G',
    slug: 'galaxy-s21 5G',
    type: ProductTypes.android,
  },
  {
    model: 'Pixel 4a',
    slug: 'pixel-4a',
    type: ProductTypes.android,
  },
  {
    model: 'Galaxy S10',
    slug: 'galaxy-s10',
    type: ProductTypes.android,
  },
  {
    model: 'iPhone SE 2022',
    slug: 'iphone-se-2022',
    type: ProductTypes.iPhone,
  },
  {
    model: 'iPhone 11',
    slug: 'iphone-11',
    type: ProductTypes.iPhone,
  },
  {
    model: 'iPhone XR',
    slug: 'iphone-xr',
    type: ProductTypes.iPhone,
  },
  {
    model: 'iPhone 8',
    slug: 'iphone-8',
    type: ProductTypes.iPhone,
  },
];

const useStyles = makeStyles((theme) => ({
  button: {
    width: '90%',
    [theme.breakpoints.up('mobile')]: {
      width: '60%',
    },
  },
  grid: {
    display: 'grid',
    gap: theme.spacing(4),
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateColumns: 'minmax(0, 1fr)',
    [theme.breakpoints.up('desktop')]: {
      gridTemplateColumns: 'minmax(0, 1.5fr) minmax(0, 1fr)',
      gap: theme.spacing(2),
    },
  },
  image: {
    display: 'block',
    height: 'auto',
    width: 'auto !important',
    maxHeight: 350,
    maxWidth: '100%',
    padding: theme.spacing(5),
  },
}));

const PreOwnedItems = () => {
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);
  const { inventory } = useContext(InventoryContext);

  const classes = useStyles();

  const slides = phones.map(({ model, slug, type }) => {
    const items = inventory[type].filter((item) => item.model === model);
    const price = Math.min(...items.map((item) => item.price));

    return (
      <Box
        key={model}
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Picture
          className={classes.image}
          alt={model}
          webp={`https://static.getorchard.com/images/pages/home/${slug}.webp`}
          height={350}
        />
        <LinkButton className={classes.button} to={`/${slug}-for-sale/`}>
          <FormattedMessage id="modelStartingPrice" values={{ model, price }} />
        </LinkButton>
      </Box>
    );
  });

  return (
    <Box py={7.5} px={isMobile ? 4 : 7}>
      <Boundaries>
        <Box
          className={classes.grid}
          width={isDesktop ? '80%' : '100%'}
          m="auto"
        >
          <Box width={isMobile || isDesktop ? '100%' : '80%'} m="auto">
            <Carousel slides={slides} />
          </Box>
          <Box px={2}>
            <Box mb={2}>
              <Typography
                variant={isMobile ? 'h4' : 'h3'}
                color="primary"
                align={isDesktop ? 'inherit' : 'center'}
              >
                <FormattedMessage id="orchardMakesItAffordable" />
              </Typography>
            </Box>
            <Typography
              variant={isMobile ? 'h6' : 'h5'}
              color="textPrimary"
              align={isDesktop ? 'inherit' : 'center'}
            >
              <FormattedMessage id="discountOffStickerPrice" />
            </Typography>
          </Box>
        </Box>
      </Boundaries>
    </Box>
  );
};

export default PreOwnedItems;
