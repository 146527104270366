export default [
  {
    title: 'buyAndSell',
    links: [
      { label: 'myAccount', path: '/dashboard/buying-history/' },
      { label: 'buyInBulk', path: '/buy-in-bulk/', external: true },
      { label: 'shopOurPhones', path: '/search/shop-for-featured-phones/' },
      { label: 'shopOurIpads', path: '/search/shop-for-featured-ipads/' },
      { label: 'sellYourPhone', path: '/sell/' },
      { label: 'sellInBulk', path: '/sell-in-bulk/', external: true },
      { label: 'giftCards', path: '/gift-cards/' },
    ],
  },
  {
    title: 'compare',
    links: [
      { label: 'Gazelle', path: '/orchard-vs-gazelle/', external: true},
      { label: 'Swappa', path: '/orchard-vs-swappa/', external: true},
      { label: 'Glyde', path: '/orchard-vs-glyde/', external: true},
      { label: 'eBay', path: '/orchard-vs-ebay/' },
      { label: 'uSell', path: '/orchard-vs-usell/', external: true },
      { label: 'Craigslist', path: '/orchard-vs-craigslist/', external: true },
    ],
  },
  {
    title: 'company',
    links: [
      { label: 'faqs', path: '/faqs/' },
      { label: 'contactUs', path: '/faqs/contact-us/' },
      { label: 'jobs', path: '/jobs/' },
      { label: 'press', path: '/press/' },
      { label: 'blog', path: '/blog/' },
      { label: 'legal', path: '/legal/', external: true },
    ],
  },
];
