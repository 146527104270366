import { camelizeKeys } from 'humps';
import React, { createContext, useEffect, useState } from 'react';
import api from '../api';
import Accessory from '../types/Accessories';
import Cart, { CartItem } from '../types/Cart';
import { trackSimpleEvent } from '../helpers/analytics';
import { InventoryItem } from '../types/Inventory';

interface AddToCartParams {
  item: InventoryItem;
  quantity: number;
  warranty: boolean;
  battery: boolean;
}

interface Context {
  cart: Cart;
  addToCart: (params: AddToCartParams) => Promise<void>;
  updateCart: (rowid: string, newQuantity: number) => Promise<void>;
  removeFromCart: (rowid: string) => Promise<void>;
  addPromoCode: (code: string) => Promise<void>;
  addAccessory: (accessory: Accessory, quantity: number) => Promise<void>;
  addGiftCard: (credit: number, name: string, message: string) => Promise<void>;
  addOrchardCare90: (credit: number, name: string, message: string) => Promise<void>;
  updateCartFromResponse: (cartItems: Array<CartItem>) => void;
}

export const CartContext = createContext<Context>({
  cart: [],
  addToCart: () => new Promise((resolve) => resolve()),
  updateCart: () => new Promise((resolve) => resolve()),
  removeFromCart: () => new Promise((resolve) => resolve()),
  addPromoCode: () => new Promise((resolve) => resolve()),
  addAccessory: () => new Promise((resolve) => resolve()),
  addGiftCard: () => new Promise((resolve) => resolve()),
  addOrchardCare90: () => new Promise((resolve) => resolve()),
  updateCartFromResponse: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const [cart, setCart] = useState<Cart>(
    window.cartItems?.map((cartItem: CartItem) => camelizeKeys(cartItem)) || [],
  );

  useEffect(() => {
    const fetchCart = async () => {};
    fetchCart();
  }, []);

  const addToCart = async ({
    item,
    quantity,
    warranty,
    battery,
  }: {
    item: InventoryItem;
    quantity: number;
    warranty: boolean;
  }) => {
    const { data } = await api.post(
      'cart-api/add-to-cart/',
      {
        carrier: item.carrierId,
        checkout: true,
        cid: item.carrierId,
        color: item.color,
        href: '/cart/shopping-cart/',
        name: item.name,
        orchardcare: warranty,
        battery,
        price: item.price,
        quantity,
        sale: item.sale,
        sku: item.sku,
      },
      { validateStatus: (status) => status >= 200 && status < 500 },
    );
    if (data.status === 'error') {
      throw new Error(data.message);
    }
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
    return data;
  };

  const updateCart = async (rowid: string, newQuantity: number) => {
    const { data } = await api.post('cart-api/update-item/', {
      rowid,
      qty: newQuantity,
    });
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
  };

  const removeFromCart = async (rowid: string) => {
    const { data } = await api.post('cart-api/remove-item/', { rowid });
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
  };

  const addPromoCode = async (code: string) => {
    const { data } = await api.post(
      'cart-api/add-promo/',
      { promo: code },
      { validateStatus: (status) => status >= 200 && status < 500 },
    );
    if (!data.cartItems) {
      trackSimpleEvent('promo_failed');
      throw new Error(data.message);
    } else {
      trackSimpleEvent('promo_success');
    }
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
  };

  const updateCartFromResponse = (cartItems: Array<CartItem>) => {
    const updatedCart = cartItems.map((cartItem: CartItem) => camelizeKeys(cartItem));
    return updatedCart;
  };

  const addOrchardCare90 = async (orderId:number, email:string) => {
    const { data } = await api.post('cart-api/add-orchard-care-90/', {
      orderId,
      email,
    });
    if (data.status === 'error') {
      throw new Error(data.message);
    }
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
  };

  const addGiftCard = async (credit: number, name: string, message: string) => {
    const { data } = await api.post('cart-api/add-gift-card/', {
      credit,
      name,
      message,
    });
    if (data.status === 'error') {
      throw new Error(data.message);
    }
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
  };
  const addAccessory = async (accessory: Accessory, quantity: number) => {
    const { data } = await api.post('cart-api/add-accessory/', {
      accessory_id: accessory.accessoryId,
      quantity,
    });
    if (data.status === 'error') {
      throw new Error(data.message);
    }
    const updatedCart = data.cartItems;
    setCart(updatedCart.map((cartItem: CartItem) => camelizeKeys(cartItem)));
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        addGiftCard,
        updateCart,
        removeFromCart,
        addPromoCode,
        addAccessory,
        updateCartFromResponse,
        addOrchardCare90
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default Provider;
