export const emailRegex = /^\w+([-.\\+]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/;

export const postalCodeRegex = /^\w\d\w\s?\d\w\d$/;

export const sizeRegex = '\\d+gb';

export default {
  emailRegex,
  postalCodeRegex,
  sizeRegex,
};
