import React from 'react';

interface Props {
  webp: string;
  png?: string;
  className?: string;
  alt?: string;
  styleOuter?: object;
  width?: string | number;
  height?: string | number;
}

const Picture = ({ styleOuter, webp, png: pngSrc, ...rest }: Props) => {
  const {
    className,
    alt,
    width,
    height,
  } = rest;
  const png = pngSrc || webp.replace('.webp', '.png');

  return (
    <picture style={styleOuter}>
      <source srcSet={webp} type="image/webp" />
      <source srcSet={png} type="image/png" />
      <img
        src={png}
        {...{
          className,
          alt,
          width,
          height,
        }}
      />
    </picture>
  );
};

Picture.defaultProps = {
  png: null,
  className: null,
  alt: null,
  width: null,
  height: null,
};
export default Picture;
