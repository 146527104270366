export enum ProductFilters {
  model = 'model',
  size = 'size',
  color = 'color',
  condition = 'condition',
  carrier = 'carrier',
  onSale = 'on-sale',
}

export enum ProductTypeUrls {
  iPhone = 'phones',
  Android = 'android-phones',
  iPad = 'ipads',
}

enum ProductTypes {
  iPhone = 'iPhone',
  android = 'Android',
  iPad = 'iPad',
}

export default ProductTypes;
