const messages = {
  errorJs: {
    en: "Oops...",
    fr: "Oops...",
  },
  unknownError: {
    en: "Something went wrong",
    fr: "Something went wrong",
  },
  error404: {
    en: "Uh oh...",
    fr: "Uh oh...",
  },
  pageNotFound: {
    en: "We can't find that page. Sorry about that -- the team has been notified and we'll get to work on a fix.",
    fr: "We can't find that page. Sorry about that -- the team has been notified and we'll get to work on a fix.",
  },
  inTheMeanTime: {
    en: "In the meantime, let's get you out of here.",
    fr: "In the meantime, let's get you out of here.",
  },
  emailrequired: {
    en: 'Email cannot be blank.',
    fr: 'Le champ “adresse courriel” ne peut pas être vide.',
  },
  firstNamerequired: {
    en: 'First Name cannot be blank.',
    fr: 'Le champ “Prénom” ne peut pas être vide.',
  },
  lastNamerequired: {
    en: 'Last Name cannot be blank.',
    fr: 'Le champ “Nom” ne peut pas être vide.',
  },
  streetrequired: {
    en: 'Street cannot be blank.',
    fr: 'Le champ “Rue” ne peut pas être vide.',
  },
  cityrequired: {
    en: 'City cannot be blank.',
    fr: 'Le champ “Ville” ne peut pas être vide.',
  },
  postalCoderequired: {
    en: 'Postal Code cannot be blank.',
    fr: 'Le champ "Code postal" ne peut pas être vide.',
  },
  postalCodeNoMatch: {
    en: "The postal code does not match what's on file for this card",
    fr: 'Le code postal ne correspond pas à la carte',
  },
  genericError: {
    en: 'There was an error. Please, try again.',
    fr: 'Il y a eu une erreur. Veuillez réessayer',
  },
  requiredValue: {
    en: 'Value is required',
    fr: 'Une valeur est exigée',
  },
  invalidEmail: {
    en: 'You have entered an invalid email address!',
    fr: 'Vous avez saisi une adresse électronique non valide!',
  },
  invalidPostalCode: {
    en: 'Invalid postal code',
    fr: 'Code postal non valide',
  },
  postalCodeLookupError: {
    en: 'Unable to look up postal code',
    fr: 'Impossible de chercher le code postal',
  },
};

export default messages;
