import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ListIcon from '@mui/icons-material/List';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import LocaleLink from '../UI/LocaleLink';
import CartIcon from './CartIcon';
import Logo from './Logo';
import MobileNavBar from './NavBar/MobileNavBar';
import MobileNavOpenFooter from './NavBar/MobileNavOpenFooter';

const useStyles = makeStyles((theme) => ({
  lockIcon: {
    display: 'flex',
    fontSize: theme.spacing(3.5),
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    marginRight: theme.spacing(1),
    marginTop: '-4px',
  },
  menuIcon: {
    display: 'flex',
    fontSize: theme.spacing(3.5),
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    cursor: 'pointer',
  },
}));

const MobileHeader = () => {
  const [openCart, setOpenCart] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const classes = useStyles();

  const toggleCart = () => {
    setOpenMenu(false);
    setOpenCart((prevState) => !prevState);
  };

  const toggleMenu = () => {
    setOpenCart(false);
    setOpenMenu((prevState) => !prevState);
  };

  return (
    <Box
      px={0.5}
    >
      <Box display="flex" px={2}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
        <div
          role="button"
          className={classes.menuIcon}
          onClick={toggleMenu}
          onKeyPress={toggleMenu}
          tabIndex={0}
        >
          {!openMenu &&(<ListIcon sx={{fontSize:"1.4em", color: "#0187A7"}} />)}
          {openMenu &&(<HighlightOffOutlinedIcon sx={{fontSize:"1.4em", color: "#0187A7"}} />)}
        </div>
        <Logo darker />
            <LocaleLink className={classes.menuIcon} to="/cart/shopping-cart/">
              <CartIcon isMobile />
            </LocaleLink>
        </Box>
      </Box>
      <Drawer open={openMenu} onClose={toggleMenu}
        sx={{
          width:"80%",
          [`& .MuiDrawer-paper`]: {
            width: "80%", boxSizing: 'border-box',
            border:1,
            borderRight:0,
            borderBottom:0,
            borderLeft:0,
            borderColor:"#333"
          }
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{height:"100%", paddingTop:"100px"}}>
          <Box display="flex">
            <MobileNavBar onClose={() => setOpenMenu(false)} />
          </Box>
          <Box display="flex">
            <MobileNavOpenFooter />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileHeader;
