import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import LogoImagePng from '../../assets/images/orchard-logo-maple-leaf.png';
import LogoImage from '../../assets/images/orchard-logo-maple-leaf.webp';
import LogoImageDarkerPng from '../../assets/images/orchard-logo-maple-leaf-darker.png';
import LogoImageDarker from '../../assets/images/orchard-logo-maple-leaf-darker.webp';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import ConditionalWrapper from '../ConditionalWrapper';
import LocaleLink from '../UI/LocaleLink';
import Picture from '../UI/Picture';

const useStyles = makeStyles(() => ({
  image: {
    height: '100%',
  },
}));

interface Props {
  withoutLink?: boolean;
  darker?: boolean;
}

const Logo = ({ darker = false, withoutLink = false }: Props) => {
  const { isDesktop } = useContext(ScreenSizeContext);

  const classes = useStyles();

  return (
    <Box height={isDesktop ? 60 : 50} py={1.7} mr={2}>
      <ConditionalWrapper
        condition={!withoutLink}
        wrapper={(children) => <LocaleLink to="/">{children}</LocaleLink>}
      >
        <Picture
          webp={darker ? LogoImageDarker : LogoImage}
          png={darker ? LogoImageDarkerPng : LogoImagePng}
          className={classes.image}
          alt="Orchard"
          width={isDesktop ? '171px' : '119px'}
          height={isDesktop ? '33' : '23'}
        />
      </ConditionalWrapper>
    </Box>
  );
};

Logo.defaultProps = {
  withoutLink: false,
};

export default Logo;
