const messages = {
  addNewBattery: {
    en: 'Upgrade to a Replacement Battery for $50',
    fr: 'Faites installer une nouvelle batterie pour 50 $'
  },
  mayDelayShipment1to2days: {
    en: 'May delay shipment 1-2 days',
    fr: 'Peut retarder la livraison de 1-2 jours',
  },
  onBackorderItemPage: {
    en: 'Ships in 2-3 weeks',
    fr: 'Expédition sous 2-3 semaines',
  },
  giveTheGift: {
    en: 'Give the gift of mobile freedom',
    fr: 'Give the gift of mobile freedom',
  },
  addItToMyOrder: {
    en: 'Protect my purchase now',
    fr: 'Protect my purchase now',
  },
  pleaseWait: {
    en: 'Please wait...',
    fr: 'Please wait...',
  },
  lastChanceOc: {
    en: 'Last chance to add Orchard Care to your Order!',
    fr: 'Last chance to add Orchard Care to your Order!',
  },
  lastChanceProtect: {
    en: 'Extend your protection by one year for added peace of mind',
    fr: 'Extend your protection by one year for added peace of mind',
  },
  thePerfectWay: {
    en: 'Help someone special toward their next smartphone',
    fr: 'Help someone special toward their next smartphone',
  },
  batteryBullet: {
    en: 'Battery is guaranteed to last for one day of typical usage',
    fr: 'Battery is guaranteed to last for one day of typical usage',
  },
  addGiftCard: {
    en: 'Add Gift Card to your Cart',
    fr: 'Add Gift Card to your Cart',
  },
  elfHeader: {
    en: 'Our latest reviews...',
    fr: 'Ce que nos clients disent de nous...',
  },
  filters: {
    en: 'Filters',
    fr: 'Filtres',
  },
  sortBy: {
    en: `{sortBy, select,
      default { Sort by: Default }
      priceHigh { Sort by: Price - High to Low }
      priceLow { Sort by: Price  - Low to High }
      bestSeller { Sort by: Best Sellers }
      other { Sort by Price: High to Low }
    }`,
    fr: `{sortBy, select,
      default { Sort by: Default }
      priceHigh { Sort by: Price - High to Low }
      priceLow { Sort by: Price  - Low to High }
      bestSeller { Sort by: Best Sellers }
      other { Sort by Price: High to Low }
    }`,
  },
  viewResults: {
    en: 'View Results',
    fr: 'Voir les résultats',
  },
  clearFiltersOddit: {
    en: 'Clear Filters',
    fr: 'Voir les résultats',
  },
  applyFiltersOddit: {
    en: 'View Results',
    fr: 'Voir les résultats',
  },
  deviceCondition: {
    en: `{condition, select,
      BrandNew { This device is in Brand New condition }
      LikeNew { This device is in Like New condition }
      VeryGood { This device is in Very Good condition }
      other { This device is in Good condition }
    }`,
    fr: `{condition, select,
      BrandNew { Cet appareil est Neuf }
      LikeNew { Cet appareil est dans un état comme neuf }
      VeryGood { Cet appareil est en très bon état }
      other { Cet appareil est en bon état }
    }`,
  },
  deviceConditionColon: {
    en: `{condition, select,
      BrandNew { This device is in Brand New condition: }
      LikeNew { This device is in Like New condition: }
      VeryGood { This device is in Very Good condition: }
      other { This device is in Good condition: }
    }`,
    fr: `{condition, select,
      BrandNew { Cet appareil est Neuf: }
      LikeNew { Cet appareil est dans un état comme neuf: }
      VeryGood { Cet appareil est en très bon état: }
      other { Cet appareil est en bon état: }
    }`,
  },
  brandNewDeviceDescription: {
    en: "We guarantee that this phone is Brand New in box, but we cannot guarantee that it is still under manufacturer warranty, as warranty policies vary by manufacturer.",
    fr: "Nous garantissons que cet appareil est Neuf, dans sa boîte d'origine scellée. Nous ne pouvons pas garantir qu'il est toujours éligible à la garantie du fabricant, puisque celles-ci varient selon le fabricant.",
  },
  likeNewDeviceDescription: {
    en: 'This phone shows almost zero evidence of prior wear. The screen is in near-perfect condition, the back casing shows minimal signs of wear, and it is fully functional.',
    fr: "Ce téléphone ne présente pratiquement aucune trace d'usure antérieure. L'écran est dans un état presque parfait, la partie arrière montre des signes minimes d'usure, et il est entièrement fonctionnel.",
  },
  veryGoodDeviceDescription: {
    en: 'This device shows minor evidence of prior wear. There may be a few light scratches on the screen, and some scratches and/or nicks on the back casing. That being said, this {product} is fully functional.',
    fr: "Ce téléphone présente des traces mineures d'usure antérieure. Il peut y avoir quelques égratignures légères sur l'écran, et quelques égratignures et/ou entailles sur la partie arrière. Ceci étant dit, ce téléphone est entièrement fonctionnel.",
  },
  goodDeviceDescription: {
    en: 'This {product} shows clear evidence of prior wear. There may be scratches and nicks on the screen and the back casing. That being said, the screen is not cracked, and this {product} is fully functional.',
    fr: "Ce téléphone présente des signes évidents d'usure antérieure. Il peut y avoir des égratignures et des entailles sur l'écran et la partie arrière. Ceci étant dit, l'écran n'est pas fissuré et ce téléphone est entièrement fonctionnel.",
  },
  recommendedFor: {
    en: 'Recommended For: ',
    fr: 'Recommended For: fr',
  },
  bestFor: {
    en: 'Best For: ',
    fr: 'Best For: fr',
  },
  description: {
    en: 'Description: ',
    fr: 'Description: fr',
  },
  brandNewConditionDescriptionExp1: {
    en: "{startWith} who want a Brand New device",
    fr: "People who want a Brand New device fr",
  },
  brandNewConditionDescriptionExp2: {
    en: "Brand New. It comes sealed in its original box with accessories.",
    fr: "Brand New. It comes sealed in its original box with accessories. fr",
  },
  likeNewConditionDescriptionExp1: {
    en: '{startWith} who are very picky about the cosmetic condition of their device.',
    fr: "People who are very picky about the cosmetic condition of their device. fr",
  },
  likeNewConditionDescriptionExp2: {
    en: 'Perfect or near-perfect cosmetic condition. Any (very minor) imperfections will be on the sides or back of the phone. Screen is flawless, or has a single, tiny imperfection.',
    fr: 'Perfect or near-perfect cosmetic condition. Any (very minor) imperfections will be on the sides or back of the phone. Screen is flawless, or has a single, tiny imperfection. fr',
  },
  veryGoodConditionDescriptionExp1: {
    en: '{startWith} who want a device that is in good shape, but are not bothered by minor wear and tear (this is our most popular condition grade)',
    fr: 'People who want a device that is in good shape, but are not bothered by minor wear and tear (this is our most popular condition grade) fr',
  },
  veryGoodConditionDescriptionExp2: {
    en: "This device has been well cared for. There are no obvious imperfections, but a close look (or angling the screen to the light in the right way) reveals light scratching on the screen or backplate or nicks on the side bezel.",
    fr: "This device has been well cared for. There are no obvious imperfections, but a close look (or angling the screen to the light in the right way) reveals light scratching on the screen or backplate or nicks on the side bezel. fr",
  },
  goodConditionDescriptionExp1: {
    en: '{startWith} who are more concerned about their device’s performance than its cosmetic condition.',
    fr: 'People who are more concerned about their device’s performance than its cosmetic condition. fr',
  },
  goodConditionDescriptionExp2: {
    en: "This device shows obvious signs of prior wear – scratches on the screen, or dings and scratches on the side bezel and backplate.",
    fr: "This device shows obvious signs of prior wear – scratches on the screen, or dings and scratches on the side bezel and backplate. fr",
  },
  orchardPhonesWorkLikeNew: {
    en: "Every phone Orchard sells is guaranteed to work like new. Our condition grades refer exclusively to the phone's outward appearance",
    fr: "Chaque téléphone vendu par Orchard est garanti comme neuf. Nos notes d'état se réfèrent exclusivement à l'aspect extérieur du téléphone.",
  },
  likeNew: {
    en: 'Like New',
    fr: 'Comme neuf',
  },
  brandNew: {
    en: 'Brand New',
    fr: 'Neuf',
  },
  manuWarranty: {
    en: "We guarantee that this phone is Brand New in box, but we cannot guarantee that it is still under manufacturer warranty, as warranty policies vary by manufacturer.",
    fr: "Nous garantissons que cet appareil est Neuf, dans sa boîte d'origine scellée. Nous ne pouvons pas garantir qu'il est toujours éligible à la garantie du fabricant, puisque celles-ci varient selon le fabricant.",
  },
  fourteenDay: {
    en: "14-day return policy for buyer's remorse -- returns will only be accepted for unopened, sealed devices",
    fr: "Retours acceptés sous 14 jours si vous changez d'avis -- les retours ne seront acceptés que pour les appareils scellés dans leur emballage d'origine, non-ouvert.",
  },
  thisIsBrandNew: {
    en: '90-day warranty on all device functionality',
    fr: "Garantie de 90 jours pour tout problème de fonctionnalité",
  },
  brandNewConditionDescription: {
    en: "We guarantee that this phone is Brand New in box, but we cannot guarantee that it is still under manufacturer warranty, as warranty policies vary by manufacturer.",
    fr: "Nous garantissons que cet appareil est Neuf, dans sa boîte d'origine scellée. Nous ne pouvons pas garantir qu'il est toujours éligible à la garantie du fabricant, puisque celles-ci varient selon le fabricant.",
  },
  likeNewConditionDescription: {
    en: 'A phone showing almost zero evidence of prior wear. The screen is in near-perfect condition, the back casing shows minimal signs of wear.',
    fr: "Un téléphone qui ne présente pratiquement aucune trace d'usure antérieure. L'écran est dans un état presque parfait, la partie arrière montre des signes minimes d'usure.",
  },
  'brand new': {
    en: 'Brand New',
    fr: 'Neuf',
  },
  'very good': {
    en: 'Very Good',
    fr: 'Très bon',
  },
  'like new': {
    en: 'Like New',
    fr: 'Comme Neuf',
  },
  veryGood: {
    en: 'Very Good',
    fr: 'Très bon',
  },
  veryGoodConditionDescription: {
    en: 'A phone showing minor evidence of prior wear. There may be a few light scratches on the screen, and some scratches and/or nicks on the back casing.',
    fr: "Un téléphone présentant des signes mineurs d'usure antérieure. Il peut y avoir quelques égratignures légères sur l'écran, et quelques égratignures et/ou entailles sur la partie arrière.",
  },
  good: {
    en: 'Good',
    fr: 'Bon',
  },
  goodConditionDescription: {
    en: 'This phone shows clear evidence of prior wear. There may be scratches and nicks on the screen and the back casing.',
    fr: "Ce téléphone présente des signes évidents d'usure antérieure. Il peut y avoir des égratignures et des entailles sur l'écran et la partie arrière.",
  },
  was: {
    en: 'Was:',
    fr: 'Était:',
  },
  retailPrice: {
    en: 'Retail price:',
    fr: 'Prix de vente:',
  },
  paybrightAd: {
    // eslint-disable-next-line no-template-curly-in-string
    en: 'As low as ${paybrightPrice} (plus tax) per month! Choose {logo} as payment method at checkout.',
    // eslint-disable-next-line no-template-curly-in-string
    fr: 'Pour aussi peu que {paybrightPrice}$ (plus taxe) par mois! Choisissez {logo} comme mode de paiement à la caisse.',
  },
  flexitiAd: {
    // eslint-disable-next-line no-template-curly-in-string
    en: 'As low as ${paybrightPrice} (plus tax) per month! Choose {logo} as payment method at checkout.',
    // eslint-disable-next-line no-template-curly-in-string
    fr: 'Pour aussi peu que {paybrightPrice}$ (plus taxe) par mois! Choisissez {logo} comme mode de paiement à la caisse.',
  },
  paybrightAdExp: {
    // eslint-disable-next-line no-template-curly-in-string
    en: '${paybrightPrice} per month with {logo} {infoIcon}',
    // eslint-disable-next-line no-template-curly-in-string
    fr: '{paybrightPrice}$ par mois avec {logo} {infoIcon}',
  },
  worksLikeNew: {
    en: 'Works Like New with a 90-Day Warranty',
    fr: 'Fonctionne comme un neuf avec une garantie de 90 jours.',
  },
  freeShipping: {
    en: 'Free Shipping and Returns',
    fr: 'Livraison et retours gratuits',
  },
  batteryOffer: {
    en: 'Minimum 85% of original battery capacity',
    fr: "Minimum 85% de la capacité de la batterie d'origine",
  },
  allPhonesCleaned: {
    en: 'All devices thoroughly cleaned and disinfected before shipping',
    fr: 'Tous les téléphones sont soigneusement nettoyés et désinfectés avant la livraison.',
  },
  includesChargeCable: {
    en: 'Includes 3rd party charge cable and block',
    fr: 'Comprend un câble de chargement tiers et un bloc',
  },
  learnMore: {
    en: 'Learn More',
    fr: 'En savoir plus',
  },
  addOrchardCare: {
    en: 'Add Orchard Care Premium Extended Warranty for $60',
    fr: 'Ajoutez la garantie prolongée Orchard Care Premium pour 60 $.',
  },
  oneYearCoverage: {
    en: '1 Full Year of Accidental Coverage',
    fr: "Couverture en cas d'accident pour une année complète",
  },
  warrantyDescription: {
    en: 'Orchard Care Premium covers your phone against all functionality issues and accidental damage for 1 full year from your purchase date. See the following table for breakdown:',
    fr: "Orchard Care Premium couvre votre téléphone contre tous les problèmes de fonctionnalité et les dommages accidentels pendant une année complète à compter de la date d'achat. En savoir plus:",
  },
  purchasePrice: {
    en: 'Purchase Price',
    fr: "Prix d'achat",
  },
  repairDeductible: {
    en: 'Repair Deductible',
    fr: 'Franchise de réparation',
  },
  replacementDeductible: {
    en: 'Replacement Deductible',
    fr: 'Franchise de remplacement',
  },
  lessThanValue: {
    en: 'Less than {value}',
    fr: 'Moins que {value}',
  },
  valueOrMore: {
    en: '{value} or more',
    fr: '{value} ou plus',
  },
  forDetailsCallUs: {
    en: 'For more details on coverage and claims, see our FAQ or give us a call at <bold>1-800-736-2703</bold>',
    fr: 'Pour plus de détails sur la couverture et les réclamations, consultez notre FAQ ou appelez- nous au numéro suivant <bold>1-800-736-2703</bold>',
  },
  quantity: {
    en: 'Quantity',
    fr: 'Quantité',
  },
  fewAvailable: {
    en: `({quantity, plural,
      one {Only # available!}
      other {# available}
    })`,
    fr: `({quantity, plural,
      one {1 seul disponible}
      other {# disponible}
    })`,
  },
  manyAvailable: {
    en: '(More than 5 available!)',
    fr: '(Plus de 5 disponibles !)',
  },
  goToYourCart: {
    en: 'Added',
    fr: 'Ajouté',
  },
  buyNow: {
    en: 'Buy Now',
    fr: 'Acheter',
  },
  addToCart: {
    en: 'Add to Cart',
    fr: 'Acheter',
  },
  outOfStock: {
    en: 'Currently Out of Stock',
    fr: 'Actuellement en rupture de stock',
  },
  creditCardsAccepted: {
    en: 'We accept all major credit cards, Visa debit and Paypal',
    fr: 'Nous acceptons les principales cartes de crédit, Visa débit et Paypal',
  },
  itemAdded: {
    en: 'Item added. Redirecting you to cart page.',
    fr: 'Article ajouté - nous vous redirigeons vers votre panier',
  },
  itemAddedGiftCard: {
    en: 'Gift Card added. Redirecting you to checkout.',
    fr: 'Article ajouté - nous vous redirigeons vers votre panier',
  },
  whyOrchard: {
    en: 'Why Orchard',
    fr: 'Pourquoi Orchard',
  },
  specs: {
    en: 'Specs',
    fr: 'Spécifications',
  },
  whyOrchardQuestion: {
    en: 'Why Orchard?',
    fr: 'Pourquoi Orchard?',
  },
  whyOrchardAnswer: {
    en: "Orchard is Canada's #1 reseller of high-quality used smartphones. We've earned a reputation for great quality phones because every phone we sell has gone through a 30-point test by two separate technicians. We're so confident in the quality of our phones that we offer a 90-day No Questions Asked return policy. We'll even pay for return shipping, so your purchase is truly risk-free!",
    fr: "Orchard est le premier revendeur de téléphones intelligents usagés de haute qualité au Canada. Nous avons acquis la réputation d'offrir des téléphones de grande qualité parce que chaque téléphone que nous vendons a été soumis à un test en 30 points par deux techniciens distincts. Nous avons tellement confiance en la qualité de nos téléphones que nous offrons une politique de retour de 90 jours sans questions. Nous payons même les frais de retour, de sorte que votre achat est vraiment sans risque!",
  },
  affordableQuestion: {
    en: 'Orchard makes it affordable',
    fr: 'Orchard fait en sorte que ce soit abordable',
  },
  affordableAnswer: {
    en: 'Get up to 60% off sticker price by shopping our premium pre-owned smartphones. Now you can own awesome hardware while keeping your arm and your leg.',
    fr: "Obtenez jusqu'à 60% de rabais sur le prix en achetant nos téléphones intelligents usagés haut de gamme. Maintenant, vous pouvez posséder du matériel génial sans que ça vous coûte les yeux de la tête.",
  },
  getConnectedQuestion: {
    en: 'Get connected on your own terms',
    fr: 'Se connecter selon vos propres termes',
  },
  getConnectedAnswer: {
    en: 'Bring your Orchard phone to your existing plan or decide on something new. Owning your phone lets you avoid your carrier’s overpriced long-term contracts so you can get the same service for much less.',
    fr: "Apportez votre téléphone Orchard à votre forfait existant ou optez pour un nouveau forfait. Le fait d'être propriétaire de votre téléphone vous permet d'éviter les contrats à long terme trop coûteux de votre fournisseur et d'obtenir le même service pour beaucoup moins cher",
  },
  safeQuestion: {
    en: "It's safe, and truly hassle-free",
    fr: "C'est sécuritaire, et vraiment sans tracas.",
  },
  safeAnswer: {
    en: "Orchard is the only place to buy used with a lifetime blacklist guarantee and unbeatable customer support. Our comprehensive 90-day warranty means if you don't love your new phone, we’ll replace it or give you your money back.",
    fr: "Orchard est le seul endroit où acheter un téléphone usagé avec une garantie de liste noire à vie et un support client imbattable. Notre garantie complète de 90 jours signifie que si vous n'aimez pas votre nouveau téléphone, nous le remplacerons ou vous rembourserons.",
  },
  readMoreQuestion: {
    en: '4.9 Star Rating - 1000+ reviews',
    fr: '4,9 étoiles - 1 000+ évaluations',
  },
  readMoreAnswer: {
    en: 'Read more on {googlePlusLink} or {trustPilotLink}',
    fr: 'En savoir plus sur {googlePlusLink} ou {trustPilotLink}',
  },
  frequentlyAskedQuestions: {
    en: 'Frequently Asked Questions',
    fr: 'Questions fréquentes',
  },
  itemCarrierQuestion: {
    en: 'Will this phone work with my carrier?',
    fr: 'Ce téléphone fonctionnera-t-il avec mon fournisseur?',
  },
  itemCarrierAnswer: {
    en: 'Unlocked devices will work with all Canadian carriers. Locked devices will only work with the carrier that they are locked to. Contact us for more information.',
    fr: "Les appareils déverrouillés fonctionnent avec tous les fournisseurs canadiens. Les appareils verrouillés ne fonctionnent qu'avec le fournisseur auquel ils sont verrouillés. Communiquez avec nous pour plus d'informations.",
  },
  itemContractQuestion: {
    en: 'Is there still a contract associated with this iPhone?',
    fr: 'Y a-t-il toujours un contrat associé à cet iPhone?',
  },
  itemContractAnswer1: {
    en: "No. A wireless contract is associated with a specific person and not the phone itself. Transferring a contract between people takes a lot more than simply buying a phone— usually both parties have to sign a Transfer of Responsibility and other legal documents. This means you're able to buy a used phone without its baggage (the contract).",
    fr: "Non. Un contrat de téléphonie mobile est associé à une personne spécifique et non au téléphone lui-même. Le transfert d'un contrat entre des personnes exige beaucoup plus que le simple achat d'un téléphone. En général, les deux parties doivent signer un transfert de responsabilité et d'autres documents juridiques. Cela signifie que vous pouvez acheter un téléphone usagé sans son bagage (le contrat).",
  },
  itemContractAnswer2: {
    en: "You can use any compatible Orchard iPhone with your existing plan or choose the new plan that's right for you. When activating your Orchard iPhone with your carrier, make sure you mention that you already own a phone, as you can save between $15-$30/month on the price of your plan (depending on plan and carrier).",
    fr: "Vous pouvez utiliser n'importe quel iPhone Orchard compatible avec votre forfait existant ou choisir le nouveau forfait qui vous convient. Lorsque vous activez votre iPhone Orchard auprès de votre fournisseur, veillez à mentionner que vous possédez déjà un téléphone, car vous pouvez économiser entre 15 et 30 $/mois sur le prix de votre forfait (selon le forfait et le fournisseur).",
  },
  itemInventoryQuestion: {
    en: 'Where does this inventory come from?',
    fr: 'D’où vient cet inventaire?',
  },
  itemInventoryAnswer: {
    en: "Orchard's inventory comes from people who sell their phones to Orchard through our iPhone app. The Orchard app ensures that an iPhone is fully functional through a rigorous testing process. We only sell iPhones that pass all our tests, so that our buyers know they are buying a fully functional device.",
    fr: "L'inventaire d’Orchard provient de personnes qui vendent leurs téléphones à Orchard via notre application iPhone. L'application Orchard garantit qu’un iPhone est entièrement fonctionnel grâce à un processus de test rigoureux. Nous ne vendons que des iPhones qui réussissent tous nos tests, afin que nos acheteurs sachent qu’ils achètent un appareil entièrement fonctionnel.",
  },
  itemPhysicalConditionQuestion: {
    en: 'What does the physical condition mean?',
    fr: "Que signifie l'état physique?",
  },
  itemPhysicalConditionAnswer1: {
    en: "The condition Like-New/Very Good/Good each refers to the cosmetic appearance of the phone. We thoroughly test about 30 elements to make sure all the phones we sell are working perfectly, so you won't have any issues with battery life, faulty buttons, etc., regardless of how the condition is marked.",
    fr: "L'état Comme neuf/Très bon/Bon fait référence à l'aspect cosmétique du téléphone. Nous testons minutieusement une trentaine d'éléments pour nous assurer que tous les téléphones que nous vendons fonctionnent parfaitement. Vous n'aurez donc aucun problème de batterie, de boutons défectueux, etc., peu importe l'état indiqué.",
  },
  itemPhysicalConditionAnswer2: {
    en: 'This device shows almost zero evidence of prior wear. The screen is in near-perfect condition, the back casing shows minimal signs of wear, and it is fully functional.',
    fr: "Ce téléphone ne présente pratiquement aucune trace d'usure antérieure. L'écran est dans un état presque parfait, la partie arrière montre des signes minimes d'usure, et il est entièrement fonctionnel.",
  },
  seeOurBlog: {
    en: 'See our blog for more information.',
    fr: "Consultez notre blog pour plus d'informations.",
  },
  itemSetUpQuestion: {
    en: 'How do I get this phone set up with my phone plan?',
    fr: 'Comment puis-je configurer ce téléphone avec mon forfait téléphonique?',
  },
  itemSetUpAnswer1: {
    en: 'Orchard phones are not tied to any specific contract or phone plan, so you can choose the plan that is right for you. To set this phone up with your desired phone plan, you will need a SIM card from your carrier.',
    fr: "Les téléphones Orchard ne sont liés à aucun contrat ou forfait téléphonique spécifique, vous pouvez donc choisir le forfait qui vous convient. Pour configurer ce téléphone avec le forfait téléphonique de votre choix, vous aurez besoin d'une carte SIM de votre fournisseur.",
  },
  itemSetUpAnswer2: {
    en: "If you already have a 'live' SIM card, simply insert it into your new phone to instantly begin using it with your plan.",
    fr: "Si vous possédez déjà une carte SIM « active », il vous suffit de l'insérer dans votre nouveau téléphone pour commencer à l'utiliser immédiatement avec votre forfait.",
  },
  itemSetUpAnswer3: {
    en: 'If you\'re unsure of what size SIM card you\'ll need for this phone, scroll up to the section marked "About this Device" for more information.',
    fr: "Si vous n'êtes pas sûr de la taille de la carte SIM dont vous avez besoin pour ce téléphone, consultez la section « À propos de cet appareil » pour plus d'informations.",
  },
  aboutThisDevice: {
    en: 'About this device',
    fr: 'À propos de cet appareil',
  },
  lightning: {
    en: 'lightning',
    fr: 'la foudre',
  },
  microUsb: {
    en: 'Micro USB',
    fr: 'Micro USB',
  },
  '30 pin': {
    en: '30-Pin',
    fr: '30 broches',
  },
  requiresACharger: {
    en: 'Requires a {chargerType} charger',
    fr: 'Nécessite un chargeur {chargerType}',
  },
  requiresASim: {
    en: 'Requires a {simType} sim',
    fr: 'Nécessite un carte SIM {simType}',
  },
  requiresNoSim: {
    en: 'Requires no SIM card',
    fr: 'Ne nécessite pas de carte SIM',
  },
  certifiedHighQualityPhones: {
    en: 'Certified High Quality Phones',
    fr: 'Téléphones certifiés de haute qualité',
  },
  highQualityCertificationMethod: {
    en: 'Each phone undergoes a rigorous 30-point inspection. Better than a fine-toothed comb, our quality experts combine manual tests that simulate real-world use and software checks to ensure quality.',
    fr: "Chaque téléphone est soumis à une inspection rigoureuse en 30 points. Mieux qu'un peigne fin, nos spécialistes en qualité combinent des tests manuels qui simulent une utilisation réelle et des contrôles logiciels pour garantir la qualité.",
  },
  display: {
    en: 'Display',
    fr: 'Affichage',
  },
  displayGuarantees: {
    en: 'Brilliant display with sharp colours and fully responsive touchscreen.',
    fr: 'Affichage brillant avec des couleurs vives et un écran tactile entièrement réactif.',
  },
  ports: {
    en: 'Ports',
    fr: 'Ports',
  },
  portsGuarantees: {
    en: 'All ports connect, read correctly and are free of debris.',
    fr: 'Tous les ports se connectent, lisent correctement et sont exempts de débris.',
  },
  battery: {
    en: 'Battery',
    fr: 'Batterie',
  },
  batteryGuarantees: {
    en: 'Batteries guaranteed to have a minimum of 80% of original charge capacity.',
    fr: "Batteries garanties d'avoir un minimum de 80 % de la capacité de charge d'origine.",
  },
  connectivity: {
    en: 'Connectivity',
    fr: 'Connectivité',
  },
  connectivityGuarantees: {
    en: 'Wireless network, Wi-Fi, and Bluetooth all maintain strong connections.',
    fr: 'Le réseau sans fil, le Wi-Fi et le Bluetooth maintiennent tous des connexions solides.',
  },
  audio: {
    en: 'Audio',
    fr: 'Audio',
  },
  audioGuarantees: {
    en: 'All speakers output loud, crisp sound. Microphones record high-definition sound.',
    fr: 'Tous les haut-parleurs produisent un son fort et net. Les microphones enregistrent un son haute définition.',
  },
  exterior: {
    en: 'Exterior',
    fr: 'Extérieur',
  },
  exteriorGuarantees: {
    en: 'Casting is thoroughly cleaned and polished, any debris around buttons or openings is removed.',
    fr: 'Le moulage est soigneusement nettoyé et poli, tous les débris autour des boutons ou des ouvertures sont enlevés.',
  },
  cameras: {
    en: 'Cameras',
    fr: 'Caméras',
  },
  camerasGuarantees: {
    en: 'No damage or dust on the lens. Both front and back cameras take, focused vibrant photos.',
    fr: "Aucun dommage ou poussière sur l'objectif. Les caméras avant et arrière prennent des photos nettes et éclatantes.",
  },
  software: {
    en: 'Software',
    fr: 'Logiciel',
  },
  softwareGuarantees: {
    en: 'Factory restored back to activation screen.',
    fr: "Restauré en usine à l'écran d'activation.",
  },
};

export default messages;
