import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  condition: boolean;
  wrapper: (children: React.ReactNode | React.ReactNodeArray) => JSX.Element;
}

const ConditionalWrapper = ({ children, condition, wrapper }: Props) => (
  condition ? wrapper(children) : <>{children}</>
);

export default ConditionalWrapper;
