import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import LocaleLink from '../../UI/LocaleLink';
import Logo from '../Logo';

const useStyles = makeStyles((theme) => ({
  lockIcon: {
    display: 'flex',
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    marginRight: theme.spacing(1),
    marginTop: '-4px',
  },
  langSwitch: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    fontFamily: 'Panton-Bold, sans-serif, serif',
    fontSize: "23px !important",
    textDecoration: 'none',
  },
  menuIcon: {
    display: 'flex',
    color: theme.palette.text.secondary,
    fontFamily: 'Panton-Bold, sans-serif, serif',
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    cursor: 'pointer',
  },
}));

const windowLocale = window.location.href.includes('/fr/') ? 'fr' : 'en';

const MobileNavOpenFooter = () => {
  const classes = useStyles();

  return (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          {windowLocale !== 'fr' && (
            <a
              className={classes.langSwitch}
              href="/fr/"
            >
              Français
            </a>
          )}
          {windowLocale == 'fr' && (
            <a
              className={classes.langSwitch}
              href="/en/"
            >
              English
            </a>
          )}
          <Box display="flex" justifyContent="flex-end">
            <a href="tel:18007362703">
              <HeadsetMicIcon color="primary" sx={{marginRight: "20px", fontSize:"2em"}} />
            </a>
            <a href="mailto:support@getorchard.com">
              <MarkAsUnreadIcon color="primary" sx={{marginRight: "20px", fontSize:"2em"}} />
            </a>
          </Box>
        </Box>
  );
};

export default MobileNavOpenFooter;
