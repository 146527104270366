import React, { createContext } from 'react';
import { GaExperiment } from '../types/Window';

export const ExperimentContext = createContext({
  showSortByVariant: false,
  showItemPageVariant: false,
  showHiddenAccessoriesVariant: false,
  showCableUpgradeHigherVariant: false,
  showShopifyRedirectVariant: false,
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const sortByExp = window.orchardExperiments.find(
    (exp: GaExperiment) => exp.id === 'wuX3mEEpT_iF6_QtkEvbRA');
  const showSortByVariant = typeof(sortByExp) !== 'undefined' && sortByExp.variant === 1;
  const itemPageExp = window.orchardExperiments.find(
    (exp: GaExperiment) => exp.id === 'J7xYedeBQ0aLDbFUQpgvGQ');
  const showItemPageVariant = typeof(itemPageExp) !== 'undefined' && itemPageExp.variant === 1;
  const hiddenAccessoriesExp = window.orchardExperiments.find(
    (exp: GaExperiment) => exp.id === 'GT57FoL9TjGHdKKvlnQ-iQ');
  const showHiddenAccessoriesVariant = typeof(hiddenAccessoriesExp) !== 'undefined' && hiddenAccessoriesExp.variant === 1;
  const cableUpgradeHigherExp = window.orchardExperiments.find(
    (exp: GaExperiment) => exp.id === 'ocBnRYFDT-yVqcsUUuETYA');
  const showCableUpgradeHigherVariant = typeof(cableUpgradeHigherExp) !== 'undefined' && cableUpgradeHigherExp.variant === 1;
  const shopifyRedirectExp = window.orchardExperiments.find(
    (exp: GaExperiment) => exp.id === 'shopifyRedirect');
  const showShopifyRedirectVariant = typeof(shopifyRedirectExp) !== 'undefined' && shopifyRedirectExp.variant === 1;
  console.log(typeof(shopifyRedirectExp), shopifyRedirectExp.variant, showShopifyRedirectVariant);


  return (
    <ExperimentContext.Provider value={{ showSortByVariant, showItemPageVariant, showHiddenAccessoriesVariant, showCableUpgradeHigherVariant, showShopifyRedirectVariant }}>
      {children}
    </ExperimentContext.Provider>
  );
};

export default Provider;
