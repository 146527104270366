import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import api from '../../api';
import { PopupContext } from '../../contexts/PopupContext';
import { PopupTypes } from '../../types/Popup';
import RhfSubscriptionForm from '../components/RhfSubscriptionForm';
import { trackSimpleEvent } from '../../helpers/analytics';

const SubscriptionContainer = () => {
  const { showPopup } = useContext(PopupContext);
  const intl = useIntl();
  const methods = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });
  const { handleSubmit, setValue } = methods;

  const onSubmit = handleSubmit(
    async (formData) => {
      try {
        await api.post('search-api/newsletter/', formData);
        setValue('email', '');
        showPopup(intl.formatMessage({ id: 'subscriptionSuccesful' }), PopupTypes.SUCCESS);
        trackSimpleEvent('newsletter-subscription-success');
      } catch (e) {
        showPopup(intl.formatMessage({ id: 'genericError' }), PopupTypes.ERROR);
        trackSimpleEvent('newsletter-subscription-error');
      }
    },
    () => {
      showPopup(intl.formatMessage({ id: 'invalidEmail' }), PopupTypes.ERROR);
    },
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <RhfSubscriptionForm onSubmit={onSubmit} />
    </FormProvider>
  );
};

export default SubscriptionContainer;
