const messages = {
  comeVisitUs: {
    en: 'Come visit us!',
    fr: 'Venez nous rencontrer!',
  },
  weAreLocatedAt: {
    en: "We're located at",
    fr: 'Nous sommes situés au',
  },
  orchardAddress: {
    en: '213 STERLING RD, UNIT 110 {br} TORONTO, ON {br} M6R 2B2',
    fr: '213 STERLING RD, UNIT 110 {br} TORONTO, ON {br} M6R 2B2',
  },
  openByAppointment: {
    en: 'Currently, Orchard is open by appointment only from 10 AM to 6 PM, Monday through Sunday.',
    fr: 'Orchard est présentement ouvert sur rendez-vous uniquement de 10h à 18h, du lundi au dimanche.',
  },
  orderOnlineFirst: {
    en:
      "If you'd like to buy a phone, we encourage you to place an order online first -- that way, the phone will be ready for you when you arrive. "
      + 'Just choose "In-store Pickup" as your shipping method at checkout, and your order confirmation email will include a link to schedule your pickup.',
    fr:
      "Si vous souhaitez acheter un téléphone, nous vous encourageons à passer d'abord une commande en ligne - de cette façon, le téléphone sera prêt pour vous lorsque vous arriverez. "
      + 'Il suffit de choisir « Ramassage en magasin » comme méthode d’expédition à la caisse, et votre courriel de confirmation de commande comprendra un lien pour planifier votre ramassage.',
  },
  scheduleWalkInAppointment: {
    en: "But if you think you'll need some in-person assistance with buying a phone, you can <link>make a purchase appointment</link> so that one of our friendly Customer Advocates will be there to help.",
    fr: "Mais si vous pensez avoir besoin d'aide en personne pour acheter un téléphone, vous pouvez prendre rendez-vous avec l’un de nos sympathiques conseillers à la clientèle qui sera là pour vous aider.",
  },
  scheduleDropInAppointment: {
    en: 'For other types of appointments, such as repairs or selling a phone, please <link>book here</link>.',
    fr: 'Pour d’autres types de rendez-vous, comme des réparations ou la vente d’un téléphone, veuillez <link>réserver ici.</link>',
  },
  faceCoverRequired: {
    en: 'All customers are required to wear a face covering from nose to chin and use hand sanitizer when inside the store.',
    fr: "Tous les clients sont tenus de se couvrir le visage du nez au menton et d'utiliser un désinfectant pour les mains lorsqu'ils se trouvent à l'intérieur du magasin.",
  },
  findUs: {
    en: 'Find us',
    fr: 'Nous trouver',
  },
  ourNewOfficeLocation: {
    en: 'Our new office is conveniently located on Sterling Rd, just southwest of the Bloor and Lansdowne intersection. It is a quick walk from Lansdowne subway station.',
    fr: 'Notre nouveau bureau est situé sur le chemin Sterling, juste au sud-ouest de l’intersection Bloor et Lansdowne. Il se trouve à quelques pas de la station de métro Lansdowne.',
  },
  howToReachUs: {
    en: 'If you are driving north on Sterling Rd, follow the road until the Perth Avenue intersection. If you are driving from Bloor Street West, turn south at Perth Avenue, follow it until it ends, and make a left onto Sterling Rd. From Sterling Rd, make a right turn at the House of Assembly sign into the parking lot. Continue straight toward the back of the lot.',
    fr: "Si vous prenez votre voiture, à partir de la rue Bloor Ouest, tournez vers le sud à l'avenue Perth jusqu'au chemin Sterling. Notre immeuble sera juste en face de vous. Tournez à gauche sur le chemin Sterling. Si vous roulez vers le nord sur le chemin Sterling, suivez la route jusqu’à l’intersection de l'avenue Perth.",
  },
  pleaseUseOurDedicatedEntrance: {
    en: 'If you are driving north on Sterling Rd, follow the road until the Perth Avenue intersection. If you are driving from Bloor Street West, turn south at Perth Avenue, follow it until it ends, and make a left onto Sterling Rd. From Sterling Rd, make a right turn at the House of Assembly sign into the parking lot. Continue straight toward the back of the lot.',
    fr: "De là, tournez à droite dans le stationnement. Vous verrez d’abord l’entrée principale de l'immeuble à votre droite, puis le stationnement des visiteurs à l’arrière de l'immeuble.",
  },
  ourParkingIsAroundTheBuilding: {
    en: "On your right, you'll see Orchard's door with our logo on it. Our parking is just around the building corner from the door.",
    fr: "Une fois dans l'immeuble, suivez les panneaux dans le couloir jusqu'à notre bureau. Nous sommes la dernière porte à gauche du couloir, dans le bureau 110.",
  },
};

export default messages;
