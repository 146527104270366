const messages = {
  workAtOrchard: {
    en: 'Work at Orchard',
    fr: 'Travailler chez Orchard',
  },
  weChangeTheWayPeopleBuyAndSellElectronics: {
    en: "At Orchard, we are changing the way people buy and sell used electronics. Our singular focus on customer service and delivering great experiences reverberates through every fibre of our company. We are always on the outlook for talented people who want to contribute to our success. Talent isn't everything though: a strong personality fit with the existing team is also a top priority.",
    fr: "Orchard change la façon dont les gens achètent et vendent des appareils électroniques d'occasion. Nous mettons un point d'honneur à fournir de bonnes expériences et un excellent service à la clientèle, et cela se ressent dans toutes les fibres de notre entreprise. Nous sommes toujours à l'affût de nouveaux talents qui souhaiteraient contribuer à notre réussite. Mais le talent ne fait pas tout, et une personnalité en adéquation avec le reste de l'équipe constitue aussi une priorité pour nous.",
  },
  ifItSoundsLikeAPlaceYoudLikeToWork: {
    en: "As a startup with big ambitions, every member of our team has the opportunity to help define our company and grow with us. We work hard but respect each other's lives outside of the office and believe in having fun while at work. If Orchard sounds like a place you'd like to work, let us know at <mail>jobs@getorchard.com</mail>.",
    fr: "En tant que petite entreprise avec de grandes ambitions, chaque membre de notre équipe a l'occasion de contribuer à définir notre entreprise et peut grandir avec nous. Nous travaillons dur, mais nous respectons la vie des autres hors du bureau et nous pensons qu'il est important de s'amuser au travail. Si Orchard vous semble un bon endroit pour travailler, écrivez-nous à <mail>jobs@getorchard.com</mail>.",
  },
  ourOpenPositions: {
    en: 'Our Open Positions',
    fr: 'Nos postes vacants',
  },
  digitalMarketingManager: {
    en: 'Digital Marketing Manager - Full-time',
    fr: 'Responsable du marketing numérique - Temps plein',
  },
  digitalMarketingManagerDescription: {
    en: `
      <b>The Role:</b>
      {br}
      We are searching for a Digital Marketing Manager to join Orchard’s team. This is a newly created position reporting to the Founder. Orchard has healthy ROAS in all major channels and is currently working to scale up marketing spend. The Digital Marketing Manager will become a key contributor in the company and will be responsible for the overall growth effort. 
      {br}
      {br}
      <b>Primary Responsibilities:</b>
      <ul>
        <li>Create and execute a strong growth marketing strategy and execution plan</li>
        <li>Continuously test and experiment to optimize all channels, audiences, bidding strategies, visual creative, and copy</li>
        <li>Monitor, analyze, and report on KPIs to ensure marketing initiatives are driving profitable acquisition</li>
        <li>Use web analytics and business intelligence tools such as Google Analytics, and internal dashboards to better understand Orchard’s customers and apply those insights when designing marketing campaigns</li>
      </ul>
      {br}
      <b>What you bring:</b>
      <ul>
        <li>Strong experience with demand generation, ideally for an e-commerce or DTC business</li>
        <li>Experience developing compelling ad creatives a must; experience with short form video content a nice to have</li>
        <li>Proven ability to think strategically, analytically, and creatively about new ways to reach customers and increase relevance</li>
        <li>Intellectual curiosity, scrappiness, and a drive for optimizing results</li>
        <li>An entrepreneurial spirit and the ability to thrive in a fast-moving environment</li>
      </ul>
      {br}
      <b>What Orchard Offers:</b>
      {br}
      This is an integral role at Orchard, and you will have an outsized impact on the business. You’ll work shoulder-to-shoulder with the founding team to reshape the wireless industry. While Orchard has a high bar in terms of Digital, they are open to accommodating different work styles and schedules.  In return, you can expect full time hours, competitive pay, and benefits. If this sounds interesting, we want to hear from you.
      {br}
      {br}
      Orchard offers a competitive compensation package including options, attractive benefits, and the opportunity to contribute high-impact work as a member of a small, cohesive team.
    `,
    fr: `
      <b>Le poste:</b>
      {br}
      Nous recherchons un ou une Responsable du marketing numérique pour rejoindre l’équipe d’Orchard. Cette mission est une création de poste sous la responsabilité du Fondateur de l’entreprise. Orchard a un bon score ROAS dans tous les canaux principaux et cherche à augmenter ses dépenses marketing. Le ou la Responsable du marketing numérique contribuera de manière significative à la croissance de l’entreprise et sera responsable de l'effort de développement en général.
      {br}
      {br}
      <b>Principales responsabilités:</b>
      <ul>
        <li>Créer et mettre en place une stratégie marketing de croissance et un plan d’exécution forts</li>
        <li>Tester et expérimenter en permanence pour optimiser tous les canaux de communication, les publics, les stratégies d’enchères, la création visuelle et la rédaction</li>
        <li>Surveiller, analyser et rendre compte des KPIs pour s’assurer que les initiatives marketing entraînent des acquisitions rentables</li>        <li>Utiliser les données web et les outils d’intelligence d’entreprise comme Google Analytics et les tableaux de bord internes pour mieux comprendre les clients d’Orchard et mettre ces connaissances en pratique lors de la création de campagnes marketing</li>
      </ul>
      {br}
      <b>Ce que vous apportez:</b>
      <ul>
        <li>Une expérience solide dans la génération de demande, idéalement pour un e-commerce ou une entreprise B2C</li>
        <li>Expérience dans le développement de créations publicitaires percutantes indispensable; expérience dans la création de contenu vidéo court appréciée</li>
        <li>Capacité démontrée à penser de manière stratégique, analytique et créative à de nouvelles façons d’atteindre les clients et d’augmenter la pertinence de l’entreprise</li>
        <li>De la curiosité intellectuelle, de la combativité et une forte envie d’optimiser les résultats</li>
        <li>Un esprit d’initiative et la capacité à s’épanouir dans un environnement toujours en mouvement</li>
      </ul>
      {br}
      <b>Ce qu’Orchard offre:</b>
      {br}
      Ce poste est partie intégrante d’Orchard et vous aurez un impact très fort sur l’entreprise. Vous travaillerez aux côtés de l’équipe fondatrice d’Orchard pour faire bouger les lignes de l’industrie du mobile sans fil. Orchard place la barre assez haut au niveau Numérique, mais nous sommes ouverts à différents styles et méthodes de travail et emplois du temps. Vous pouvez compter sur un temps de travail complet, un salaire compétitif et des avantages. Si cela vous intéresse, nous avons hâte d’en discuter avec vous.
      {br}
      {br}
      Orchard propose un salaire compétitif avec des options, des avantages intéressants, et l’opportunité de contribuer à un travail à fort impact au sein d’une petite équipe soudée.
    `,
  },
  applyForThisPosition: {
    en: 'Apply for this position',
    fr: 'Postuler pour ce poste',
  },
};

export default messages;
