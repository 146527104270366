import React, { createContext, useEffect, useState } from 'react';
import Popup from '../components/UI/Popup';
import { PopupTypes } from '../types/Popup';

export const PopupContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showPopup: (newMessage: string, newType: PopupTypes) => {},
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<PopupTypes>(PopupTypes.ERROR);

  useEffect(() => {
    if (open) {
      const closeInterval = setInterval(() => setOpen(false), 5000);
      return () => clearInterval(closeInterval);
    }
    return () => {};
  }, [open, message, type]);

  const showPopup = (newMessage: string, newType: PopupTypes) => {
    setMessage(newMessage);
    setType(newType);
    setOpen(true);
  };

  return (
    <PopupContext.Provider value={{ showPopup }}>
      <Popup open={open} message={message} type={type} />
      {children}
    </PopupContext.Provider>
  );
};

export default Provider;
