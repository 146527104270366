const messages = {
  latestNews: {
    en: 'Latest News',
    fr: 'Dernières Nouvelles',
  },
  cbcMarketPlace: {
    en: 'CBC Marketplace',
    fr: 'CBC Marketplace',
  },
  testingBestBuy: {
    en: 'Testing Best Buy: Refurbished Electronics',
    fr: 'Testing Best Buy: Refurbished Electronics',
  },
  theTorontoStar: {
    en: 'The Toronto Star',
    fr: 'The Toronto Star',
  },
  gottaBeMobile: {
    en: 'Gotta Be Mobile',
    fr: 'Gotta Be Mobile',
  },
  bgr: {
    en: 'BGR',
    fr: 'BGR',
  },
  mobileSyrup: {
    en: 'MobileSyrup',
    fr: 'MobileSyrup',
  },
  betaKit: {
    en: 'Betakit',
    fr: 'Betakit',
  },
  globalNews: {
    en: 'Global News',
    fr: 'Global News',
  },
  ctvNews: {
    en: 'CTV News',
    fr: 'CTV News',
  },
  printTorontoStar: {
    en: 'PRINT: Toronto Star',
    fr: 'PRINT: Toronto Star',
  },
  videoCityNews: {
    en: 'VIDEO: City News',
    fr: 'VIDEO: City News',
  },
  videoGlobalNews: {
    en: 'VIDEO: Global News',
    fr: 'VIDEO: Global News',
  },
  videoMobileSyrup: {
    en: 'VIDEO: Mobile Syrup',
    fr: 'VIDEO: Mobile Syrup',
  },
  vancouverSun: {
    en: 'Vancouver Sun',
    fr: 'Vancouver Sun',
  },
  videoNtdtv: {
    en: 'VIDEO: NTDTV',
    fr: 'VIDEO: NTDTV',
  },
  universityOfTorontoNews: {
    en: 'University of Toronto News',
    fr: 'University of Toronto News',
  },
  digitalJournal: {
    en: 'Digital Journal',
    fr: 'Digital Journal',
  },
  theGlobeAndMail: {
    en: 'The Globe and Mail',
    fr: 'The Globe and Mail',
  },
  yahooNews: {
    en: 'Yahoo! News',
    fr: 'Yahoo! News',
  },
  pskf: {
    en: 'PSKF',
    fr: 'PSKF',
  },
  macleans: {
    en: "Maclean's",
    fr: "Maclean's",
  },
  compareCellular: {
    en: 'Compare Cellular',
    fr: 'Compare Cellular',
  },
  founder2Be: {
    en: 'Founder2Be',
    fr: 'Founder2Be',
  },
  orchardOffersRosyWay: {
    en: 'Orchard offers rosy way of chucking old phones',
    fr: 'Orchard offers rosy way of chucking old phones',
  },
  iphone6SUpgrade: {
    en: 'iPhone 6S Upgrade: Best Old iPhone Trade-In Values',
    fr: 'iPhone 6S Upgrade: Best Old iPhone Trade-In Values',
  },
  heresWhereToSellYourOldIphone: {
    en: "Buying the iPhone 6s? Here's where to sell your old iPhone for the most cash",
    fr: "Buying the iPhone 6s? Here's where to sell your old iPhone for the most cash",
  },
  howToGetTheBestDealOnTradeIn: {
    en: 'How to Get the Best Deal on an iPhone 6 Trade-In',
    fr: 'How to Get the Best Deal on an iPhone 6 Trade-In',
  },
  howOrchardKeepsShakingUsedPhoneTree: {
    en: 'How Orchard keeps shaking the used iPhone tree',
    fr: 'How Orchard keeps shaking the used iPhone tree',
  },
  orchardLabsReceivesInvestment: {
    en: 'Orchard Labs receives $500,000 from the MLA48 investment fund',
    fr: 'Orchard Labs receives $500,000 from the MLA48 investment fund',
  },
  orchardKnowsItsAllAboutNetwork: {
    en: "Due South: Orchard knows it's all about the network",
    fr: "Due South: Orchard knows it's all about the network",
  },
  canadianPhonePriceIncreaseLeadtsToSurge: {
    en: 'Canadian iPhone price increase leads to surge in secondhand iPhone 5C, 5S sales',
    fr: 'Canadian iPhone price increase leads to surge in secondhand iPhone 5C, 5S sales',
  },
  cleverWaysToReuseOldPhone: {
    en: 'Clever ways to reuse your old iPhone',
    fr: 'Clever ways to reuse your old iPhone',
  },
  stringOfRobberiesComeWithRedFlags: {
    en: 'String of Kijiji robberies come with "red flags" say experts',
    fr: 'String of Kijiji robberies come with "red flags" say experts',
  },
  theresABetterWayToSellYourOldPhone: {
    en: "There's a better way to sell your old iPhone",
    fr: "There's a better way to sell your old iPhone",
  },
  newAppHelpsSellDevices: {
    en: 'New app helps iPhone users sell their devices',
    fr: 'New app helps iPhone users sell their devices',
  },
  whySomeAreCampingOutForNewPhone: {
    en: 'Why some Torontonians are camping out for the new iPhone',
    fr: 'Why some Torontonians are camping out for the new iPhone',
  },
  appleLaunchInPhotos: {
    en: 'Apple iPhone 6 and iPhone 6 Plus launch in photos (and video)',
    fr: 'Apple iPhone 6 and iPhone 6 Plus launch in photos (and video)',
  },
  theresAnAppForSellingYourOldPhone: {
    en: "Selling your old iPhone? There's an app for that",
    fr: "Selling your old iPhone? There's an app for that",
  },
  iPhone6: {
    en: 'iPhone6',
    fr: 'iPhone6',
  },
  isThisTheOnlyAppYoullNeed: {
    en:
      'Is this "the only app you'
      + "'"
      + 'll need" when the iPhone 6 hits stores next week?',
    fr:
      'Is this "the only app you'
      + "'"
      + 'll need" when the iPhone 6 hits stores next week?',
  },
  torontoStartupSeesBillions: {
    en: 'Toronto startup sees billions in used iPhone marketplace',
    fr: 'Toronto startup sees billions in used iPhone marketplace',
  },
  whatYouNeedToKnowAboutOrchard: {
    en: 'What you need to know about the reselling-marketplace Orchard',
    fr: 'What you need to know about the reselling-marketplace Orchard',
  },
  startupSimplifiesBuyingAndSelling: {
    en: 'Startup simplifies process of buying or selling used iPhones',
    fr: 'Startup simplifies process of buying or selling used iPhones',
  },
  thisIsTheOnlyAppYoullNeed: {
    en: "This is the only app you'll need when the iPhone 6 launches",
    fr: "This is the only app you'll need when the iPhone 6 launches",
  },
  orchardMakesItEasyToSellYourPhone: {
    en: 'Orchard makes it easy to sell your iPhone, with an iPhone',
    fr: 'Orchard makes it easy to sell your iPhone, with an iPhone',
  },
  orchardLetsYouSellYourPhoneForItsWorth: {
    en: "The Orchard app lets you sell your iPhone for what it's really worth",
    fr: "The Orchard app lets you sell your iPhone for what it's really worth",
  },
  sockDrawerForSocks: {
    en: 'Use your sock drawer for actual socks: Smartphone resale market booms',
    fr: 'Use your sock drawer for actual socks: Smartphone resale market booms',
  },
  lookingToSellOrchardMakesItEasy: {
    en: 'Looking to Sell Your iPhone? Orchard Makes it Easy.',
    fr: 'Looking to Sell Your iPhone? Orchard Makes it Easy.',
  },
  simpleSocialSales: {
    en: 'Simple, social iPhone sales',
    fr: 'Simple, social iPhone sales',
  },
  mediaResources: {
    en: 'Media Resources',
    fr: 'Media Resources fr',
  },
  pressKit: {
    en: 'Press Kit',
    fr: 'Press Kit fr',
  },
  founderHeadShots: {
    en: 'Founder Head Shots',
    fr: 'Founder Head Shots fr',
  },
  productScreenShots: {
    en: 'Product Screen Shots',
    fr: 'Product Screen Shots fr',
  },
  logos: {
    en: 'Logos',
    fr: 'Logos fr',
  },
};

export default messages;
