const messages = {
  lookingToSellInBulk: {
    en: 'Looking to sell smartphones in bulk?',
    fr: 'Vous cherchez à vendre des téléphones intelligents en vrac?',
  },
  clickHere: {
    en: 'Click here!',
    fr: 'Cliquez ici!',
  },
  getMoneyFromYourOldSmartphone: {
    en: "There's money in your old Smartphone. We are going to help you get it.",
    fr: "Il y a de l'argent dans votre vieux téléphone intelligent. Nous allons vous aider à l'obtenir.",
  },
  clickHereToSellOnline: {
    en: 'Click here to sell online',
    fr: 'Cliquez ici pour vendre en ligne',
  },
  saleStep0Title: {
    en: "We'll pay you more money than any trade-in offer",
    fr: "Nous vous paierons plus que n'importe quelle offre d'échange",
  },
  saleStep1Title: {
    en: '1. Ship your phone to us',
    fr: '1. Envoyez-nous votre téléphone',
  },
  saleStep1Description: {
    en: 'Shipping is free. Box and packing materials included',
    fr: "La livraison est gratuite. La boîte et les matériaux d'emballage sont compris",
  },
  saleStep2Title: {
    en: "2. We'll sell your phone",
    fr: '2. Nous vendrons votre téléphone',
  },
  saleStep2Description: {
    en: "We'll prepare your device for selling and take care of dealing with buyers",
    fr: 'Nous préparerons votre appareil pour la vente et nous nous occuperons de traiter avec les acheteurs.',
  },
  saleStep3Title: {
    en: "3. You'll get paid",
    fr: '3. Vous serez payé',
  },
  saleStep3Description: {
    en: 'Prompt payments via PayPal, check or Interac e-transfer',
    fr: 'Paiements rapides via PayPal, chèque ou virement électronique Interac',
  },
  perfectSuccessRate: {
    en: '100% success rate',
    fr: 'Taux de réussite à 100 %',
  },
  soldEveryPhoneListed: {
    en: 'We have sold every phone listed with Orchard',
    fr: 'Nous avons vendu tous les téléphones inscrits avec Orchard',
  },
  ourArticles: {
    en: 'Our articles',
    fr: 'Nos articles',
  },
  article0Title: {
    en: "Here's Why Your iPhone Battery Dies in Cold Weather",
    fr: "Here's Why Your iPhone Battery Dies in Cold Weather fr",
  },
  article0Preview: {
    en: 'February was the coldest month that many parts of Ontario and Quebec have felt in a really long...',
    fr: 'February was the coldest month that many parts of Ontario and Quebec have felt in a really long... fr',
  },
  article1Title: {
    en: 'Broken Lightning Cable? Make The Next One Last Longer',
    fr: 'Broken Lightning Cable? Make The Next One Last Longer fr',
  },
  article1Preview: {
    en: 'If you own any iPhone, iPad or iPod released since September 2012, then you have a lightning...',
    fr: 'If you own any iPhone, iPad or iPod released since September 2012, then you have a lightning... fr',
  },
  article2Title: {
    en: 'The Definitive Guide to Unlocked Phones',
    fr: 'The Definitive Guide to Unlocked Phones fr',
  },
  article2Preview: {
    en: 'There seems to be a weird mystique around unlocked phones. We all kind of know, somehow...',
    fr: 'There seems to be a weird mystique around unlocked phones. We all kind of know, somehow... fr',
  },
  article3Title: {
    en: 'iPhones and Wind Mobile: 5 things you need to know',
    fr: 'iPhones and Wind Mobile: 5 things you need to know fr',
  },
  article3Preview: {
    en: 'Among (viable) Canadian carriers, Wind Mobile offers by far the cheapest unlimited talk, text, and...',
    fr: 'Among (viable) Canadian carriers, Wind Mobile offers by far the cheapest unlimited talk, text, and... fr',
  },
  readMore: {
    en: 'Read more',
    fr: 'En savoir plus',
  },
  sellWithOrchard: {
    en: 'Sell your device with Orchard',
    fr: 'Vendez votre appareil avec Orchard',
  },
  getPaidMoreThanTradeInOffer: {
    en: "We'll pay you more money than any trade-in offer",
    fr: "Nous vous paierons plus que n'importe quelle offre d'échange",
  },
  deviceTypeQuestion: {
    en: 'What type of device do you have?',
    fr: 'Veuillez sélectionner le modèle de votre appareil',
  },
  iPhone: {
    en: 'Apple iPhone',
    fr: 'Apple iPhone',
  },
  Android: {
    en: 'Android',
    fr: 'Android',
  },
  iPad: {
    en: 'Apple iPad',
    fr: 'Apple iPad',
  },
  deviceModelQuestion: {
    en: "Please select your device's model",
    fr: 'Veuillez sélectionner le modèle de votre appareil',
  },
  deviceCarrierQuestion: {
    en: 'Please select your carrier',
    fr: 'Veuillez sélectionner votre fournisseur',
  },
  deviceSizeQuestion: {
    en: 'Please select the storage capacity of your device',
    fr: 'Veuillez sélectionner la capacité de stockage de votre appareil',
  },
  deviceIssueQuestion: {
    en: 'Are there any problems with your device?',
    fr: 'Are there any problems with your device?',
  },
  issueOption0: {
    en: 'None',
    fr: 'Aucun',
  },
  issueOption0Description: {
    en: 'Phone works just like new!',
    fr: 'Le téléphone fonctionne comme un neuf!',
  },
  issueOption1: {
    en: 'Damaged Screen or Backplate',
    fr: "Dégâts sur l'écran ou au dos",
  },
  issueOption1Description: {
    en: ' ',
    fr: ' ',
  },
  issueOption2: {
    en: 'Issue with the Camera',
    fr: 'Problème de caméra',
  },
  issueOption2Description: {
    en: 'Blurry or black dots on photos',
    fr: 'Flou ou points noirs sur les photos',
  },
  issueOption4: {
    en: 'No Power',
    fr: "Ne s'allume pas",
  },
  issueOption4Description: {
    en: 'Does not power on or has water damage',
    fr: "Ne s'allume pas ou Dégâts des eaux",
  },
  issueOption3: {
    en: 'Other Issues',
    fr: 'Autres problèmes',
  },
  issueOption3Description: {
    en: "Something else that isn't quite right",
    fr: "Quelque chose d'autre cloche",
  },
  deviceConditionQuestion: {
    en: 'What is the cosmetic condition of your device (ignoring any damage)?',
    fr: 'En quel état cosmétique est votre appareil (sans tenir compte des dégâts)?',
  },
  conditionOption1: {
    en: 'Heavy Wear, No Power, or Water Damage',
    fr: "Usure très importante, Ne s'allume pas, ou Dégâts des eaux",
  },
  conditionOption1Description: {
    en: `A phone in this condition needs some TLC before we find it a new home. It'll have damage such as a broken screen, broken buttons, or other problems like:
      <ul>
        <li>Won't turn on</li>
        <li>Water Damage</li>
        <li>Damaged or dead LCD screen</li>
        <li>Jammed or unresponsive buttons</li>
        <li>Shattered glass on screen or backplate</li>
      </ul>
    `,
    fr: `Un téléphone dans cet état a besoin d'un peu d'amour et d'attention avant que nous lui trouvions un nouveau foyer. Il aura des dommages comme un écran cassé, des boutons cassés, ou d'autres problèmes comme :
    <ul>
      <li>Ne s'allume pas</li>
      <li>Dégâts des eaux</li>
      <li>Écran LCD endommagé ou mort</li>
      <li>Boutons bloqués ou non réactifs</li>
      <li>Vitre brisée sur l'écran ou la plaque arrière</li>
    </ul>
  fr`,
  },
  conditionOption2: {
    en: 'Good',
    fr: 'Bon',
  },
  conditionOption2QuickDescription: {
    en: 'Everyday Wear and Tear',
    fr: 'Usure quotidienne',
  },
  conditionOption2Description: {
    en: `It would be hard to miss the signs of wear and tear on the body of a phone in Good condition. If you have a phone in this condition, you'll notice:
      <ul>
        <li>Scrapes or chips along the phone's sides and corners</li>
        <li>Light scratches on the screen that don't interfere with everyday use</li>
        <li>Scuffing on the phone's backplate</li>
      </ul>
    `,
    fr: `Il serait difficile de manquer les signes d'usure sur son corps. Si vous avez un téléphone dans cet état, vous remarquerez :
    <ul>
      <li>Des égratignures ou des écailles le long des côtés et des coins du téléphone</li>
      <li>De légères égratignures sur l'écran qui n'interfèrent pas avec l'utilisation quotidienne</li>
      <li>Éraflures sur la partie arrière du téléphone</li>
    </ul>
  fr`,
  },
  conditionOption3: {
    en: 'Very Good',
    fr: 'Très bon',
  },
  conditionOption3QuickDescription: {
    en: 'Well Kept with Minor Flaws',
    fr: 'Bien entretenu avec défauts mineurs',
  },
  conditionOption3Description: {
    en: `If you've taken great care of your device, you most likely have a phone in Very Good condition. It should look great overall, but a close look should show minor imperfections. Keep an eye out for:
      <ul>
        <li>Minor scuffing or scrapes on the phone's corners</li>
        <li>Light scratches on the backplate</li>
        <li>A screen free of noticeable scratches</li>
      </ul>
    `,
    fr: `Si vous avez pris grand soin de votre appareil, vous avez probablement un téléphone en très bon état. Dans l'ensemble, il devrait sembler excellent, mais un examen attentif devrait montrer des imperfections mineures. Surveillez :
      <ul>
        <li>Des éraflures mineures sur les coins du téléphone</li>
        <li>De légères égratignures sur la plaque arrière</li>
        <li>Un écran sans égratignures apparentes</li>
      </ul>
    fr`,
  },
  conditionOption4: {
    en: 'Like new',
    fr: 'Comme neuf',
  },
  conditionOption4QuickDescription: {
    en: 'Looks Like a New Phone',
    fr: 'On dirait un nouveau téléphone',
  },
  conditionOption4Description: {
    en: `A phone in this condition works looks like the day it came out of the box. Please take off the case – scratches can happen under even the most protective case! If you have a Like New phone, you'll see:
      <ul>
        <li>No scuffs or scrapes on the phone's body</li>
        <li>No signs of wear around volume buttons or external ports</li>
        <li>Screen free of scratches</li>
      </ul>
    `,
    fr: `Un téléphone dans cet état ressemble au jour où il est sorti de la boîte. S'il vous plaît enlever l'étui! Si vous avez un nouveau téléphone, vous verrez :
      <ul>
        <li>Aucune éraflure ou égratignure sur le corps du téléphone</li>
        <li>Aucun signe d'usure autour des boutons de volume ou des ports externes</li>
        <li>Écran sans égratignure</li>
      </ul>
    `,
  },
  conditionOption5: {
    en: 'Brand New In-Box',
    fr: 'Neuf dans sa boîte',
  },
  conditionOption5QuickDescription: {
    en: 'A brand new, unused phone',
    fr: 'Un nouveau téléphone',
  },
  conditionOption5Description: {
    en: `A phone in this condition is Brand New in the original packaging and has never been turned on
      <ul>
        <li>Unused</li>
        <li>Original Box</li>
      </ul>
    `,
    fr: `A phone in this is new and comes in the box:
      <ul>
        <li>Unused</li>
        <li>Original Box</li>
      </ul>
    `,
  },
  moreAboutThisCondition: {
    en: 'More about this condition:',
    fr: 'En savoir plus sur cette condition :',
  },
  watchVideo: {
    en: 'Watch video',
    fr: 'Voir la vidéo',
  },
  hereIsOurOffer: {
    en: 'Here is our offer for your:',
    fr: 'Voici notre offre pour:',
  },
  getPaid: {
    en: 'Get paid',
    fr: 'Être payé',
  },
  continue: {
    en: 'Continue',
    fr: 'Continuer',
  },
  allPhonesAreTested: {
    en: '*All phones are subject to manual quality tests before being approved for sale.',
    fr: "*Tous les téléphones sont soumis à des tests de qualité manuels avant d'être approuvés pour la vente.",
  },
  blacklistedOrStolenPhones: {
    en: '**We do not accept any phones that have been blacklisted or reported lost or stolen.',
    fr: "**Nous n'acceptons aucun téléphone qui a été mis sur la liste noire ou déclaré perdu ou volé.",
  },
  completePhoneRegistration: {
    en: "Please complete your phone's registration below",
    fr: "Veuillez compléter l'enregistrement de votre iPhone ci-dessous",
  },
  youAreSelling: {
    en: 'You are selling a(n)',
    fr: 'Vous vendez un',
  },
  selectPaymentWindow: {
    en: 'Please select the window within which you would like to be paid',
    fr: 'Veuillez sélectionner la fenêtre dans laquelle vous souhaitez être payé',
  },
  longerWaitMorePay: {
    en: 'The longer you wait the more you get paid!',
    fr: 'Plus vous attendez, plus vous êtes payé!',
  },
  fourWeekSale: {
    en: '4-week sale',
    fr: '4 semaines',
  },
  twoWeekSale: {
    en: '2-week sale',
    fr: '2 semaines',
  },
  oneDaySale: {
    en: '1-day sale',
    fr: '1 journée',
  },
  chooseShippingOption: {
    en: 'Choose a shipping option',
    fr: 'Choisissez une option de livraison',
  },
  labelOrBox: {
    en: 'A prepaid shipping label to your email address, or a box to ship your phone in',
    fr: 'Une étiquette de livraison prépayée à votre adresse courriel, ou une boîte dans laquelle expédier votre téléphone',
  },
  sendMeA: {
    en: 'Send me a',
    fr: 'Envoyez-moi',
  },
  label: {
    en: 'Label',
    fr: 'Une étiquette',
  },
  box: {
    en: 'Box',
    fr: 'Une boîte',
  },
  fastest: {
    en: 'Fastest',
    fr: 'Le plus rapide',
  },
  unavailable: {
    en: 'Unavailable',
    fr: 'Indisponible',
  },
  shippingInformation: {
    en: 'Shipping information',
    fr: 'Renseignements de livraison',
  },
  submitListing: {
    en: 'Submit listing',
    fr: "Soumettre l'inscription",
  },
  firstNamePlaceholder: {
    en: 'First name',
    fr: 'Prénom',
  },
  lastNamePlaceholder: {
    en: 'Last name',
    fr: 'Nom',
  },
  streetPlaceholder: {
    en: 'Street address',
    fr: 'Adresse',
  },
  street2Placeholder: {
    en: 'Apartment',
    fr: 'Appartement',
  },
  apartmentPlaceholder: {
    en: 'Apartment',
    fr: 'Appartement',
  },
  cityPlaceholder: {
    en: 'City',
    fr: 'Ville',
  },
  provincePlaceholder: {
    en: 'Province',
    fr: 'Province',
  },
  postalCodePlaceholder: {
    en: 'Postal code',
    fr: 'Code postal',
  },
  province: {
    en: 'Province',
    fr: 'Province',
  },
  listingRegistrationComplete: {
    en: 'Registration Complete. Your Listing ID is {id}',
    fr: "Inscription terminée. Votre numéro d'inscription est {id}",
  },
  heyUser: {
    en: 'Hey {userName}',
    fr: 'Hé {userName}',
  },
  thanksForSellingWithOrchard: {
    en: "Thanks for selling with Orchard! You've successfully registered your phone for sale. Stay tuned— we'll be in touch shortly with information on your shipping materials.",
    fr: "Merci d'avoir vendu avec Orchard! Vous avez inscrit  votre téléphone à vendre avec succès. Restez à l'affût – nous communiquerons sous peu avec vous pour vous fournir des renseignements sur vos documents de livraison.",
  },
  onceWeReceiveYourDevice: {
    // eslint-disable-next-line no-template-curly-in-string
    en: 'Once we have received your device and listed it for sale online, the {window} that you selected in the app will begin counting down. At the expiration of that window, we will send you the ${price} that you are due by e-Transfer.',
    // eslint-disable-next-line no-template-curly-in-string
    fr: "Dès que nous aurons reçu votre appareil et que nous l'aurons mis en vente en ligne, le {window} que vous avez sélectionné dans l'application va commencer à compter vers le bas. À l'expiration de cette fenêtre, nous allons vous envoyer le {price}$ qu'on vous doit par virement Interac.",
  },
  regards: {
    en: 'Regards,',
    fr: 'Cordialement,',
  },
  theOrchardTeam: {
    en: 'The Orchard Team',
    fr: "L'équipe Orchard",
  },
  fourWeeks: {
    en: '4 weeks',
    fr: '4 semaines',
  },
  twoWeeks: {
    en: '2 weeks',
    fr: '2 semaines',
  },
  oneDay: {
    en: '1 day',
    fr: '1 journée',
  },
};
export default messages;
