import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import BbbBadgePng from '../../assets/images/bbb-badge.png';
import BbbBadge from '../../assets/images/bbb-badge.webp';
import WhiteLogoPng from '../../assets/images/logo-white.png';
import WhiteLogo from '../../assets/images/logo-white.webp';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import Boundaries from '../UI/Boundaries';
import LocaleLink from '../UI/LocaleLink';
import Picture from '../UI/Picture';
import footerMenus from './footerMenus';

const useStyles = makeStyles((theme) => ({
  bbbBadge: {
    marginTop: theme.spacing(4),
    marginRight: -theme.spacing(1),
  },
  link: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}));

const BbbAndLinks = () => {
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);

  const classes = useStyles();

  let horizontalPadding = 4;
  let menuRightMargin = 5;
  if (isMobile) {
    horizontalPadding = 2;
    menuRightMargin = 0;
  } else if (isDesktop) {
    horizontalPadding = 8;
    menuRightMargin = 10;
  }

  return (
    <Box bgcolor="grey.500" py={isMobile ? 2 : 6} px={horizontalPadding}>
      <Boundaries>
        <Box
          color="white"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={isMobile ? 1 : 0}
          >
            <Picture
              webp={WhiteLogo}
              png={WhiteLogoPng}
              alt="Orchard"
              width={193}
              height={37}
            />
            <Picture
              className={classes.bbbBadge}
              webp={BbbBadge}
              png={BbbBadgePng}
              alt="BBB Accredited Business"
              width={200}
              height={109}
            />
          </Box>
          <Box
            display="flex"
            justifyContent={isMobile ? 'space-around' : 'flex-end'}
            flexDirection="row"
            flexWrap="wrap"
            m="auto"
          >
            {footerMenus.map((menu) => (
              <Box key={menu.title} mr={menuRightMargin} mb={isMobile ? 3 : 0}>
                <Typography className={classes.title} variant="h5">
                  <FormattedMessage id={menu.title} />
                </Typography>
                {menu.links.map((link) => (
                  !link.external ? (
                    <LocaleLink
                      key={link.path}
                      className={classes.link}
                      to={link.path}
                    >
                      <Typography variant="body2" color="inherit">
                        <FormattedMessage id={link.label} />
                      </Typography>
                    </LocaleLink>
                  ) : (
                    <a
                      key={link.path}
                      className={classes.link}
                      href={link.path}
                    >
                      <Typography variant="body2" color="inherit">
                        <FormattedMessage id={link.label} />
                      </Typography>
                    </a>
                  )
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Boundaries>
    </Box>
  );
};

export default BbbAndLinks;
