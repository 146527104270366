import React, { createContext, useState } from 'react';

interface Context {
  showHeader: boolean;
  showFooter: boolean;
  showCopyright: boolean;
  setShowHeader: (show: boolean) => void;
  setShowFooter: (show: boolean) => void;
  setShowCopyright: (show: boolean) => void;
}

export const HeaderAndFooterContext = createContext<Context>({
  showHeader: true,
  showFooter: true,
  showCopyright: true,
  setShowHeader: () => {},
  setShowFooter: () => {},
  setShowCopyright: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [showCopyright, setShowCopyright] = useState<boolean>(true);

  return (
    <HeaderAndFooterContext.Provider
      value={{ showHeader, setShowHeader, showFooter, setShowFooter, showCopyright, setShowCopyright }}
    >
      {children}
    </HeaderAndFooterContext.Provider>
  );
};

export default Provider;
