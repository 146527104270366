// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Panton-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Panton-Thin.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Panton-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Panton-Extra-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  height: 100vh;\n}\n\n* {\n  box-sizing: border-box;\n}\n\n#root {\n  height: 100%;\n}\n\n@font-face {\n  font-family: 'Panton-Bold';\n  src: local('Panton-Bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n@font-face {\n  font-family: 'Panton-Thin';\n  src: local('Panton-Thin'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'Panton-Regular';\n  src: local('Panton-Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'Panton-Extra-Bold';\n  src: local('Panton-Extra-Bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,iFAA8E;AAChF;AACA;EACE,0BAA0B;EAC1B,iFAA8E;AAChF;;AAEA;EACE,6BAA6B;EAC7B,oFAAoF;AACtF;;AAEA;EACE,gCAAgC;EAChC,uFAA0F;AAC5F","sourcesContent":["@import \"~react-image-gallery/styles/css/image-gallery.css\";\n\nbody {\n  margin: 0;\n  height: 100vh;\n}\n\n* {\n  box-sizing: border-box;\n}\n\n#root {\n  height: 100%;\n}\n\n@font-face {\n  font-family: 'Panton-Bold';\n  src: local('Panton-Bold'), url(./assets/fonts/Panton-Bold.woff) format('woff');\n}\n@font-face {\n  font-family: 'Panton-Thin';\n  src: local('Panton-Thin'), url(./assets/fonts/Panton-Thin.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'Panton-Regular';\n  src: local('Panton-Regular'), url(./assets/fonts/Panton-Regular.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'Panton-Extra-Bold';\n  src: local('Panton-Extra-Bold'), url(./assets/fonts/Panton-Extra-Bold.woff) format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
