import Collapse from '@mui/material/Collapse';
import React, { useContext } from 'react';
import { HeaderAndFooterContext } from '../contexts/HeaderAndFooterContext';
import { PreHeaderContext } from '../contexts/PreHeaderContext';

const PreHeader = () => {
  const { showHeader } = useContext(HeaderAndFooterContext);
  const { content } = useContext(PreHeaderContext);

  return <Collapse in={showHeader}>{content || null}</Collapse>;
};

export default PreHeader;
