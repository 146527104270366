import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { NavigationItem, NavigationMenu } from '../../../types/NavBar';
import MobileNavMenu from './MobileNavMenu';
import MobileNavOpenFooter from './MobileNavOpenFooter';
import navBarItems from './navBarItems';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  active: {
    textDecoration: 'underline !important',
  },
  menuItem: {
    minHeight: 0,
    color: theme.palette.primary.main,
    fontFamily: 'Panton-Bold, sans-serif, serif',
  },
  plainText: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  onClose: () => void;
}

const MobileNavBar = ({ onClose }: Props) => {
  const classes = useStyles();
  const isEnglish = useIntl().locale === 'en';
  const navBar = navBarItems.reduce(
    (acc: React.ReactNodeArray, cur: NavigationItem | NavigationMenu) => {
      if ('subItems' in cur) {
        return acc.concat(
          <MenuItem key={cur.label} classes={{ root: classes.menuItem }}>
            <MobileNavMenu
              label={<FormattedMessage id={cur.label} />}
              items={cur.subItems.map((item) => (
                <MenuItem key={item.path} classes={{ root: classes.menuItem }}>
                  <Typography classes={classes.menuItem}>
                    <NavItem
                      label={<FormattedMessage id={item.label} />}
                      path={item.path}
                      variant="body2"
                      ext={item.ext}
                      lighter
                      showActive
                    />
                  </Typography>
                </MenuItem>
              ))}
            />
          </MenuItem>,
        );
      }

      if (isEnglish && cur.path === '/en/') {
        return acc;
      }
      if (!isEnglish && cur.path === '/fr/') {
        return acc;
      }
      return acc.concat(
        <MenuItem key={cur.path} classes={{ root: classes.menuItem }}>
          <NavItem
            path={cur.path}
            label={<FormattedMessage id={cur.label} />}
            variant="body2"
            ext={cur.ext}
          />
        </MenuItem>,
      );
    },
    [],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <MenuList onClick={onClose}>{navBar}</MenuList>
    </Box>
  );
};

export default MobileNavBar;
