import BiotechOutlined from '@mui/icons-material/BiotechOutlined';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import VerifiedOutlined from '@mui/icons-material/VerifiedOutlined';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

const useStyles = makeStyles((theme) => ({
  valueProps: {
    backgroundColor: '#f6f6f6',
    fontSize: theme.spacing(1.5),
  },
  inlineIcon: {
    verticalAlign: 'bottom',
  },
}));

const MobileValueProps = () => {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  useInterval(() => {
    setCurrent((current + 1) % 15);
  }, 1000);
  return (
    <Box px={0} py={1} className={classes.valueProps}>
      {Math.floor(current / 5) === 0 && (
        <Fade in={current % 5 !== 4} timeout={1000}>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              <BiotechOutlined className={classes.inlineIcon} />
              <FormattedMessage id="valuePropOne" />
            </Typography>
          </Box>
        </Fade>
      )}
      {Math.floor(current / 5) === 1 && (
        <Fade in={current % 5 !== 4} timeout={1000}>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              <VerifiedOutlined className={classes.inlineIcon} />
              &nbsp;
              <FormattedMessage id="valuePropTwo" />
            </Typography>
          </Box>
        </Fade>
      )}
      {Math.floor(current / 5) === 2 && (
        <Fade in={current % 5 !== 4} timeout={1000}>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              <LocalShippingOutlined className={classes.inlineIcon} />
              &nbsp;
              <FormattedMessage id="valuePropThree" />
            </Typography>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default MobileValueProps;
