import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Boundaries from '../../components/UI/Boundaries';
import LinkButton from '../../components/UI/LinkButton';
import LocaleLink from '../../components/UI/LocaleLink';
import Picture from '../../components/UI/Picture';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';

const phones = [
  { model: 'iPhone 8', image: 'iphone-8', price: 1 },
  { model: 'iPhone XR', image: 'iphone-xr', price: 1 },
  { model: 'iPhone 11', image: 'iphone-11', price: 1 },
  { model: 'iPhone SE (2022)', image: 'iphone-se-2022', price: 1 },
  { model: 'Galaxy S10', image: 'galaxy-s10', price: 1 },
  { model: 'Pixel 4a', image: 'pixel-4a', price: 1 },
  { model: 'Galaxy S21', image: 'galaxy-s21-5g', price: 1 },
  { model: 'Pixel 5', image: 'pixel-5', price: 1 },
];

const useStyles = makeStyles((theme) => ({
  ad: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    margin: theme.spacing(2),
    width: theme.spacing(20),
    [theme.breakpoints.up('mobile')]: {
      margin: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    },
    [theme.breakpoints.up('desktop')]: {
      margin: `${theme.spacing(2)} ${theme.spacing(5)}`,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(3),
  },
  phoneModel: {
    fontWeight: 'bold',
  },
}));

const ViewOurPhones = () => {
  const { isMobile } = useContext(ScreenSizeContext);

  const classes = useStyles();

  const ads = phones.map((phone) => (
    <LocaleLink
      key={phone.model}
      to={`/${phone.image}-for-sale`}
      className={classes.ad}
    >
      <Picture
        className={classes.image}
        alt={phone.model}
        webp={`https://static.getorchard.com/images/pages/home/${phone.image}.webp`}
        height={160}
      />
      <Typography className={classes.phoneModel}>{phone.model}</Typography>
    </LocaleLink>
  ));

  return (
    <Box py={7.5} px={isMobile ? 4 : 7}>
      <Boundaries>
        <Box mb={3}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            color="primary"
            align="center"
          >
            <FormattedMessage id="shopOurPhones" />
          </Typography>
        </Box>
        <Typography
          variant={isMobile ? 'h6' : 'h5'}
          color="textPrimary"
          align="center"
          paragraph
        >
          <FormattedMessage id="findTheBestPhone" />
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          m="auto"
          maxWidth={1000}
        >
          {ads}
        </Box>
        <Box mt={2} maxWidth={280} mx="auto">
          <LinkButton to="/search/shop-for-featured-phones/">
            <FormattedMessage id="viewAllOurPhones" />
          </LinkButton>
        </Box>
      </Boundaries>
    </Box>
  );
};

export default ViewOurPhones;
