import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    position: 'fixed !important' as 'fixed',
    transform: `translate(calc(50vw - 50%), ${theme.spacing(2)}) !important`,
    zIndex: 1000,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  paper: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: '#FFC107',
  },
}));

interface Props {
  open: boolean;
  message: string;
  type: 'error' | 'warning' | 'success';
}

const Popup = ({ open, message, type }: Props) => {
  const ref = useRef(null);

  const classes = useStyles();

  return (
    <>
      <div ref={ref} />
      <Popper
        className={classes.root}
        open={open}
        anchorEl={ref.current}
        transition
      >
        {({ TransitionProps }) => {
          const { in: inProp, onEnter, onExited } = TransitionProps || {};
          return (
            <Fade {...{ in: inProp, onEnter, onExited }} timeout={350}>
              <Paper
                className={classnames(classes.paper, classes[type])}
                elevation={0}
              >
                <Typography variant="h6" color="inherit">
                  {message}
                </Typography>
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </>
  );
};

export default Popup;
