import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import LocaleLink from './LocaleLink';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    padding: theme.spacing(1),
    borderRadius: 25,
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
}));

interface Props extends LinkProps {}

const LinkButton = ({ className, children, ...rest }: Props) => {
  const classes = useStyles();

  const {
    component,
    to,
    rel,
    replace,
    innerref,
    target,
  } = rest;

  return (
    <LocaleLink
      className={classNames(classes.button, className)}
      {...{
        component,
        to,
        rel,
        replace,
        innerref,
        target,
      }}
    >
      <Typography color="primary" variant="body1">
        {children}
      </Typography>
    </LocaleLink>
  );
};

export default LinkButton;
