const messages = {
  goodQualityDevicesForPeople: {
    en: 'Orchard gets good quality devices into the hands of good quality people',
    fr: 'Orchard met des appareils de qualité entre les mains de personnes de qualité',
  },
  lookingToBuyOrSell: {
    en: 'Looking to buy or sell a used smart device? Orchard is the online service that makes it easy, affordable, and surprisingly enjoyable. We remove the unnecessary anxiety and cost so you can get connected on your own terms.',
    fr: 'Vous cherchez à acheter ou à vendre un appareil intelligent usagé? Orchard est le service en ligne qui le rend facile, abordable et étonnamment agréable. Nous éliminons l’anxiété et le coût inutiles afin que vous puissiez vous connecter selon vos propres conditions.',
  },
  unlikeOtherResellers: {
    en: 'Unlike other resellers, Orchard offers sellers a price for their phone that actually reflects its market value. This way, more people sell, less devices end up collecting dust, and no one has to haggle with a stranger to buy a used phone ever again.',
    fr: "Contrairement aux autres revendeurs, Orchard offre aux vendeurs un prix pour leur téléphone qui reflète réellement sa valeur marchande. Ainsi, plus de personnes vendent, moins d'appareils dorment sur les tablettes et plus personne n'a à marchander avec un inconnu pour acheter un téléphone usagé.",
  },
  middlemenGetABadRep: {
    en: 'Middlemen get a bad rap these days. But with Orchard in the middle, getting your hands on awesome hardware is a cinch.',
    fr: "Les intermédiaires ont mauvaise réputation de nos jours. Mais avec Orchard comme intermédiaire, mettre la main sur du matériel génial est un jeu d'enfant.",
  },
  howWeDoIt: {
    en: 'How we do it?',
    fr: 'Comment on fait?',
  },
  greatPrices: {
    en: 'Great prices based on hard data',
    fr: 'Des prix intéressants fondés sur des données concrètes',
  },
  greatPricesDescription: {
    en: 'Phones are priced by the Orchard app, which runs a diagnostic on a phone’s functionality. Using this and data from the resale market, we calculate a price for each phone that actually reflects what it is worth.',
    fr: "Des prix intéressants fondés sur des données concrètes. Le prix des téléphones est déterminé par l'application Orchard, qui effectue un diagnostic des fonctionnalités du téléphone. À partir de ce diagnostic et des données du marché de la revente, nous calculons un prix pour chaque téléphone qui reflète réellement sa valeur.",
  },
  humanTested: {
    en: 'Human Tested and Approved',
    fr: 'Testé Humainement et Approuvé',
  },
  humanTestedDescription: {
    en: 'Our quality assurance combines smart analytics with a human touch. Each phone has inspection and servicing done by hand with the end-user in mind.',
    fr: 'Notre assurance qualité combine l’analyse intelligente avec une touche humaine. Chaque téléphone a l’inspection et l’entretien fait à la main en tenant compte de l’utilisateur final.',
  },
  dedicatedSupport: {
    en: 'Dedicated support, standing by',
    fr: 'Soutien dédié par, en attente',
  },
  dedicatedSupportDescription: {
    en: 'Orchard offers unlimited access to device experts. Our open advice and comprehensive warranty gives both buyers and sellers peace of mind.',
    fr: 'Orchard offre un accès illimité aux experts des appareils. Nos conseils ouverts et notre garantie complète procurent une tranquillité d’esprit aux acheteurs et aux vendeurs.',
  },
  lifecycleOfAPhone: {
    en: 'Lifecycle of a Phone',
    fr: 'Cycle de vie d’un téléphone',
  },
  seeAPhonesJourney: {
    en: "See an Orchard phone's journey from start to finish",
    fr: "Découvrez le cheminement d'un téléphone Orchard du début à la fin",
  },
  clickToEnlarge: {
    en: 'Click to enlarge',
    fr: 'Cliquez pour agrandir',
  },
  value: {
    en: 'Value',
    fr: 'Value',
  },
  valueSubtitle1: {
    en: 'How we cut the premium price tag',
    fr: 'Comment nous réduisons le prix élevé',
  },
  valueDescription1: {
    en:
      'Any price tag comes with the question, "But is it actually worth that much?" We remove any doubt. Our phones are priced according to Fair Market Value, which is an average of what each phone will sell for across other online marketplaces. '
      + "That price is the price you pay — nothing more, nothing less. This means our prices are not only affordable, they're fair.",
    fr:
      "Toute étiquette de prix vient avec la question : « Mais est-ce que ça vaut vraiment autant? ». Nous éliminons tout doute. Le prix de nos téléphones est fixé en fonction de la juste valeur marchande, qui correspond à la moyenne du prix de vente de chaque téléphone sur d'autres marchés en ligne"
      + "C'est le prix que vous payez, ni plus ni moins. Cela signifie que nos prix sont non seulement abordables, ils sont justes.",
  },
  valueSubtitle2: {
    en: 'Algorithms: the Secret Sauce',
    fr: 'Algorithmes : la recette secrète',
  },
  valueDescription2: {
    en: "Selling a phone with Orchard starts with the Orchard app. After a series of diagnostic tests, it calculates a price for each phone. Its pricing algorithm takes into account each phone's storage capacity, model, external condition, and other factors. We then deduct a selling fee and generate an in-app quote. Understanding the state of your phone before it reaches us means we can make higher offers. This gets our sellers paid more than any other resale program.",
    fr: "La vente d'un téléphone avec Orchard commence par l'application Orchard. Après une série de tests de diagnostic, elle calcule un prix pour chaque téléphone. Son algorithme de tarification tient compte de la capacité de stockage, du modèle, de l'état extérieur et d'autres facteurs de chaque téléphone. Nous déduisons ensuite des frais de vente et générons un devis dans l'application. Le fait de connaître l'état de votre téléphone avant qu'il ne nous parvienne nous permet de faire des offres plus élevées. Nos vendeurs sont ainsi mieux payés que par tout autre programme de revente.",
  },
  quality: {
    en: 'Quality',
    fr: 'Qualité',
  },
  qualitySubtitle1: {
    en: 'Quality: Guaranteed',
    fr: 'Qualité : assurée',
  },
  qualityDescription1: {
    en: "We're obsessive about quality. That's why perform hands-on tests for every device that comes through our doors. Before being listed for sale, each phone must pass two 30-point quality inspections by our phone experts. Along with using tools to check internal components, they perform manual tests that mirror real world use. Only phones that work like new are added to our inventory.",
    fr: "Nous sommes obsédés par la qualité. C'est pourquoi nous mettons la main sur chaque appareil qui passe nos portes. Avant d'être mis en vente dans notre inventaire, chaque téléphone doit passer deux inspections indépendantes de qualité en 30 points par deux experts. Outre les outils permettant de vérifier les composants internes, des tests manuels sont effectués pour refléter une utilisation réelle. Seuls les téléphones qui fonctionnent comme des appareils neufs sont mis en vente dans notre inventaire.",
  },
  qualitySubtitle2: {
    en: 'Tools of the trade',
    fr: 'Les outils du métier',
  },
  qualityDescription2: {
    en: "Like a book and its cover, you can't judge a smartphone by its screen alone. Tools didn't exist for us to appraise the internal parts of a smartphone, so we built some ourselves. We put some of this technology to work in the Orchard app, which helps our sellers test, price, and register a smartphone for sale with us.",
    fr: "Tout comme un livre et sa couverture, on ne peut pas juger un téléphone intelligent uniquement par son écran. Il n'existait pas d'outils nous permettant d'évaluer les parties internes d'un téléphone intelligent, alors nous en avons élaborés nous-mêmes. Nous avons mis une partie de cette technologie au service de l'application Orchard, qui aide nos vendeurs à tester, à évaluer et à enregistrer un téléphone intelligent à vendre avec nous.",
  },
  service: {
    en: 'Service',
    fr: 'Service',
  },
  serviceSubtitle1: {
    en: "If you don't love it, we'll make it right",
    fr: "Si vous ne l'aimez pas, nous nous efforcerons de vous satisfaire",
  },
  serviceDescription1: {
    en: "Even the most casual phone user needs a reliable, high quality device. That's why we offer a comprehensive 90-day warranty on every phone, with the option to extend to 1 full year. And while some companies (ahem, Apple) make you pay extra for customer support, we offer unlimited access to our team of smartphone experts so you can get the most out of your phone.",
    fr: "Même l'utilisateur de téléphone le plus occasionnel a besoin d'un appareil fiable et de haute qualité. C'est pourquoi nous offrons une garantie complète de 90 jours sur chaque téléphone, avec la possibilité de la prolonger à une année complète. Et alors que certaines entreprises (hum, Apple) vous font payer un supplément pour le support client, nous offrons un accès illimité à notre équipe d'experts en téléphones intelligents pour que vous puissiez tirer le meilleur parti de votre téléphone.",
  },
  serviceSubtitle2: {
    en: 'Sell your phone. Minus the rigamarole.',
    fr: 'Vendez votre téléphone. Sans tracas.',
  },
  serviceDescription2: {
    en: "No one should have to meet a stranger at a subway station to sell their phone. That's why we take care of everything involved in selling a phone. You get paid and we take full responsibility in making sure your phone's new owner loves it as much as you did.",
    fr: "Personne ne devrait avoir à rencontrer un inconnu dans une station de métro pour vendre son téléphone. C'est pourquoi nous nous occupons de tout ce qui concerne la vente d'un téléphone. Vous êtes payé et nous prenons l'entière responsabilité de nous assurer que le nouveau propriétaire de votre téléphone l'aime autant que vous.",
  },
  buyers: {
    en: 'Buyers',
    fr: 'Acheteurs',
  },
  phonesAsLowAs: {
    // eslint-disable-next-line no-template-curly-in-string
    en: 'Phones starting as low as ${value}',
    // eslint-disable-next-line no-template-curly-in-string
    fr: "Téléphones à partir d'aussi peu que {value}$ dollars",
  },
  newUnlockedPhone: {
    en: 'New Unlocked {br} iPhone 11 Pro 256 GB',
    fr: 'New Débloqué {br} iPhone 11 256 Go',
  },
  orchardUnlockedPhone: {
    en: 'Orchard Unlocked {br} iPhone 11 Pro 256 GB',
    fr: 'Orchard Débloqué {br} iPhone 11 256 Go',
  },
  saveOffRetail: {
    en: 'Save up to {value}% off retail',
    fr: 'Économisez {value}% sur le prix de détail',
  },
  shopSmart: {
    en: 'Shop smart',
    fr: 'Magasinez intelligemment',
  },
  orchardBringsOrder: {
    en: 'Orchard brings order into the chaos of used phones sales with:',
    fr: 'Orchard met de l’ordre dans le chaos des ventes de téléphones usagés avec :',
  },
  comprehensiveWarranty: {
    en: 'Comprehensive 90-day warranty',
    fr: 'Une garantie complète de 90 jours',
  },
  freeAndFastShipping: {
    en: 'Free & fast shipping',
    fr: 'Une expédition gratuite et rapide',
  },
  dedicatedCustomerSupport: {
    en: 'Dedicated customer support',
    fr: 'Un support client dédié',
  },
  sellers: {
    en: 'Sellers',
    fr: 'Vendeurs',
  },
  makeMore: {
    // eslint-disable-next-line no-template-curly-in-string
    en: 'Make ${value} more',
    // eslint-disable-next-line no-template-curly-in-string
    fr: 'Obtenez {value} $ de plus',
  },
  makeMorePercent: {
    // eslint-disable-next-line no-template-curly-in-string
    en: 'Make {value}% more',
    // eslint-disable-next-line no-template-curly-in-string
    fr: 'Obtenez {value}% de plus',
  },
  sellersPhoneModel: {
    en: 'iPhone XS 256 GB',
    fr: 'iPhone XS 256 Go',
  },
  carrierOffer: {
    en: "Carrier's Offer",
    fr: 'Offre opérateur mobile',
  },
  orchardOffer: {
    en: "Orchard's Offer",
    fr: 'Offre de Orchard',
  },
  sellSmart: {
    en: 'Sell smart',
    fr: 'Vendez intelligemment',
  },
};

export default messages;
