export default [
  [
    { label: 'buyNewDevices', path: '/search/shop-all-brand-new-devices', external: true },
    { label: 'buyUsedIphone', path: '/search/shop-for-featured-phones/' },
    { label: 'sellPhonesOnline', path: '/sell/' },
    { label: 'sellPhonesInBulk', path: '/sell/' },
    { label: 'iPhone12ProMaxRefurbished', path: '/iphone-12-pro-max-for-sale/' },
  ],
  [
    { label: 'iPhone12ProRefurbished', path: '/iphone-12-pro-for-sale/' },
    { label: 'iPhone12Refurbished', path: '/iphone-12-for-sale/' },
    { label: 'iPhone11ProMaxRefurbished', path: '/iphone-11-pro-max-for-sale/' },
    { label: 'iPhone11ProRefurbished', path: '/iphone-11-pro-for-sale/' },
    { label: 'iPhone11Refurbished', path: '/iphone-11-for-sale/' },
  ],
  [
    { label: 'galaxyS22plus5g', path: '/galaxy-s22-plus-5g-for-sale/' },
    { label: 'galaxyS225g', path: '/galaxy-s22-5g-for-sale/' },
    { label: 'galaxyS21plus5g', path: '/galaxy-s21-plus-5g-for-sale/' },
    { label: 'galaxyS215g', path: '/galaxy-s21-5g-for-sale/' },
    { label: 'galaxyS20fe5g', path: '/galaxy-s20-fe-5g-for-sale/' },
  ],
  [
    { label: 'iPhoneXSMaxRefurbished', path: '/iphone-xs-max-for-sale/' },
    { label: 'iPhoneXSUsed', path: '/iphone-xs-for-sale/' },
    { label: 'iPhoneXRRefurbished', path: '/iphone-xr-for-sale/' },
    { label: 'usedIPhoneX', path: '/iphone-x-for-sale/' },
    { label: 'usedIPhone8', path: '/iphone-8-for-sale/' },
  ],
  [
    { label: 'usedIPhone8Plus', path: '/iphone-8-plus-for-sale/' },
    { label: 'usedIPhoneSE', path: '/iphone-se-for-sale/' },
    { label: 'usedIPhone7Plus', path: '/iphone-7-plus-for-sale/' },
    { label: 'usedIPhone7', path: '/iphone-7-for-sale/' },
    { label: 'samsungS20Plus', path: '/galaxy-s20-plus-for-sale/' },
  ],
  [
    { label: 'galaxyS20', path: '/galaxy-s20-for-sale/' },
    { label: 'samsungS10Plus', path: '/galaxy-s10-plus-for-sale/' },
    { label: 'samsungS10', path: '/galaxy-s10-for-sale/' },
    { label: 'samsungS9Plus', path: '/galaxy-s9-plus-for-sale/' },
    { label: 'samsungS9', path: '/galaxy-s9-for-sale/' },
  ],
];
