import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { I18nContext } from '../contexts/I18nContext';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { sizeRegex } from '../helpers/regex';
import { ProductTypeUrls } from '../types/ProductTypes';
import UpsellOffers from '../types/UpsellOffers';
import HomeContainer from '../home/containers/HomeContainer';


const CartContainer = lazy(() => import('../cart/containers/CartContainer'));
const CheckoutCompleteContainer = lazy(
  () => import('../checkout/containers/CheckoutCompleteContainer'),
);
const CheckoutContainer = lazy(
  () => import('../checkout/containers/CheckoutContainer'),
);
const SellOnlineContainer = lazy(
  () => import('../sell/containers/SellOnlineContainer'),
);
const SellContainer = lazy(() => import('../sell/containers/SellContainer'));
const DashboardContainer = lazy(() => import('../dashboard/containers/DashboardContainer'));
const ItemContainer = lazy(() => import('../item/containers/ItemContainer'));
const ShopContainer = lazy(() => import('../shop/containers/ShopContainer'));
const AboutUsContainer = lazy(() => import('../home/about-us/containers/AboutUsContainer'));
const HowItWorksContainer = lazy(() => import('../home/how-it-works/containers/HowItWorksContainer'));
const LocationContainer = lazy(() => import('../home/location/containers/LocationContainer'));
const JobsContainer = lazy(() => import('../home/jobs/containers/JobsContainer'));
const GiftCardsContainer = lazy(() => import('../giftcards/GiftCardsContainer'));
const OrchardCare90Container = lazy(() => import('../orchardcare90/OrchardCare90Container'));
const LoginContainer = lazy(() => import('../login/LoginContainer'));
const ResetPasswordContainer = lazy(() => import('../login/ResetPasswordContainer'));
const RegisterContainer = lazy(() => import('../login/RegisterContainer'));
const PressContainer = lazy(() => import('../home/press/containers/PressContainer'));
const EeyouComponent = lazy(() => import('../home/eeyou/Eeyou'));
const ErrorPage = lazy(() => import('../components/ErrorPage'));

interface Params {
  locale?: 'en' | 'fr';
}

const windowLocale = window.location.href.includes('/fr/') ? 'fr' : 'en';
const Routes = () => {
  const { setLocale } = useContext(I18nContext);
  const { locale } = useParams<Params>();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const stripePromise = loadStripe(window.STRIPE_PUB_KEY, { locale: windowLocale });

  useEffect(() => {
    if (locale) {
      setLocale(locale);
    }
  }, [locale]);

  if (locale === 'en') {
    return <Redirect to={pathname.replace('/en', '')} />;
  }

  const upsellOffers = `${UpsellOffers.ORCHARD_CARE}|${UpsellOffers.SCREEN_PROTECTOR}|${UpsellOffers.FIZZ}`;
  const productTypes = `${ProductTypeUrls.iPhone}|${ProductTypeUrls.Android}|${ProductTypeUrls.iPad}`;

  return window.serverError ? (<ErrorPage />) : (
    <Switch>
      <Route path={`${url}/dashboard/buying-history/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <DashboardContainer page="buyingHistory" />
        </Suspense>
      </Route>
      <Route path={`${url}/dashboard/selling-history/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <DashboardContainer page="sellingHistory" />
        </Suspense>
      </Route>
      <Route path={`${url}/dashboard/trade-in/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <DashboardContainer page="tradeIn" />
        </Suspense>
      </Route>
      <Route path={`${url}/cart/shopping-cart/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <Elements stripe={stripePromise}>
            <CartContainer />
          </Elements>
        </Suspense>
      </Route>
      <Route
        path={[
          `${url}/checkout/offer/:offer(${upsellOffers})`,
          `${url}/checkout/thankyou/`,
        ]}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <CheckoutCompleteContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/checkout/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <CheckoutContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/emi/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <EeyouComponent brand="eeyou" />
        </Suspense>
      </Route>
      <Route path={`${url}/qiniq/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <EeyouComponent brand="qiniq" />
        </Suspense>
      </Route>
      <Route path={`${url}/orchard-care-90/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <OrchardCare90Container />
        </Suspense>
      </Route>
      <Route path={`${url}/gift-cards/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <GiftCardsContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/login/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <LoginContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/reset-password/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <ResetPasswordContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/register/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <RegisterContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/sell-it-online/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <SellOnlineContainer stripped={true} />
        </Suspense>
      </Route>
      <Route path={`${url}/sell-online/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <SellOnlineContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/sell/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <SellContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/search/item/:fullText`}>
        <Suspense fallback={<div>Loading...</div>}>
          <ItemContainer />
        </Suspense>
      </Route>
      <Route
        path={`${url}/search/shop-all-brand-new-devices`}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <ShopContainer />
        </Suspense>
      </Route>
      <Route
        path={[
          `${url}/search/shop-for-phones/`,
          `${url}/search/shop-for-new-:type(${productTypes})/`,
          `${url}/search/shop-for-featured-:type(${productTypes})/`,
          `${url}/search/shop-for-:size(${sizeRegex})-:type(${productTypes})/`,
          `${url}/search/shop-for-:color-:type(${productTypes})/`,
          `${url}/search/shop-for-:type(${productTypes})-in-:condition-condition/`,
          `${url}/search/shop-for-:type(${productTypes})-on-sale`,
          `${url}/search/shop-for-all-devices-on-sale`,
          `${url}/search/shop-for-:type(${productTypes})-for-:carrier/`,
          `${url}/:model-for-sale/`,
          `${url}/:model-for-sale-between-:price1-and-:price2/`,
        ]}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <ShopContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/about-us/`} exact>
        <Suspense fallback={<div>Loading...</div>}>
          <AboutUsContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/how-it-works/`} exact>
        <Suspense fallback={<div>Loading...</div>}>
          <HowItWorksContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/location/`} exact>
        <Suspense fallback={<div>Loading...</div>}>
          <LocationContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/jobs/`} exact>
        <Suspense fallback={<div>Loading...</div>}>
          <JobsContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/press/`} exact>
        <Suspense fallback={<div>Loading...</div>}>
          <PressContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/`} exact>
        <Suspense fallback={<div>Loading...</div>}>
          <HomeContainer />
        </Suspense>
      </Route>
      <Route path={`${url}/`}>
        <Suspense fallback={<div>Loading...</div>}>
          <ErrorPage />
        </Suspense>
      </Route>
    </Switch>
  );
};

const RoutesContainer = () => (
  <Route path="/:locale(en|fr)?">
    <Routes />
  </Route>
);

export default RoutesContainer;
