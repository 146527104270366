import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  anchor: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  paper: {
    borderColor: theme.palette.grey[200],
    boxShadow: theme.shadows[3],
    borderRadius: "13px",
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: "rgba(255,255,255,0.9)",
    padding: "10px 30px 10px 30px"
  },
}));

interface Props {
  label: React.ReactNode;
  items?: React.ReactNodeArray;
}

const Menu = ({ label, items = [] }: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const classes = useStyles();

  const toggleMenu = () => {
    setOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        role="button"
        className={classes.anchor}
        ref={ref}
        onClick={toggleMenu}
        onKeyPress={toggleMenu}
        tabIndex={0}
      >
        {label}
      </div>
      <Popper open={open} anchorEl={ref.current} placement="bottom-end">
        <Paper classes={{ root: classes.paper }} square variant="outlined">
          <ClickAwayListener onClickAway={closeMenu}>
            <MenuList onClick={closeMenu}>{items}</MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

Menu.defaultProps = {
  items: [],
};

export default Menu;
