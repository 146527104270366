import Collapse from '@mui/material/Collapse';
import React, { useContext } from 'react';
import { HeaderAndFooterContext } from '../../contexts/HeaderAndFooterContext';
import { ExperimentContext } from '../../contexts/ExperimentContext';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import ComputerHeader from './ComputerHeader';
import ComputerValueProps from './ComputerValueProps';
import MobileHeader from './MobileHeader';
import MobileValueProps from './MobileValueProps';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
return {
  navBarStyle: {
    zIndex: theme.zIndex.drawer - 201,
    backgroundColor: "#fff",
  }
}});
const Header = () => {
  const { showShopifyRedirectVariant } = useContext(ExperimentContext);
  const ignoreRedirect = window.location.href.includes('sell') || window.location.href.includes('login') || window.location.href.includes('classic');
  if (showShopifyRedirectVariant && !ignoreRedirect) {
    window.location.href = 'https://www3.getorchard.com';
  }

  const classes= useStyles();
  const { showHeader } = useContext(HeaderAndFooterContext);
  const { isMobile } = useContext(ScreenSizeContext);

  return (
    <Collapse in={showHeader}
      className={classes.navBarStyle}
    >
      {isMobile ? (
        <div>
          <MobileValueProps />
          <MobileHeader />
        </div>
      ) : (
        <div>
          <ComputerValueProps />
          <ComputerHeader />
        </div>
      )}
    </Collapse>
  );
};

export default Header;
