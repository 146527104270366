import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import Boundaries from '../UI/Boundaries';
import Picture from '../UI/Picture';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
  },
  paymentList: {
    listStyle: 'none',
    display: 'flex',
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('mobile')]: {
      marginTop: 0,
    },
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Copyright = () => {
  const isEnglish = useIntl().locale === "en";
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);

  const classes = useStyles();

  const paymentImages = [
    'visa.webp',
    'mastercard.webp',
    'visa-debit.webp',
    'amex.webp',
    'paypal.webp',
  ];

  let horizontalPadding = 4;
  if (isMobile) {
    horizontalPadding = 2;
  } else if (isDesktop) {
    horizontalPadding = 8;
  }

  return (
    <Box bgcolor="text.primary" py={isMobile ? 2 : 4} px={horizontalPadding}>
      <Boundaries>
        <Box
          color="grey.300"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" color="inherit">
            <FormattedMessage
              id="copyright"
              values={{ year: new Date().getFullYear() }}
            />
          </Typography>
          <ul className={classes.paymentList}>
            { isEnglish && <li><a href="/fr/" className={classes.link}>Français</a></li>}
            { !isEnglish && <li><a href="/en/" className={classes.link}>English</a></li>}
            {paymentImages.map((image) => (
              <li key={image}>
                <Picture
                  width={44}
                  height={28}
                  webp={`https://static.getorchard.com/images/${image}`}
                  alt={image}
                />
              </li>
            ))}
          </ul>
        </Box>
      </Boundaries>
    </Box>
  );
};

export default Copyright;
