import React, { createContext, useState } from 'react';

interface Context {
  content?: JSX.Element;
  setPreHeaderContent: (newContent?: JSX.Element) => void;
}

export const PreHeaderContext = createContext<Context>({
  content: <></>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPreHeaderContent: (newContent?: JSX.Element) => {},
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const [content, setContent] = useState<JSX.Element>();

  return (
    <PreHeaderContext.Provider
      value={{ content, setPreHeaderContent: setContent }}
    >
      {children}
    </PreHeaderContext.Provider>
  );
};

export default Provider;
