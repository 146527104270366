import React from 'react';
import PreOwnedItems from '../components/PreOwnedItems';
import ShopOurPhones from '../components/ShopOurPhones';
import UnlockedPhones from '../components/UnlockedPhones';
import ViewOurPhones from '../components/ViewOurPhones';
import WhyOrchard from '../components/WhyOrchard';
import SubscriptionContainer from './SubscriptionContainer';

const HomeContainer = () => (
  <>
    <ShopOurPhones />
    <PreOwnedItems />
    <UnlockedPhones />
    <WhyOrchard />
    <ViewOurPhones />
    <SubscriptionContainer />
  </>
);

export default HomeContainer;
