const messages = {
  close: {
    en: 'Close',
    fr: 'Fermer',
  },
  noAccessoriesToShow: {
    en: 'No accessories to show.',
    fr: "Pas d'accessoires à montrer",
  },
  chooseAccessories: {
    en: 'Choose accessories and more for your phone',
    fr: 'Choisissez des accessoires et plus encore pour votre téléphone',
  },
  selectCarrier: {
    en: 'Select Carrier',
    fr: 'Sélectionner le fournisseur',
  },
  add: {
    en: 'Add',
    fr: 'Ajouter',
  },
  added: {
    en: 'Added',
    fr: 'Ajouté',
  },
};

export default messages;
