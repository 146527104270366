import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import Boundaries from '../UI/Boundaries';
import LocaleLink from '../UI/LocaleLink';
import popularSearchesList from './popularSearchesList';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    marginBottom: theme.spacing(0.5),
  },
  title: {
    fontWeight: 'bold',
  },
}));

const PopularSearches = () => {
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);

  const classes = useStyles();

  let horizontalPadding = 4;
  if (isMobile) {
    horizontalPadding = 2;
  } else if (isDesktop) {
    horizontalPadding = 8;
  }

  return (
    <Box py={isMobile ? 2 : 4} px={horizontalPadding}>
      <Boundaries>
        <Typography
          className={classes.title}
          variant="h5"
          color="primary"
          paragraph
        >
          <FormattedMessage id="popularSearches" />
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          {popularSearchesList.map((list) => (
            <Box key={list[0].path} width={isMobile ? '50%' : '100%'}>
              {list.map((item) => (
                <Typography
                  key={item.label}
                  variant="body2"
                  color="textPrimary"
                >
                {!item.external ? (
                  <LocaleLink className={classes.link} to={item.path}>
                    <FormattedMessage id={item.label} />
                  </LocaleLink>
                ) : (
                    <a
                      key={item.path}
                      className={classes.link}
                      href={item.path}
                    >
                      <FormattedMessage id={item.label} />
                    </a>
                )}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      </Boundaries>
    </Box>
  );
};

export default PopularSearches;
