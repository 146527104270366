import NavigationBar from '../../../types/NavBar';

const navBar: NavigationBar = [
  {
    label: 'shop',
    path: '/search/shop-for-featured-phones/',
  },
  {
    label: 'sell',
    path: '/sell/',
  },
  {
    label: 'faqs',
    path: '/faqs/',
  },
  {
    label: 'sale',
    path: '/search/shop-for-all-devices-on-sale/',
    ext: true,
  },
  {
    label: 'about',
    subItems: [
      {
        label: 'aboutUs',
        path: '/about-us/',
      },
      {
        label: 'howItWorks',
        path: '/how-it-works/',
      },
      {
        label: 'blog',
        path: '/blog/',
      },
      {
        label: 'contactUs',
        path: '/faqs/contact-us/',
      },
      {
        label: 'location',
        path: '/location/',
      },
    ],
  },
];

export default navBar;
