const messages = {
  giftCardCheckoutText: {
    en: 'Upon purchase completion, you will be emailed your gift card code.',
    fr: 'Upon purchase completion, you will be emailed your gift card code.',
  },
  noThanks: {
    en: 'No Thanks',
    fr: 'Non merci',
  },
  yesFizz: {
    en: 'Yes, I would like a free SIM from Fizz',
    fr: 'Oui, je veux une carte SIM Fizz gratuite',
  },
  greatNews: {
    en: 'Great News',
    fr: 'Bonne nouvelle!',
  },
  oneOfOurPartners: {
    en: 'One of our wireless partners is running a promotion in your area.',
    fr: "L'un de nos opérateurs partenaires a une promotion en cours dans votre région.",
  },
  nowThatYouOwn: {
    en: 'Now that you own your phone, you can get wireless service from anyone!',
    fr: "Maintenant que vous êtes propriétaire de votre téléphone, vous pouvez profiter du réseau de n'importe quel opérateur mobile!",
  },
  wePartneredWithFizz: {
    en: 'We have partnered with Fizz because they have amazing prices',
    fr: "Nous nous sommes associés à Fizz parce qu'ils ont des prix incroyables.",
  },
  flexiblePlans: {
    en: 'Flexable Plans that work for you',
    fr: 'Des forfaits flexibles qui vous correspondent',
  },
  fizzFlexibleText1: {
    en: 'Plus they are super flexible: you get to create your plan (and change it whenver you want)',
    fr: 'Des forfaits flexibles qui vous correspondent',
  },
  fizzFlexibleText2: {
    en: 'For example: {bluePart}',
    fr: 'Profitez par exemple de {bluePart}',
  },
  fizzFlexibleText3: {
    en: '$36/month gets you 3GB of data with unlimited nationwide talk and text',
    fr: '3 GO de données et des appels et SMS illimités partout au Canada pour 36 $ par mois.',
  },
  fizzFlexibleText4: {
    en: 'Need more data? The same plan with {bluePart}',
    fr: 'Besoin de plus de données? Le même forfait avec {bluePart}',
  },
  fizzFlexibleText5: {
    en: '8GB of data is $40/month',
    fr: '8 GO de données est à 40 $ par mois!',
  },
  fizzPromoSummary: {
    en: 'Referral Bonus: $25 and 2GB of free data',
    fr: 'Bonus de parrainage: 25$ de remise et 2 GO de données gratuites',
  },
  fizzPromoSummaryText1: {
    en: 'Plus if you use the referral code {bluePart} to activate your first Fizz plan, you will get a $25 referral bonus and 2GB of free data.',
    fr: 'Et si vous utilisez le code de parrainage {bluePart} pour activer votre premier forfait Fizz, vous obtiendrez un bonus de parrainage de 25 $ et 2 GO de données gratuites.',
  },
  fizzPromoSummaryText2: {
    en: "Thats not all! We'll even give you a free SIM to get started from the comfort of your own home.",
    fr: "Et ce n'est pas tout! Nous vous enverrons même une carte SIM gratuite pour que vous puissiez vous lancer confortablement depuis chez vous.",
  },
  cart: {
    en: 'Cart',
    fr: 'Panier',
  },
  ourAddress: {
    en: 'Where to pick up your order',
    fr: 'Où ramasser votre commande',
  },
  ourAddressText: {
    en: 'Thanks for placing your order for pickup! Before you drop by, please select your pickup time below so we can make sure your order is ready for you.',
    fr: 'Merci d’avoir passé une commande à ramasser! Avant de venir, merci de sélectionner un horaire de ramassage pour que nous puissions préparer votre commande.',
  },
  ourAddressText2stripe: {
    en: "You'll need to bring along your credit card and photo ID for verification purposes.",
    fr: 'Pour des raisons de vérification, il vous faudra apporter votre carte de crédit et une pièce d’identité avec photo.',
  },
  ourAddressText2noStripe: {
    en: "You'll need to bring along your photo ID for verification purposes.",
    fr: 'Pour des raisons de vérification, il vous faudra apporter une pièce d’identité avec photo.',
  },
  ourAddressText3: {
    en: 'If you’re not sure when you want to pick up yet, no worries – we sent you this link by email as well. We look forward to seeing you soon!',
    fr: 'Si vous ne savez pas encore quand vous viendrez ramasser votre commande, ne vous inquiétez pas – nous vous avons aussi envoyé ce lien par email. Nous avons hâte de vous voir!',
  },
  bookTime: {
    en: 'BOOK PICKUP TIME',
    fr: 'PLANIFIER UN HORAIRE DE RAMASSAGE',
  },
  customerInformation: {
    en: 'Customer information',
    fr: 'Renseignements client',
  },
  differentBillingWarn: {
    en: 'Note: If you are shipping to a residential address that does not match your billing address, we suggest paying with PayPal. Orders placed with a credit card will be delayed for additional verification.',
    fr: "Remarque: les commandes hors-Paypal peuvent être retardées ou rejetées si votre adresse de facturation ne correspond pas à votre adresse d'expédition et que nous sommes dans l'incapacité de vérifier vos informations d'expédition",
  },
  insufficient_funds: {
    en: 'Your bank has returned an error for "Insufficient Funds"',
    fr: 'Votre banque a renvoyé une erreur pour "fonds insuffisants"',
  },
  incorrect_zip: {
    en: "The postal code does not match what's on file for this credit card.",
    fr: 'Le code postal ne correspond pas aux informations de cette carte de crédit.',
  },
  showOrderSummary: {
    en: 'Show order summary',
    fr: 'Montrer le résumé de la commande',
  },
  hideOrderSummary: {
    en: 'Hide order summary',
    fr: 'Cacher le résumé de la commande',
  },
  timeIsUp: {
    en: 'Time is up!',
    fr: 'Le temps est écoulé!',
  },
  timeIsUpDescription: {
    en: 'Your items will stay in your cart, but are now available for others to purchase. Act fast before someone snatches them up!',
    fr: 'Vos articles resteront dans votre panier, mais ils sont maintenant à nouveau disponibles à l’achat. Dépêchez-vous avant que quelqu’un d’autre ne s’en empare!',
  },
  ok: {
    en: 'OK',
    fr: 'OK',
  },
  shippingOptions: {
    en: 'Shipping Options',
    fr: "Options d'expédition",
  },
  pickUpOrder: {
    en: "I'll pick up my order in Orchard's Toronto office",
    fr: "Je souhaite ramasser ma commande au bureau d'Orchard à Toronto.",
  },
  shipOrder: {
    en: 'Ship my phone to me with your Ultra Fast Free Shipping',
    fr: 'Je voudrais recevoir mon téléphone grâce à votre livraison gratuite ultra rapide.',
  },
  shippingAddress: {
    en: 'Shipping Address',
    fr: "Adresse d'expédition",
  },
  hereIsOrchardsAddress: {
    en: "Here is Orchard's address. We'll show you a map for you to print after purchasing.",
    fr: 'Voici l’adresse d’Orchard. Après votre achat, nous vous montrerons un plan que vous pourrez imprimer.',
  },
  returnToCart: {
    en: 'Return to cart',
    fr: 'Retourner au panier',
  },
  continueToPayment: {
    en: 'Continue to Payment Method',
    fr: 'Passer au paiement',
  },
  save: {
    en: 'Save',
    fr: 'save',
  },
  returnToCustomerInfo: {
    en: 'Return to customer information',
    fr: 'Retourner aux renseignements client',
  },
  billingAddress: {
    en: 'Billing Address',
    fr: 'Adresse de facturation',
  },
  paymentMethod: {
    en: 'Payment Method',
    fr: 'Mode de paiement',
  },
  paymentMethodDescription: {
    en: 'All transactions are secure and encrypted. Credit card information is never stored.',
    fr: 'Toutes les transactions sont sécurisées et cryptées. Vos informations de carte de crédit ne sont jamais stockées.',
  },
  creditOrDebitCard: {
    en: 'Credit/Debit Card',
    fr: 'Carte de crédit',
  },
  giftcardOnly: {
    en: 'Gift Card Only',
    fr: 'Gift Card Only fr',
  },
  financePurchaseWithPaybright: {
    en: 'Finance your purchase with {paybright}',
    fr: 'Financez votre achat avec {paybright}',
  },
  financePurchaseWithFlexiti: {
    en: 'Finance your purchase with {flexiti}',
    fr: 'Financez votre achat avec {flexiti}',
  },
  paybrightPurchaseDescription: {
    en: "Clicking 'Complete Order' will redirect you to PayBright to complete your purchase.",
    fr: 'Vous allez être redirigé vers Paybright pour terminer votre achat en tout sécurité.',
  },
  flexitiPurchaseDescription: {
    en: "Clicking 'Complete Order' will redirect you to Flexiti to complete your purchase.",
    fr: 'Vous allez être redirigé vers Flexiti pour terminer votre achat en tout sécurité.',
  },
  paypalPurchaseDescription: {
    en: "Clicking 'Complete Order' will redirect you to Paypal to complete your purchase.",
    fr: 'Vous allez être redirigé vers Paypal pour terminer votre achat en tout sécurité.',
  },
  cash: {
    en: 'Cash',
    fr: 'Espèces',
  },
  square: {
    en: 'Square',
    fr: 'Square',
  },
  sameAsShipping: {
    en: 'Same as shipping address',
    fr: "L'adresse de facturation est la même que l'adresse d'expédition.",
  },
  differentThanShipping: {
    en: 'Use different billing address',
    fr: 'Utiliser une adresse de facturation différente',
  },
  completeOrder: {
    en: 'Complete Order',
    fr: 'Passer la commande',
  },
  itemsReserved: {
    en: 'We have reserved your cart for you for 8 minutes! After this time expires, the items in your cart will be made available to others.',
    fr: "Nous avons réservé votre panier pour 8 minutes! Après ce délai, les articles dans votre panier seront à nouveau disponibles pour d'autres clients",
  },
  havingProblems: {
    en: 'Having problems?',
    fr: 'Vous rencontrez des problèmes?',
  },
  flexitiFail: {
    en: "Looks like your payment was not approved by Flexiti. Please try again with another payment method.",
    fr: "Looks like your payment was not approved by Flexiti. Please try again with another payment method.",
  },
  hereToHelp: {
    en: "We're here to help!",
    fr: "Nous sommes là pour vous aider!",
  },
  workingHours: {
    en: '9am-7pm (EST) Mon-Fri, 10am-6pm Sat/Sun',
    fr: '9am-7pm (EST) Mon-Fri, 10am-6pm Sat/Sun',
  },
  protectYourInvestment: {
    en: 'Wait! Protect your investment',
    fr: 'Attendez ! Protégez votre investissement.',
  },
  limitedTimeOffer: {
    en: 'Limited time offer',
    fr: 'Offre à durée limitée',
  },
  savePercentage: {
    en: 'Save {discount}%',
    fr: 'Économisez {discount}%',
  },
  regularPrice: {
    en: 'Reg. Price',
    fr: 'Prix régulier',
  },
  promoItem4Name: {
    en: 'Tempered Glass Screen Protector',
    fr: "Protecteur d'écran en verre trempé",
  },
  promoItem4Description: {
    en: "Protect your precious touchscreen from drops with a Tech Armor tempered glass screen protector. We'll even apply it before delivery!",
    fr: "Protégez votre précieux écran tactile contre les chutes avec une protection d'écran en verre trempé Tech Armor. Nous nous chargeons même de l'installer avant la livraison !",
  },
  promoItem40Name: {
    en: 'Orchard Care Premium',
    fr: 'Orchard Care Premium',
  },
  promoItem40Description: {
    en: 'Orchard Care Premium provides 1 (one) repair or replacement coverage, including shipping and labor, for all functionality issues and accidental damages. Coverage extends for 1 full year from the purchase date of your phone.',
    fr: "Orchard Care Premium fournit un incident (1) de couverture de réparation ou de remplacement, y compris l'expédition et la main d'oeuvre, pour tous les problèmes de fonctionnalité et les dommages accidentels. La couverture s'étend sur une année complète à partir de la date d'achat de votre téléphone.",
  },
  promoItem40Subtitle: {
    en: 'Coverage term',
    fr: 'Durée de la couverture',
  },
  promoItem40Description2: {
    en: "1-year from your phone's purchase date",
    fr: "1 an à compter de la date d'achat de votre",
  },
  promoItem40Description3: {
    en: 'Each Protection Claim is subject to a deductible. The deductible will vary according to the (pre-tax) purchase price of your phone, and whether the phone can be repaired, or will need to be replaced. See following table for breakdown:',
    fr: "Chaque demande de protection est soumise à une franchise. Celle-ci varie en fonction du prix d'achat (avant taxes) de votre téléphone, et si le téléphone peut être réparé ou devra être remplacé. Voir le tableau suivant pour la ventilation :",
  },
  yesAddToOrder: {
    en: 'Yes, add to my order',
    fr: 'Oui, ajouter à ma commande',
  },
  noTakeMeToReceipt: {
    en: 'No thanks, take me to my receipt please',
    fr: "Non merci, je vous prie de m'amenez à mon reçu",
  },
  orderComplete: {
    en: 'Your order is complete!',
    fr: 'Votre commande a été passée!',
  },
  orderNumber: {
    en: 'Your order no. is {number}',
    fr: 'Votre numéro de commande est {number}',
  },
  addressDetails: {
    en: 'Address details',
    fr: "Détails de l'adresse",
  },
  billingInformation: {
    en: 'Billing Information',
    fr: 'Renseignements de facturation',
  },
  shippingInformation: {
    en: 'Shipping Information',
    fr: 'Renseignements de livraison',
  },
  item: {
    en: 'Item',
    fr: 'Articles',
  },
  price: {
    en: 'Price',
    fr: 'Prix',
  },
  subtotal: {
    en: 'Subtotal',
    fr: 'Sous-total',
  },
  quickQuestion: {
    en: 'Thanks for your order! Quick question:',
    fr: "Merci d'avoir passé commande ! Une petite question :",
  },
  howDidYouHearAboutUs: {
    en: 'How did you first hear about us?',
    fr: 'Comment avez-vous entendu parler de nous ?',
  },
  'repeat-customer': {
    en: 'I am a repeat customer',
    fr: 'Je suis déjà client',
  },
  kijiji: {
    en: 'Kijiji',
    fr: 'Kijiji',
  },
  'fb-ad': {
    en: 'Facebook Ad',
    fr: "J'ai vu une publicité pour Orchard sur Facebook",
  },
  'instagram-ad': {
    en: 'Instagram Ad',
    fr: "J'ai vu une publicité pour Orchard sur Instagram",
  },
  'tik-tok': {
    en: 'TikTok',
    fr: "TikTok",
  },
  'yn-did-you-purchase-a-case': {
    en: 'Did you purchase a case today with Orchard?',
    fr: "Avez-vous acheté une coque de téléphone avec Orchard aujourd'hui?",
  },
  'yn-if-no-case-elsewhere': {
    en: 'Are you planning on getting one elsewhere?',
    fr: "Prévoyez-vous d'en acheter une dans un autre magasin?",
  },
  'postcard-in-mail': {
    en: 'Postcard in mail',
    fr: "Postcard in mail",
  },
  google: {
    en: 'Google',
    fr: 'Google',
  },
  blog: {
    en: 'The Orchard Blog',
    fr: "Le Blog d'Orchard",
  },
  'friends-or-family': {
    en: 'From friends or family',
    fr: 'Par des amis ou de la famille',
  },
  other: {
    en: 'Other (please specify)',
    fr: 'Autre - Veuillez préciser',
  },
  send: {
    en: 'Send',
    fr: 'Envoyer',
  },
  ratherNotSay: {
    en: "I'd rather not say",
    fr: 'Je ne préfère pas le dire',
  },
  thankYouForAnswering: {
    en: 'Thank you for your feedback!',
    fr: 'Merci de faire affaire avec Orchard',
  },
};

export default messages;
