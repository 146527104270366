const messages = {
  bfgc10: {
    en: 'Enjoy a 10% discount on your purchase (applied at checkout). Cyber Monday only!',
    fr: "Profitez d'une réduction de 10 % sur vos achats (appliquée à la caisse). Uniquement pour le Cyber Monday !",
  },
  emptyCart: {
    en: 'Your cart is empty.',
    fr: 'Votre panier est vide',
  },
  remove: {
    en: 'Remove',
    fr: 'Retirer',
  },
  couponCodePlaceholder: {
    en: 'Coupon code',
    fr: 'Code coupon',
  },
  apply: {
    en: 'Apply',
    fr: 'Appliquer',
  },
  couponSuccess: {
    en: 'Coupon succesfully added',
    fr: 'Coupon ajouté avec succès',
  },
  addAccessories: {
    en: 'Add accessories',
    fr: 'Ajouter des accessoires',
  },
  orderToday: {
    en: 'Order today. Delivers to {postalCode}',
    fr: "Commandez aujourd'hui. Livraison à {postalCode}",
  },
  deliveryEstimation: {
    en: '{startDate} - {endDate} — FREE',
    fr: '{startDate} - {endDate} — GRATIS',
  },
  whatsYourPostalCode: {
    en: "What's your postal code?",
    fr: 'Quel est votre code postal ?',
  },
  helpEstimate: {
    en: "It'll help us estimate shipping and delivery",
    fr: "Cela nous aidera à estimer les frais d'expédition et de livraison",
  },
  changePostalCode: {
    en: 'Change Postal Code',
    fr: 'Modifier le code postal',
  },
  update: {
    en: 'Update',
    fr: 'Mise à jour',
  },
  returnPolicy: {
    en: 'Return policy',
    fr: 'Politique de retour',
  },
  freeReturns: {
    en: 'Free no questions asked returns for 90 days',
    fr: 'Retours gratuits sans questions pendant les 90 jours',
  },
  orderSummary: {
    en: 'Order Summary',
    fr: 'Résumé de la commande',
  },
  productSubtotal: {
    en: 'Product Subtotal',
    fr: 'Sous-total',
  },
  numberOfItems: {
    en: `({quantity, plural,
      =0 {# items}
      one {# item}
      other {# items}
    })`,
    fr: `({quantity, plural,
      =0 {# articles}
      one {# article}
      other {# articles}
    })`,
  },
  orderDiscounts: {
    en: 'Order Discounts',
    fr: 'Remises sur commande',
  },
  shipping: {
    en: 'Shipping',
    fr: 'Expédition',
  },
  free: {
    en: 'Free',
    fr: 'Gratis',
  },
  salesTax: {
    en: 'Sales Tax',
    fr: 'Taxes de vente',
  },
  confirmedAtCheckout: {
    en: '(confirmed at checkout)',
    fr: '(sera confirmé à la caisse)',
  },
  selectProvince: {
    en: 'Select Province',
    fr: 'Sélectionnez la province',
  },
  estimatedTotal: {
    en: 'Estimated Total',
    fr: 'Total estimé',
  },
  total: {
    en: 'Total',
    fr: 'Total',
  },
};

export default messages;
