import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteTracker = () => {
  const { pathname } = useLocation();

  useEffect(
    () => {
      return window.dataLayer.push({
        event: 'page_view',
        page: { path: pathname },
      });
    }, [pathname],
  );

  return null;
};

export default RouteTracker;
