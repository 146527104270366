import React, { useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { I18nContext } from '../../contexts/I18nContext';

const LocaleLink = ({
  className,
  children,
  to,
  ...rest
}: LinkProps) => {
  const { locale } = useContext(I18nContext);

  let href = to;
  if (locale && typeof href === 'string') {
    href = `${locale === 'en' ? '' : '/fr'}${to}`;
    while(href.includes('/fr/fr')) {
      href = href.replace('/fr/fr', '/fr');
    }
  }

  const {
    component,
    replace,
    innerref,
  } = rest;
  const target = href.toString().includes('blog') || href.toString().includes('faqs') ? '_blank' : rest.target;

  return (
    <Link
      className={className}
      to={href}
      {...{
        component,
        replace,
        innerref,
        target,
      }}
    >
      {children}
    </Link>
  );
};

export default LocaleLink;
