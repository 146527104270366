import Box from '@mui/material/Box';
import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PreHeader from '../components/PreHeader';
import ErrorPage from '../components/ErrorPage';
import CartProvider from '../contexts/CartContext';
import HeaderAndFooterProvider from '../contexts/HeaderAndFooterContext';
import I18nProvider from '../contexts/I18nContext';
import InventoryProvider from '../contexts/InventoryContext';
import PopupProvider from '../contexts/PopupContext';
import PreHeaderProvider from '../contexts/PreHeaderContext';
import ScreenSizeProvider from '../contexts/ScreenSizeContext';
import ExperimentProvider from '../contexts/ExperimentContext';
import { initializeTracking } from '../helpers/analytics';
import RoutesContainer from './RoutesContainer';
import RouteTracker from './RouteTracker';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);this.state = { hasError: false, error: null };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }
  componentDidCatch(error, errorInfo) {
    datadogLogs.logger.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage error={this.state.error ? this.state.error : 'JsError'} />
      );
    }
    return this.props.children;
  }
}
const AppContainer = () => {
  initializeTracking();

  return (
    <I18nProvider>
      <ErrorBoundary>
        <Box
          minHeight="100vh"
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <RouteTracker />
          <ExperimentProvider>
            <ScreenSizeProvider>
              <PopupProvider>
                <InventoryProvider>
                  <CartProvider>
                    <HeaderAndFooterProvider>
                      <PreHeaderProvider>
                        <PreHeader />
                        <Header />
                        <Box flex={1}>
                          <RoutesContainer />
                        </Box>
                        <Footer />
                      </PreHeaderProvider>
                    </HeaderAndFooterProvider>
                  </CartProvider>
                </InventoryProvider>
              </PopupProvider>
            </ScreenSizeProvider>
          </ExperimentProvider>
        </Box>
      </ErrorBoundary>
    </I18nProvider>
  );
};

export default AppContainer;
