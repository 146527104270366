const messages = {
  reviewCount: {
    en: '520+ Reviews',
    fr: '+ de 520 Avis',
  },
  yourNextPhone: {
    en: 'Your next phone, for less.',
    fr: 'Votre prochain téléphone, pour moins.',
  },
  yourNextPhoneSale: {
    en: '10% OFF ALL PHONES, IPADS & WATCHES',
    fr: 'Votre prochain téléphone, pour moins.',
  },
  financingPlansAvailable: {
    en: 'Certified used smartphones are now available at up to 60% less than retail. Free shipping and returns, with a 90-day warranty.',
    fr: "Les téléphones intelligents usagés certifiés sont maintenant offerts jusqu'à 60 % de moins que dans le commerce de détail. Les plans de financement sont disponibles.",
  },
  junkTech: {
    en: "Pre-owned doesn't mean you are buying someone's junk tech. All our phones undergo two rounds of testing and are covered by our extensive performance guarantee",
    fr: "Les téléphones intelligents usagés certifiés sont maintenant offerts jusqu'à 60 % de moins que dans le commerce de détail. Les plans de financement sont disponibles.",
  },
  junkTechSale: {
    en: "Remember our inventory is limited, so don't wait too long to grab your device!",
    fr: "Les téléphones intelligents usagés certifiés sont maintenant offerts jusqu'à 60 % de moins que dans le commerce de détail. Les plans de financement sont disponibles.",
  },
  shopTheSale: {
    en: 'Shop The Sale',
    fr: 'Magasiner nos Téléphones',
  },
  shopOurPhones: {
    en: 'Shop Our Phones',
    fr: 'Magasiner nos Téléphones',
  },
  shopNow: {
    en: 'Shop Now',
    fr: 'Magasiner nos Téléphones',
  },
  modelStartingPrice: {
    // eslint-disable-next-line no-template-curly-in-string
    en: '{model} starting at ${price}',
    // eslint-disable-next-line no-template-curly-in-string
    fr: '{model} à partir de {price} $',
  },
  orchardMakesItAffordable: {
    en: 'Orchard makes it affordable',
    fr: 'Orchard le rend abordable',
  },
  discountOffStickerPrice: {
    en: 'Get up to 60% off sticker price by shopping our premium pre-owned smartphones. Now you can own awesome hardware while keeping your arm and your leg.',
    fr: "Obtenez jusqu'à 60% de rabais sur le prix en achetant nos téléphones intelligents usagés haut de gamme. Maintenant, vous pouvez posséder du matériel génial tout en gardant votre bras et votre jambe.",
  },
  getConnected: {
    en: 'Get connected on your own terms.',
    fr: 'Connectez-vous selon vos propres termes.',
  },
  bringToYourPlan: {
    en: "Bring your Orchard phone to your existing plan or decide on something new. Owning your phone lets you avoid your carrier's overpriced long-term contracts so you can get the same service for much less.",
    fr: "Apportez votre téléphone Orchard à votre forfait actuel ou décidez de quelque chose de nouveau. Posséder votre téléphone vous permet d'éviter les contrats à long terme trop chers de votre fournisseur afin que vous puissiez obtenir le même service pour beaucoup moins.",
  },
  shopOurUnlockedPhones: {
    en: 'Shop our unlocked phones',
    fr: 'Magasiner nos téléphones',
  },
  safeAndHassleFree: {
    en: "It's safe, and truly hassle-free.",
    fr: "C'est sécuritaire, et en toute sérénité.",
  },
  orchardBuysUsed: {
    en: "Orchard is the only place to buy used with a lifetime blacklist guarantee and unbeatable customer support. Our comprehensive 90-day warranty means if you don't love your new phone, we'll replace it or give you your money back.",
    fr: "Orchard est le seul endroit pour acheter un téléphone usagé avec une garantie de liste noire à vie et un support client imbattable. Notre garantie complète de 90 jours signifie que si vous n'aimez pas votre nouveau téléphone, nous le remplacerons ou vous rembourserons.",
  },
  readMoreOnGooglePlus: {
    en: 'Read more on Google+',
    fr: 'Lire la suite sur Google+',
  },
  findTheBestPhone: {
    en: 'Find the best phone for you',
    fr: 'Trouvez le meilleur téléphone pour vous',
  },
  eeyouPartner: {
    en: '{boldPart1} has partnered with {boldPart2} to provide their customers with high-quality used smartphones',
    fr: "{boldPart1} s'associe à {boldPart2} pour offrir des téléphones de seconde main de haute qualité à ses clients.",
  },
  eeyouMobility: {
    en: 'Eeyou Mobility',
    fr: 'Eeyou Mobilité',
  },
  qiniqPartner: {
    en: '{boldPart1} has partnered with {boldPart2} to provide their customers with high-quality used smartphones',
    fr: "{boldPart1} s'associe à {boldPart2} pour offrir des téléphones de seconde main de haute qualité à ses clients.",
  },
  qiniqMobility: {
    en: 'Qiniq Mobility',
    fr: 'Qiniq Mobilité',
  },
  viewAllOurPhones: {
    en: 'View all our phones',
    fr: 'Afficher tous nos téléphones',
  },
  stayInTheKnow: {
    en: 'Stay in the know',
    fr: 'Restez informé',
  },
  keepInTheLoop: {
    en: 'We will keep you in the loop with Orchard announcements, deals, sales and more!',
    fr: 'Nous vous tiendrons au courant des annonces, des offres, des ventes et plus encore de Orchard!',
  },
  emailPlaceholder: {
    en: 'Email...',
    fr: 'Courriel',
  },
  submit: {
    en: 'Submit',
    fr: 'Soumettre',
  },
  subscriptionSuccesful: {
    en: 'Subscription succesful!',
    fr: 'Abonnement réussi',
  },
  whyBuyWithEeyouAndOrchard: {
    en: 'Why Buy with Eeyou and Orchard?',
    fr: 'Pourquoi acheter avec Eeyou et Orchard?',
  },
  whyBuyWithQiniqAndOrchard: {
    en: 'Why Buy with Qiniq and Orchard?',
    fr: 'Pourquoi acheter avec Qiniq et Orchard?',
  },
  hugeSavingsComparedToBuyingNew: {
    en: 'Huge savings compared to buying new',
    fr: 'Grosses économies par rapport à un achat neuf',
  },
  freeExpressShipping: {
    en: 'Free express shipping',
    fr: 'Livraison rapide et gratuite',
  },
  ninetyDayReturns: {
    en: '90-day no questions asked returns',
    fr: 'Retours sous 90 jours, sans poser de questions',
  }
};

export default messages;
