import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "grade stars"
      "grade reviews"
    `,
  },
  grade: {
    gridArea: 'grade',
    color: '#F08628',
    marginRight: theme.spacing(2),
  },
  reviews: {
    gridArea: 'reviews',
  },
  stars: {
    gridArea: 'stars',
    color: '#F08628',
  },
}));

const Reviews = () => {
  const { isMobile } = useContext(ScreenSizeContext);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.grade} variant={isMobile ? 'h4' : 'h3'}>
        4.9
      </Typography>
      <div className={classes.stars}>
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>
      <Typography
        className={classes.reviews}
        variant="h6"
        color="textSecondary"
      >
        <FormattedMessage id="reviewCount" />
      </Typography>
    </div>
  );
};

export default Reviews;
