import BiotechOutlined from '@mui/icons-material/BiotechOutlined';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import VerifiedOutlined from '@mui/icons-material/VerifiedOutlined';
import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  valueProps: {
    backgroundColor: '#f6f6f6',
    fontSize: theme.spacing(1.5),
    fontWeight: 'normal',
    lineHeight: 2,
  },
  inlineIcon: {
    verticalAlign: 'bottom',
  },
}));

const ComputerValueProps = () => {
  const classes = useStyles();
  return (
    <Grid
      py={1}
      container
      justifyContent="space-evenly"
      alignItems="center"
      className={classes.valueProps}
    >
      <Grid item>
        <Typography>
          <BiotechOutlined className={classes.inlineIcon} />
          <FormattedMessage id="valuePropOne" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <VerifiedOutlined className={classes.inlineIcon} />
          &nbsp;
          <FormattedMessage id="valuePropTwo" />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <LocalShippingOutlined className={classes.inlineIcon} />
          &nbsp;
          <FormattedMessage id="valuePropThree" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ComputerValueProps;
