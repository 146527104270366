import React, { createContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import api from '../api';
import getIntlProps from '../intl';

export const I18nContext = createContext({
  locale: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLocale: (locale: 'en' | 'fr') => {},
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const [locale, setLocale] = useState<'en' | 'fr' | ''>('');

  const intlProps = getIntlProps(locale);

  useEffect(() => {
    if (!locale) {
      return;
    }
    if (locale === 'en') {
      api.defaults.baseURL = BASE_URL;
    } else {
      api.defaults.baseURL = `${BASE_URL}/${locale}/`;
    }
  }, [locale]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IntlProvider {...intlProps}>
      <I18nContext.Provider value={{ locale, setLocale }}>
        {children}
      </I18nContext.Provider>
    </IntlProvider>
  );
};

export default Provider;
