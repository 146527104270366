const messages = {
  sale: {
    en: 'Sale',
    fr: 'Soldes',
  },
  valuePropOneExp: {
    en: '4.9 stars from 2,314 happy customers',
    fr: 'Tests minutieux effectués par Orchard',
  },
  valuePropOne: {
    en: 'Thoroughly tested by Orchard',
    fr: 'Tests minutieux effectués par Orchard',
  },
  valuePropTwo: {
    en: '90-day no questions asked returns',
    fr: 'Retours sous 90 jours sans poser de questions',
  },
  valuePropThree: {
    en: 'Free shipping and returns',
    fr: 'Livraison et retours gratuits',
  },
  shop: {
    en: 'Shop',
    fr: 'Magasiner',
  },
  sell: {
    en: 'Sell',
    fr: 'Vendre',
  },
  faqs: {
    en: 'FAQs',
    fr: 'Questions fréquentes',
  },
  about: {
    en: 'About',
    fr: 'À propos',
  },
  aboutUs: {
    en: 'About us',
    fr: 'À notre sujet',
  },
  howItWorks: {
    en: 'How it works',
    fr: 'Comment cela fonctionne',
  },
  blog: {
    en: 'Blog',
    fr: 'Blog',
  },
  contactUs: {
    en: 'Contact Us',
    fr: 'Nous joindre',
  },
  location: {
    en: 'Location',
    fr: 'Emplacement',
  },
  viewCart: {
    en: 'View Cart',
    fr: 'Visionner le panier',
  },
  checkout: {
    en: 'Checkout',
    fr: 'La caisse',
  },
};

export default messages;
