const messages = {
  login: {
    en: 'Login',
    fr: 'Se Connecter',
  },
  email: {
    en: 'Email',
    fr: 'Courriel',
  },
  password: {
    en: 'Password',
    fr: 'Mot de passe',
  },
  confirmPassword: {
    en: 'Confirm Password',
    fr: 'Confirmer le mot de passe',
  },
  forgotYourPassword: {
    en: 'Forgot your password?',
    fr: 'Vous avez oublié votre mot de passe?',
  },
  dontHaveAnAccount: {
    en: 'Dont have an account? Signup Here',
    fr: "Vous n'avez pas de compte? Enregistrez-vous ici"
  },
  resetPassword: {
    en: 'Reset Password',
    fr: 'Réinitialiser votre mot de passe',
  },
  resetSuccessMessage: {
    en: 'Success - check your email to reset your password',
    fr: 'Demande transmise avec succès. Vérifiez vos emails pour réinitialiser votre mot de passe.',
  },
  register: {
    en: 'Register with Orchard',
    fe: "S'enregistrer avec Orchard",
  },
  alreadyHaveAnAccount: {
    en: 'Already have an account? Login Here',
    fr: 'Déjà un compte? Ouvrir la session ici.'
  }
};
export default messages;
