import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

const Boundaries = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Box
      height="100%"
      maxWidth={theme.breakpoints.values.desktop}
      margin="auto"
    >
      {children}
    </Box>
  );
};

export default Boundaries;
