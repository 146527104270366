import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Menu from '../../UI/Menu';
import navBarItems from './navBarItems';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  menu: {
    color: theme.palette.primary.secondary,
    '&:hover': {
      color: theme.palette.grey[800],
    },
  },
  menuItem: {
    minHeight: 0,
    color: theme.palette.primary.secondary,
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontWeight: 'bold',
    fontSize: "23px",
  },
}));

const ComputerNavBar = () => {
  const classes = useStyles();

  const navBar = navBarItems.map((item) => {
    if (item.path === '/en/' || item.path === '/fr/') {
      return null;
    }
    if ('subItems' in item) {
      return (
        <Menu
          key={item.label}
          label={(
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              px={2}
            >
              <Typography className={classes.menuItem} >
                <FormattedMessage id={item.label} />
              </Typography>
              <KeyboardArrowDownIcon color="inherit" className={classes.menuItem} />
            </Box>
          )}
          items={item.subItems.map((subItem) => (
            <MenuItem key={subItem.path}>
              <NavItem
                label={<FormattedMessage id={subItem.label} />}
                path={subItem.path}
                ext={subItem.ext}
              />
            </MenuItem>
          ))}
        />
      );
    }
    return (
      <Box key={item.path} px={2}>
        <NavItem
          label={<FormattedMessage id={item.label} />}
          path={item.path}
          ext={item.ext}
        />
      </Box>
    );
  });

  return (
    <Box display="flex" alignItems="center">
      {navBar}
    </Box>
  );
};

export default ComputerNavBar;
