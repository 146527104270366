import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/LockOutlined';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import LocaleLink from '../UI/LocaleLink';
import Menu from '../UI/Menu';
import CartIcon from './CartIcon';
import Logo from './Logo';
import ComputerNavBar from './NavBar/ComputerNavBar';

const useStyles = makeStyles((theme) => ({
  anchor: {
  },
  phonePopper: {
    fontFamily: '"Panton", sans-serif, serif',
    textAlign: 'center',
    marginTop: '12px',
    borderColor: theme.palette.grey[200],
    boxShadow: theme.shadows[3],
    borderRadius: "13px",
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: "rgba(255,255,255,0.9)",
    padding: "10px 30px 10px 30px"
  },
  menuIcon: {
    display: 'flex',
    fontSize: "1.8em !important",
    color: theme.palette.primary.secondary,
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontWeight: 'bold',
  },
  phoneLink: {
    color: theme.palette.primary.secondary,
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontWeight: 'bold',
  },
  menuItem: {
    display: 'flex',
    fontSize: "23px",
    color: theme.palette.primary.secondary,
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontWeight: 'bold',
  },
  lockIcon: {
    display: 'flex',
    fontSize: theme.spacing(3.5),
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
    marginRight: theme.spacing(1),
    marginTop: '-4px',
  },
  plainText: {
    textDecoration: 'none',
  },
  options: {
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const ComputerHeader = () => {
  const { isDesktop } = useContext(ScreenSizeContext);
  const classes = useStyles();
  const isEnglish = useIntl().locale === 'en';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const phoneOpen = Boolean(anchorEl);
  const popperid = phoneOpen ? 'simple-popper' : undefined;

  return (
    <Box
      border={1}
      borderTop={0}
      borderRight={0}
      borderLeft={0}
      borderColor="grey.300"
      px={isDesktop ? 6 : 2.5}
      boxShadow={3}
    >
      <Box display="flex">
        <Logo darker />
        <Box display="flex" justifyContent="space-between" width="100%" sx={{marginLeft: "20px", paddingTop: '8px'}}>
          <ComputerNavBar />
          <Box className={classes.options} display="flex" alignItems="center">
            {isEnglish && (
              <a
                className={classes.menuItem}
                href="/fr/"
              >
                <Typography className={classes.menuItem}>
                  Français
                </Typography>
              </a>
            )}
            {!isEnglish && (
              <a
                className={classes.menuItem}
                href="/en/"
              >
                <Typography className={classes.menuItem}>
                  English
                </Typography>
              </a>
            )}
            <a aria-describedby={popperid} onClick={handleClick} type="button" className={classes.menuItem}>
              <HeadsetMicIcon />
            </a>
            <Popper id={popperid} open={phoneOpen} anchorEl={anchorEl} className={classes.anchor}>
            <Box className={classes.phonePopper}>
            We're available 10 am to 6 PM Eastern 7 days per week<br /><a href="tel:+18007362703" className={classes.phoneLink}>1-800-736-2703</a>
            </Box>

            </Popper>
            <a className={classes.menuIcon} href="mailto:support@getorchard.com"
            >
              <MarkAsUnreadIcon />
            </a>
            <LocaleLink className={classes.menuIcon} to="/cart/shopping-cart/">
              <CartIcon biggerIcon className={classes.menuIcon} />
            </LocaleLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ComputerHeader;
