import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import theme from '../theme';
import AppContainer from './AppContainer';

const RootContainer = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default RootContainer;
