const messages = {
  itemAddedOrchardCare90: {
    en: 'Added Orchard Care',
    fr: '',
  },
  yourDeductibleDepends: {
    en: 'Your deductible depends on the purchase price of your phone at the time of your order. The table below indicates deductible depending on the type of service required',
    fr: '',
  },
  underStandingTheDeductible: {
    en: 'Understanding the Deductible',
    fr: '',
  },
  whyOcp: {
    en: 'Why Orchard Care Premium?',
    fr: '',
  },
  extendedCoverage: {
    en: 'Extended Coverage',
    fr: '',
  },
  extendedCoverageText: {
    en: 'Peace of mind with an extended one-year warranty right from your purchase date.',
    fr: '',
  },
  repairsAndReplacement: {
    en: 'Repairs and Replacements',
    fr: ''
  },
  repairsAndReplacementText: {
    en: "Whether it's functionality issues or accidental damages, we've got you covered. If our tech wizards can't fix it, we will send you a replacement phone!",
    fr: ''
  },
  allInclusive: {
    en: 'All-inclusive',
    fr: '',
  },
  allInclusiveText: {
    en: 'Other than the deductible, there are no additional costs for parts, labour, or shipping. We handle it all, ensuring a smooth process for you.',
    fr: '',
  },
  moreValue: {
    en: 'More Value',
    fr: '',
  },
  moreValueText: {
    en: 'Get Orchard Care for $48 instead of $60 for a limited time - using the links in this email!',
    fr: '',
  },
  devicePurchasePrice: {
    en: 'Device Purchase Price',
    fr: '',
  },
  replaceDeductible: {
    en: 'Replace Deductible',
    fr: '',
  },
  lessThan250: {
    en: 'Less than $250',
    fr: '',
  },
  moreThan500: {
    en: 'More than $500',
    fr: '',
  },
  '250to500dollars': {
    en: '$250 to $500',
    fr: '250$ to 500$',
  },
  '25dollars': {
    en: '$25',
    fr: '25$',
  },
  '75dollars': {
    en: '$75',
    fr: '75$',
  },
  '50dollars': {
    en: '$50',
    fr: '50$',
  },
  '100dollars': {
    en: '$100',
    fr: '100$',
  },
  '150dollars': {
    en: '$150',
    fr: '150$',
  },
};
export default messages;
