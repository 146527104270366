import Cart, { CartItem } from '../types/Cart';

export const countCartItems = (cart: Cart) => cart.reduce(
  (acc: number, cur: CartItem) => acc + (cur.options.itemType === 2 ? 0 : cur.qty),
  0,
);

export const countCartSubtotal = (cart: Cart) => {
  const reduced = cart.reduce(
    (acc: number, cur: CartItem) => acc + (cur.options.itemType === 2 ? 0 : cur.subtotal),
    0,
  );
  return reduced;
};

export const countCartTaxable = (cart: Cart) => {
  const reduced = cart.reduce(
    (acc: number, cur: CartItem) => (cur.options.itemType !== 3 && cur.options.itemType !== 2) ? acc + cur.subtotal : acc,
    0,
  );
  return reduced;
};

export const countCartTotal = (cart: Cart) => {
  const reduced = cart.reduce(
    (acc: number, cur: CartItem) => {
      if (cur.options && cur.options.promoType && cur.options.promoType == 1) {
        return acc + cur.price*countCartTaxable(cart)/100;
      }
      return acc + cur.subtotal;
    },
    0,
  );
  return reduced;
}

export const cartHasRush = (cart: Cart) => cart.some( item => item.name.toLowerCase().includes('rush'))

export default {
  countCartItems,
  countCartSubtotal,
  countCartTaxable,
  countCartTotal,
  cartHasRush,
};
