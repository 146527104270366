const messages = {
  giftCards: {
    en: 'Gift Cards',
    fr: 'Gift Cards',
  },
  accessoriesStore: {
    en: 'Accessories Store',
    fr: 'Acheter et Vendre',
  },
  buyInBulk: {
    en: 'Buy in Bulk',
    fr: 'Acheter en Vrac',
  },
  buyAndSell: {
    en: 'Buy & Sell',
    fr: 'Acheter et Vendre',
  },
  myAccount: {
    en: 'My Account',
    fr: 'Mon Compte',
  },
  shopOurPhones: {
    en: 'Shop Our Phones',
    fr: 'Magasiner nos téléphones',
  },
  shopOurIpads: {
    en: 'Shop Our iPads',
    fr: 'Magasiner nos iPads',
  },
  sellYourPhone: {
    en: 'Sell Your Phone',
    fr: 'Vendre votre téléphone',
  },
  sellInBulk: {
    en: 'Sell in Bulk',
    fr: 'Vendre en Vrac',
  },
  setAlert: {
    en: 'Set an Alert',
    fr: 'Fixer une alerte',
  },
  compare: {
    en: 'Compare',
    fr: 'Comparer',
  },
  company: {
    en: 'Company',
    fr: 'Entreprise',
  },
  jobs: {
    en: 'Jobs',
    fr: 'Emplois',
  },
  press: {
    en: 'Press',
    fr: 'Médias',
  },
  legal: {
    en: 'Legal',
    fr: 'Juridique',
  },
  buyUsedIphone: {
    en: 'Buy Used iPhone',
    fr: 'Acheter un iPhone usagé',
  },
  buyNewDevices: {
    en: 'Buy Brand New Phones',
    fr: 'Acheter téléphones nouveau',
  },
  sellPhonesOnline: {
    en: 'Sell Phones Online',
    fr: 'Vendre des téléphones en ligne',
  },
  sellPhonesInBulk: {
    en: 'Sell Phones In Bulk',
    fr: 'Vendre des téléphones en vrac',
  },
  iPhone11ProMaxUsed: {
    en: 'iPhone 11 Pro Max Used',
    fr: 'iPhone 11 Pro Max usagé',
  },
  iPhone12ProRefurbished: {
    en: 'iPhone 12 Pro Refurbished',
    fr: 'iPhone 12 Pro remis à neuf',
  },
  iPhone12Refurbished: {
    en: 'iPhone 12 Refurbished',
    fr: 'iPhone 12 Pro remis à neuf',
  },
  iPhone12ProMaxRefurbished: {
    en: 'iPhone 12 Pro Max Refurbished',
    fr: 'iPhone 12 Pro remis à neuf',
  },
  iPhone11ProRefurbished: {
    en: 'iPhone 11 Pro Refurbished',
    fr: 'iPhone 11 Pro remis à neuf',
  },
  iPhone11Refurbished: {
    en: 'iPhone 11 Refurbished',
    fr: 'iPhone 11 Pro remis à neuf',
  },
  iPhone11ProMaxRefurbished: {
    en: 'iPhone 11 Pro Max Refurbished',
    fr: 'iPhone 11 Pro remis à neuf',
  },
  usedIPhone11: {
    en: 'Used iPhone 11',
    fr: 'iPhone 11 usagé',
  },
  iPhoneXSMaxRefurbished: {
    en: 'iPhone XS Max Refurbished',
    fr: 'iPhone XS Max remis à neuf',
  },
  iPhoneXSUsed: {
    en: 'iPhone XS Used',
    fr: 'iPhone XS usagé',
  },
  iPhoneXRRefurbished: {
    en: 'iPhone XR Refurbished',
    fr: 'iPhone XR remis à neuf',
  },
  usedIPhoneX: {
    en: 'Used iPhone X',
    fr: 'iPhone X usagé',
  },
  usedIPhone8: {
    en: 'Used iPhone 8',
    fr: 'iPhone 8 usagé',
  },
  usedIPhone8Plus: {
    en: 'Used iPhone 8 Plus',
    fr: 'iPhone 8 Plus usagé',
  },
  usedIPhoneSE: {
    en: 'Used iPhone SE',
    fr: 'iPhone SE usagé',
  },
  usedIPhone7Plus: {
    en: 'Used iPhone 7 Plus',
    fr: 'iPhone 7 Plus usagé',
  },
  usedIPhone7: {
    en: 'Used iPhone 7',
    fr: 'iPhone 7 usagé',
  },
  usedIPhone6SPlus: {
    en: 'Used iPhone 6S Plus',
    fr: 'iPhone 6S Plus usagé',
  },
  usedIPhone6S: {
    en: 'Used iPhone 6S',
    fr: 'iPhone 6S usagé',
  },
  usedIPhoneSE2016: {
    en: 'Used iPhone SE 2016',
    fr: 'iPhone SE 2016 usagé',
  },
  samsungS20Plus: {
    en: 'Samsung S20 Plus',
    fr: 'Samsung S20 Plus',
  },
  galaxyS22: {
    en: 'Galaxy S22',
    fr: 'Galaxy S22',
  },
  galaxyS21: {
    en: 'Galaxy S21',
    fr: 'Galaxy S21',
  },
  galaxyS22plus5g: {
    en: 'Galaxy S22 Plus 5G',
    fr: 'Galaxy S22 Plus 5G',
  },
  galaxyS225g: {
    en: 'Galaxy S22 5G',
    fr: 'Galaxy S22 5G',
  },
  galaxyS21plus5g: {
    en: 'Galaxy S21 Plus 5G',
    fr: 'Galaxy S21 Plus 5G',
  },
  galaxyS215g: {
    en: 'Galaxy S21 5G',
    fr: 'Galaxy S21 5G',
  },
  galaxyS20fe5g: {
    en: 'Galaxy S20 FE 5G',
    fr: 'Galaxy S20 FE 5G',
  },
  galaxyS20: {
    en: 'Galaxy S20',
    fr: 'Galaxy S20',
  },
  samsungS10Plus: {
    en: 'Samsung S10 Plus',
    fr: 'Samsung S10 Plus',
  },
  samsungS10: {
    en: 'Samsung S10',
    fr: 'Samsung S10',
  },
  samsungS9Plus: {
    en: 'Samsung S9 Plus',
    fr: 'Samsung S9 Plus',
  },
  samsungS9: {
    en: 'Samsung S9',
    fr: 'Samsung S9',
  },
  samsungS8Plus: {
    en: 'Samsung S8 Plus',
    fr: 'Samsung S8 Plus',
  },
  usedGalaxyS8: {
    en: 'Used Galaxy S8',
    fr: 'Galaxy S8 usagé',
  },
  samsugS7Edge: {
    en: 'Samsung S7 Edge',
    fr: 'Samsung S7 Edge',
  },
  usedGalaxyS7: {
    en: 'Used Galaxy S7',
    fr: 'Galaxy S7 usagé',
  },
  samsungS6Edge: {
    en: 'Samsung S6 Edge',
    fr: 'Samsung S6 Edge',
  },
  galaxyS6Price: {
    en: 'Galaxy S6 Price',
    fr: 'Galaxy S6 Price',
  },
  popularSearches: {
    en: 'Popular Searches',
    fr: 'Recherches populaires',
  },
  copyright: {
    en: 'Copyright {year}, Orchard Labs Inc. All Rights Reserved.',
    fr: 'Copyright {year}, Orchard Labs Inc. Tous droits réservés.',
  },
};

export default messages;
