import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import BannerImagePng from '../../assets/images/home/banner.png';
import BannerImage from '../../assets/images/home/banner.webp';
import BannerExpImagePng from '../../assets/images/home/banner-0629.png';
import BannerExpImage from '../../assets/images/home/banner-0629.webp';
import Boundaries from '../../components/UI/Boundaries';
import LocaleLink from '../../components/UI/LocaleLink';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';

const bannerExp = window.orchardExperiments.find(
  (exp: GaExperiment) => exp.id === 'rO4nTB_mTEa4eX4-O7wi5A');
const showNewBanner = (bannerExp && bannerExp.variant === 1);
const BannerToUse = showNewBanner ? BannerExpImage : BannerImage;
const BannerPngToUse = showNewBanner ? BannerExpImagePng : BannerImagePng;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 670,
    backgroundImage: `url(${BannerToUse}), url(${BannerPngToUse})`,
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
    zIndex: 0,
    [theme.breakpoints.up(2500)]: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  },
  backdrop: {
    opacity: 0.7,
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  buttonSale: {
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    backgroundColor: '#FFFFFF',
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    borderRadius: showNewBanner ? "30px" : theme.shape.borderRadius,
  },
  button: {
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    backgroundColor: '#FFF765',
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    borderRadius: showNewBanner ? "30px" : theme.shape.borderRadius,
  },
  title: {
    fontFamily: theme.fonts.bold,
    marginTop: '10px',
  },
}));

const ShopOurPhones = () => {
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);

  const classes = useStyles();

  let horizontalMargin = 4;
  if (isMobile) {
    horizontalMargin = 2;
  } else if (isDesktop) {
    horizontalMargin = 8.5;
  }

  return (
    <div className={classes.root}>
      <Boundaries>
        <Box
          position="absolute"
          maxWidth={400}
          mx={horizontalMargin}
          bottom="18%"
          color={showNewBanner ? "black" : "white"}
          zIndex={2}
        >
          <Box mb={4}>
            <Typography
              className={classes.title}
              variant={isMobile ? 'h4' : 'h3'}
              color={showNewBanner ? "white" : "inherit"}
            >
              <FormattedMessage id={showNewBanner ? "yourNextPhoneSale": "yourNextPhone"} />
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="h6" color={showNewBanner ? "white" : "inherit"}>
              <FormattedMessage id={showNewBanner ? "junkTechSale": "financingPlansAvailable"} />
            </Typography>
          </Box>
          <Typography>
            <LocaleLink
              className={showNewBanner ? classes.buttonSale : classes.button}
              to="/search/shop-for-featured-phones/"
            >
              <FormattedMessage id={showNewBanner ? "shopTheSale" : "shopOurPhones"} />
            </LocaleLink>
          </Typography>
        </Box>
      </Boundaries>
      {isMobile && <div className={classes.backdrop} />}
    </div>
  );
};

export default ShopOurPhones;
