import { camelizeKeys } from 'humps';
import React, { createContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import api from '../api';
import Inventory, { InventoryDetails, InventoryItem } from '../types/Inventory';
import ProductTypes from '../types/ProductTypes';

interface Context {
  inventory: Inventory;
  inventoryDetails: InventoryDetails;
}

export const InventoryContext = createContext<Context>({
  inventory: {
    [ProductTypes.android]: [],
    [ProductTypes.iPhone]: [],
    [ProductTypes.iPad]: [],
  },
  inventoryDetails: {},
});

interface Props {
  children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
  const inventoryPage = window.orchardInventory || {
    android: [],
    apple: [],
    ipads: [],
  };

  const intl = useIntl();

  const [inventory, setInventory] = useState({
    [ProductTypes.android]: inventoryPage.android.map(
      (item: InventoryItem) => camelizeKeys(item),
    ),
    [ProductTypes.iPhone]: inventoryPage.apple.map(
      (item: InventoryItem) => camelizeKeys(item),
    ),
    [ProductTypes.iPad]: inventoryPage.ipads.map(
      (item: InventoryItem) => camelizeKeys(item),
    ),
  });

  const modelDetails: InventoryDetails = {};
  const defaultDetails = window.modelDetails;
  Object.keys(defaultDetails.models).forEach((modelKey) => {
    const model = defaultDetails.models[parseInt(modelKey, 10)];
    modelDetails[parseInt(modelKey, 10)] = {
      colors: model.colors.map((color: any) => ({
        label: defaultDetails.colors[color],
        value: color,
      })),
      storageSizes: model.storageSizes.map((size: any) => ({
        label: intl.formatMessage({ id: 'storageSize' }, { size }),
        value: size,
      })),
      carriers: model.carriers.map((carrier: any) => ({
        label: defaultDetails.carriers[carrier][0],
        value: carrier,
      })),
      specs: Object.keys(model.specs).map((specKey) => {
        const key = parseInt(specKey, 10);
        return {
          specName: defaultDetails.specNames[key - 1],
          specValue: model.specs[key],
          specOrdering: key,
        };
      }),
      batteryCapacity: model.battery_capacity,
      model: model.model,
      description: model.description,
      charger: model.charger,
      sim: model.sim,
    };
  });
  const [inventoryDetails, setInventoryDetails] = useState<InventoryDetails>(
    camelizeKeys(modelDetails) as InventoryDetails,
  );

  useEffect(() => {
    const fetchInventory = async () => {
      const { data } = await api.get('search-api/get-inventory/');
      setInventory({
        [ProductTypes.android]: data.android.map(
          (item: InventoryItem) => camelizeKeys(item),
        ),
        [ProductTypes.iPhone]: data.apple.map(
          (item: InventoryItem) => camelizeKeys(item),
        ),
        [ProductTypes.iPad]: data.ipads.map(
          (item: InventoryItem) => camelizeKeys(item),
        ),
      });
    };
    // fetchInventory();
  }, []);

  useEffect(() => {
    const fetchInventoryDetails = async () => {
      const { data } = await api.get('cart-api/get-combos/');
      setInventoryDetails(camelizeKeys(data) as InventoryDetails);
    };
    // fetchInventoryDetails();
  }, []);

  return (
    <InventoryContext.Provider value={{ inventory, inventoryDetails }}>
      {children}
    </InventoryContext.Provider>
  );
};

export default Provider;
