import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/Undo';
import HomeIcon from '@mui/icons-material/Home';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ScreenSizeContext } from '../contexts/ScreenSizeContext';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    marginLeft: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
}));
const ErrorPage = ({error}) => {
  const classes = useStyles();
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);
  const history = useHistory();

  let horizontalPadding = 7;
  if (isMobile) {
    horizontalPadding = 2;
  } else if (isDesktop) {
    horizontalPadding = 14;
  }
  const notFound = window.serverError
    && window.serverError.name
    && window.serverError.name.includes('404');
  return (
    <Box py={isMobile ? 3 : 5} px={horizontalPadding} style={{ textAlign: 'center', margin: 'auto' }}>
      <Typography variant="h2" color="textPrimary" component="span">
        <FormattedMessage id={!notFound ? "errorJs" : "error404"} />
      </Typography>
      <br />
      <Typography variant="h5" color="textPrimary" component="span">
        <FormattedMessage id={!notFound ? "unknownError" : "pageNotFound"} />
      </Typography>
      <br />
      <Typography variant="h5" color="textPrimary" component="span">
        <FormattedMessage id="inTheMeanTime" />
      </Typography>
      <br />
      <br />
      <Button className={classes.link} startIcon={<BackIcon />} variant="contained" onClick={() => history.goBack()}>Back</Button>
      <Button variant="contained" href="/" className={classes.link} endIcon={<HomeIcon />}>Home</Button><br />
    </Box>
  );
};

export default ErrorPage;
