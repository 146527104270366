import accessories from './accessories';
import cart from './cart';
import checkout from './checkout';
import errors from './errors';
import filters from './filters';
import footer from './footer';
import header from './header';
import home from './home';
import item from './item';
import ocp from './ocp';
import sell from './sell';
import shop from './shop';
import login from './login';
import dashboard from './dashboard';

const messages = {
  ...accessories,
  ...cart,
  ...checkout,
  ...errors,
  ...filters,
  ...footer,
  ...header,
  ...home,
  ...item,
  ...ocp,
  ...sell,
  ...shop,
  ...login,
  ...dashboard,
};

export default messages;
