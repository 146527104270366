import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import MobileNavOpenFooter from './MobileNavOpenFooter';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.secondary,
    textDecoration: 'none',
    fontFamily: 'Panton-Bold, sans-serif, serif',
    fontSize: "23px",
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    border: 'none',
    background: 'none',
    padding: 0,
  },
  menuItem: {
    minHeight: 0,
    fontFamily: 'Panton-Bold, sans-serif, serif',
  },
}));

interface Props {
  label: React.ReactNode;
  items: React.ReactNodeArray;
}

const MobileNavMenu = ({ label, items }: Props) => {
  const [visible, setVisibility] = useState(false);

  const classes = useStyles();

  const toggleVisibility = () => {
    setVisibility((prevState) => !prevState);
  };

  return (
    <Box>
      <button
        className={classes.button}
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          toggleVisibility();
        }}
      >
        <Typography className={classes.button} color="inherit">
          {label}
        {visible ? (
          <KeyboardArrowDownIcon color="inherit" sx={{position:"relative"}} />
        ) : (
          <KeyboardArrowRightIcon color="inherit" sx={{position:"relative"}} />
        )}
        </Typography>
      </button>
      <Collapse in={visible}>
        <MenuList>{items}</MenuList>
      </Collapse>
    </Box>
  );
};

export default MobileNavMenu;
