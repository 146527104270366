const messages = {
  buyingHistory: {
    en: 'Buying History',
    fr: 'Buying History',
  },
  tradeIn: {
    en: 'Trade In',
    fr: 'Trade In',
  },
  sellingHistory: {
    en: 'Selling History',
    fr: 'Selling History',
  },
  phoneListingSummary: {
    en: 'Phone Listing Summary',
    fr: 'Phone Listing Summary fr',
  },
  amountQuoted: {
    en: '$ {value}',
    fr: '{value} $',
  },
  trackPackage: {
    en: 'Track',
    fr: 'Track fr',
  },
  startOver: {
    en: 'Start Over',
    fr: 'Start Over fr',
  },
  hereIsPhone: {
    en: 'The phone you want to trade in is {value}',
    fr: 'The phone you want to trade in is {value} fr',
  },
  hereIsOffer: {
    en: 'Our preliminary offer is $ {value}',
    fr: 'Our preliminary offer is {value $}',
  },
  tradeInSteps: {
    en: 'Trade in steps',
    fr: 'Trade in steps fr',
  },
  tradeInInstructions1: {
    en: '1. Receive promo code for your current phone',
    fr: '1. Receive promo code for your current phone fr',
  },
  tradeInInstructions2: {
    en: '2. Purchase your new(ish) phone using promo code',
    fr: '2. Purchase your new(ish) phone using promo code fr',
  },
  tradeInInstructions3: {
    en: "3. We'll deliver your new phone",
    fr: "3. We'll deliver your new phone fr",
  },
  tradeInInstructions4: {
    en: '4. Use the included shipping label to send back your current phone',
    fr: '4. Use the included shipping label to send back your current phone fr',
  },
  ccOnlyWarning: {
    en: 'Note: purchases using your promo code must be made on credit card.',
    fr: 'Note: purchases using your promo code must be made on credit card. fr',
  },
  tradeInStep0: {
    en: 'Review Terms',
    fr: 'Review Terms fr',
  },
  tradeInStep1: {
    en: 'Get Promo Code',
    fr: 'Get Promo Code fr',
  },
  tradeInThanks: {
    en: 'Click below to copy your promocode. You can also come back to this page any time or find the promocode in the email confirmation you will recieve',
    fr: 'Click below to copy your promocode. You can also come back to this page any time or find the promocode in the email confirmation you will recieve fr',
  },
  copyPromo: {
    en: "{value}",
    fr: "{value} fr",
  }
};
export default messages;
