import { createTheme, Theme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    desktop: true;
  }
  interface Palette {
    pinkish: Palette['primary'];
    grayish: Palette['primary'];
  }
  interface PaletteOptions {
    pinkish: PaletteOptions['primary'];
    grayish: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    fonts: {
      bold: React.CSSProperties['fontFamily'];
      thin: React.CSSProperties['fontFamily'];
    };
  }
  interface ThemeOptions {
    fonts?: {
      bold?: React.CSSProperties['fontFamily'];
      thin?: React.CSSProperties['fontFamily'];
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 768,
      desktop: 1200,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  fonts: {
    bold: '"Panton-Extra-Bold", sans-serif, serif',
    thin: '"Panton-Thin", sans-serif, serif',
  },
  palette: {
    primary: {
      main: '#6499B0',
      secondary: '#0187A7',
    },
    text: {
      primary: '#777',
      secondary: '#909090',
    },
    pinkish: {
      main: '#ddb2ba',
    },
    grayish: {
      main: '#c8c8c8',
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2)',
    '0px 3px 1px -2px rgba(0,0,0,0.2)',
    '0px 3px 3px -2px rgba(0,0,0,0.2)',
    '0px 2px 4px -1px rgba(0,0,0,0.2)',
    '0px 3px 5px -1px rgba(0,0,0,0.2)',
    '0px 3px 5px -1px rgba(0,0,0,0.2)',
    '0px 4px 5px -2px rgba(0,0,0,0.2)',
    '0px 5px 5px -3px rgba(0,0,0,0.2)',
    '0px 5px 6px -3px rgba(0,0,0,0.2)',
    '0px 6px 6px -3px rgba(0,0,0,0.2)',
    '0px 6px 7px -4px rgba(0,0,0,0.2)',
    '0px 7px 8px -4px rgba(0,0,0,0.2)',
    '0px 7px 8px -4px rgba(0,0,0,0.2)',
    '0px 7px 9px -4px rgba(0,0,0,0.2)',
    '0px 8px 9px -5px rgba(0,0,0,0.2)',
    '0px 8px 10px -5px rgba(0,0,0,0.2)',
    '0px 8px 11px -5px rgba(0,0,0,0.2)',
    '0px 9px 11px -5px rgba(0,0,0,0.2)',
    '0px 9px 12px -6px rgba(0,0,0,0.2)',
    '0px 10px 13px -6px rgba(0,0,0,0.2)',
    '0px 10px 13px -6px rgba(0,0,0,0.2)',
    '0px 10px 14px -6px rgba(0,0,0,0.2)',
    '0px 11px 14px -7px rgba(0,0,0,0.2)',
    '0px 11px 15px -7px rgba(0,0,0,0.2)',
  ],
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontFamily: '"Panton-Regular", sans-serif, serif',
  },
});

export default theme;
