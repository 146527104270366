const formats = {
  date: {
    full: {
      en: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      fr: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    short: {
      en: { day: '2-digit', month: '2-digit', year: '2-digit' },
      fr: { day: '2-digit', month: '2-digit', year: '2-digit' },
    },
    dayAndMonth: {
      en: { day: 'numeric', month: 'numeric' },
      fr: { day: 'numeric', month: 'numeric' },
    },
  },
  time: {
    hour: {
      en: { hour: 'numeric' },
      fr: { hour: 'numeric' },
    },
  },
};

export default formats;
