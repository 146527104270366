import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { countCartItems } from '../../helpers/cartFunctions';

const CartIcon = ({isMobile}) => {
  const { cart } = useContext(CartContext);

  const numberOfItems = countCartItems(cart);

  return (
    <Badge badgeContent={numberOfItems ? (<Typography sx={{fontFamily: "Panton-Bold, sans serif, serif", color: "#0187A7", marginTop:"2px"}}>{numberOfItems}</Typography>) : undefined} color="pinkish">
      <ShoppingCartOutlinedIcon sx={{color:"#0187A7", fontSize: "1.1em"}} />
    </Badge>
  );
};

export default CartIcon;
