import React, { useContext } from 'react';
import { HeaderAndFooterContext } from '../../contexts/HeaderAndFooterContext';
import BbbAndLinks from './BbbAndLinks';
import Copyright from './Copyright';
import PopularSearches from './PopularSearches';

const Footer = () => {
  const { showFooter, showCopyright } = useContext(HeaderAndFooterContext);

  return (
    <>
      { showFooter ? (
        <>
          <BbbAndLinks />
          <PopularSearches />
        </>
      ) : null}
      { showCopyright ? (
        <Copyright />
      ) : null}
    </>
  );
};

export default Footer;
