import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SubscriptionBackgroundImagePng from '../../assets/images/home/subscribe-bg.png';
import SubscriptionBackgroundImage from '../../assets/images/home/subscribe-bg.webp';
import Boundaries from '../../components/UI/Boundaries';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import { emailRegex } from '../../helpers/regex';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${SubscriptionBackgroundImage}), url(${SubscriptionBackgroundImagePng})`,
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
  },
  button: {
    height: '100%',
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.8),
      boxShadow: 'none',
    },
  },
  buttonMobile: {
    margingTop: theme.spacing(2),
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.8),
      boxShadow: 'none',
    },
  },
  form: {
    display: 'grid',
    width: 550,
    maxWidth: '100%',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('mobile')]: {
      gridTemplateColumns: 'minmax(0, 2fr) minmax(0, 1fr)',
    },
  },
  textField: {
    backgroundColor: theme.palette.common.white,
    '& input::placeholder': {
      opacity: 1,
    },
  },
  title: {
    fontFamily: theme.fonts.thin,
    fontWeight: 'bold',
  },
}));

interface Props {
  onSubmit: () => void;
}

interface FormInput {
  email: string;
}

const RhfSubscriptionForm = ({ onSubmit }: Props) => {
  const { isMobile } = useContext(ScreenSizeContext);
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInput>();
  const intl = useIntl();

  const classes = useStyles();

  return (
    <Box className={classes.root} py={8} px={2}>
      <Boundaries>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          color="white"
        >
          <Typography
            className={classes.title}
            variant={isMobile ? 'h5' : 'h4'}
          >
            <FormattedMessage id="stayInTheKnow" />
          </Typography>
          <Typography align="center">
            <FormattedMessage id="keepInTheLoop" />
          </Typography>
          <form onSubmit={onSubmit} className={classes.form}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: emailRegex,
              }}
              defaultValue=""
              render={({
                field: { value, onChange },
                fieldState: { invalid },
              }) => (
                <TextField
                  variant="outlined"
                  value={value}
                  placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
                  error={invalid}
                  helperText={errors.email?.message}
                  onChange={onChange}
                  fullWidth
                  InputProps={{ classes: { root: classes.textField } }}
                />
              )}
            />
            <Button
              className={isMobile ? classes.buttonMobile : classes.button}
              color="primary"
              variant="contained"
              fullWidth={!isMobile}
              type="submit"
            >
              <Typography variant="h6">
                <FormattedMessage id="submit" />
              </Typography>
            </Button>
          </form>
        </Box>
      </Boundaries>
    </Box>
  );
};

export default RhfSubscriptionForm;
