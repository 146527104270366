import React, { useContext } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { I18nContext } from '../../contexts/I18nContext';

const LocaleNavLink = ({
  activeClassName,
  className,
  children,
  to,
  ...rest
}: NavLinkProps) => {
  const { locale } = useContext(I18nContext);

  let href = to;
  if (locale && typeof href === 'string') {
    href = `/${locale}${to}`;
    while(href.includes('/fr/fr')) {
      href = href.replace('/fr/fr', '/fr');
    }
  }

  const {
    component,
    replace,
    innerref,
  } = rest;
  const target = href.toString().includes('blog') || href.toString().includes('faqs') ? '_blank' : rest.target;

  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={href}
      {...{
        component,
        replace,
        innerref,
        target,
      }}
    >
      {children}
    </NavLink>
  );
};

export default LocaleNavLink;
