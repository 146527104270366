/* eslint-disable no-template-curly-in-string */
const messages = {
  giftCardSale: {
    en: "Get 50% off gift cards up to $100 (applied at checkout). Until Valentine's Day only",
    fr: "Obtenez 50 % de rabais sur les cartes-cadeaux jusqu'à 100 $ (appliqué à la caisse). Offre valide jusqu'à la Saint-Valentin seulement",
  },
  results: {
    en: 'Results',
    fr: 'Results',
  },
  tellUsAboutRecipient: {
    en: 'Tell us about who the gift card is for',
    fr: 'Tell us about who the gift card is for fr',
  },
  recipientEmail: {
    en: 'Recipient Email',
    fr: 'Recipient Email fr',
  },
  recipientName: {
    en: 'Recipient Name',
    fr: 'Recipient Name fr',
  },
  recipientMessage: {
    en: 'Do you want to add a personalized message (optional)',
    fr: 'Recipient Message fr',
  },
  whenToSend: {
    en: 'When To Send',
    fr: 'When To Send fr',
  },
  filtersHelp: {
    en: 'Sort our inventory using these filters',
    fr: 'Trier notre inventaire en utilisant ces filtres',
  },
  filtersApplied: {
    en: 'Filters applied (tap to remove)',
    fr: 'Filtres appliqués (appuyer pour supprimer)',
  },
  priceFormatted: {
    en: '$ {value}',
    fr: '{value} $',
  },
  priceFormattedShorter: {
    en: '${value}',
    fr: '{value}$',
  },
  priceRangeFormatted: {
    en: '${min} - ${max}',
    fr: '{min}$ - {max}$',
  },
  creditFormatted: {
    en: '${credit}',
    fr: '{credit}$',
  },
  showOnSaleOnly: {
    en: 'Show On Sale Only',
    fr: 'Voir les soldes',
  },
  doorcrasher: {
    en: 'Door Crasher',
    fr: 'Rabais additionnel',
  },
  priceRange: {
    en: 'Price Range',
    fr: 'Gamme de Prix',
  },
  priceRangeOddit: {
    en: 'Price',
    fr: 'Gamme de Prix',
  },
  shopTheGreatest: {
    en: 'Shop the almost latest and greatest',
    fr: 'Magasiner les presque plus récents et meilleurs',
  },
  bigSavings: {
    en: 'Big Savings vs Buying New | Free Shipping and Returns',
    fr: 'Grandes économies par rapport à acheter à neuf | Livraison et retours gratuits',
  },
  models: {
    en: 'Models',
    fr: 'Modèles',
  },
  memory: {
    en: 'Memory',
    fr: 'Tailles',
  },
  conditions: {
    en: 'Conditions',
    fr: 'États',
  },
  colors: {
    en: 'Colours',
    fr: 'Couleurs',
  },
  connectivity: {
    en: 'Connectivity',
    fr: 'Connectivité',
  },
  includedItemsQuestion: {
    en: 'What is included with the purchase of this phone?',
    fr: "Que comprend l'achat de ce téléphone?",
  },
  includedItemsAnswer: {
    en: 'Each phone comes with a third-party charger cable and block. Other accessories, like Earpods and screen protectors are sold separately. You can add these onto your order at checkout.',
    fr: "Chaque téléphone est livré avec un câble de chargement tiers et un bloc. D'autres accessoires, comme les Earpods et les protecteurs d'écran sont vendus séparément. Vous pouvez les ajouter à votre commande à la caisse.",
  },
  carrierQuestion: {
    en: 'Will this phone work with my carrier?',
    fr: 'Ce téléphone fonctionnera-t-il avec mon fournisseur?',
  },
  carrierAnswer: {
    en: "We guarantee each phone will work with the carrier listed. All phones listed as Unlocked will work on all major wireless carriers. Check each phone's individual product page for more information.",
    fr: "Nous garantissons que chaque téléphone fonctionnera avec le fournisseur listé. Tous les téléphones listés comme déverrouillés fonctionneront avec tous les principaux fournisseurs sans fil. Consultez la page produit de chaque téléphone pour plus d'informations.",
  },
  arrivalQuestion: {
    en: 'How long will it take for my phone to arrive?',
    fr: 'Combien de temps faut-il pour que mon téléphone arrive?',
  },
  arrivalAnswer: {
    en: "We'll ship your phone on the next business day, so depending how far you are from Toronto, it will take 2-5 business days to arrive",
    fr: 'Nous vous enverrons votre téléphone le jour ouvrable suivant, donc selon la distance que vous êtes de Toronto, il faudra entre 2 et 5 jours ouvrables avant de le recevoir.',
  },
  shippingCostQuestion: {
    en: 'How much does the shipping cost?',
    fr: 'Quels sont les frais de livraison?',
  },
  shippingCostAnswer: {
    en: "We'll pay for Expedited shipping, but if you need it sooner, we offer Priority shipping (that costs extra)",
    fr: 'Nous paierons pour la livraison accélérée, mais si vous en avez besoin plus tôt, nous offrons la livraison prioritaire (frais supplémentaires).',
  },
  conditionsQuestion: {
    en: 'What does the device condition mean?',
    fr: "Que signifie l'état de l'appareil?",
  },
  missingcartitems: {
    en: 'Unfortunately the items in your cart are no longer available',
    fr: 'Les articles de votre panier ne sont malheureusement plus disponibles.',
  },
  searchPageBackorder: {
    en: 'Ships in 2-3 weeks',
    fr: 'Expédition sous 2-3 semaines',
  },
  conditionsAnswer: {
    en: 'The conditions Good, Very Good, and Like New only refer to the cosmetic condition of each phone. Every phone in our inventory will function like new, guaranteed.',
    fr: "Les états Bon, Très Bon, et Comme neuf ne se réfèrent qu'à l'état cosmétique de chaque téléphone. Chaque téléphone de notre inventaire fonctionnera comme neuf, garanti.",
  },
  conditionsReadMore: {
    en: 'You can read more specific information on each condition here.',
    fr: "Vous pouvez lire plus d'informations spécifiques sur chaque état ici.",
  },
  allModels: {
    en: 'All {productType} Models',
    fr: 'Tous les modèles {productType}',
  },
  fromPrice: {
    en: 'From ${price}',
    fr: 'À partir de {price} $',
  },
  storageSize: {
    en: '{size}GB ',
    fr: '{size}Go ',
  },
  displaySize: {
    en: '{displaySize} inch display',
    fr: 'Écran de {displaySize} pouces',
  },
  reduced: {
    en: 'Reduced',
    fr: 'À prix réduit',
  },
  outOfStock: {
    en: 'Currently Out of Stock',
    fr: 'Actuellement en rupture de stock',
  },
  brandNewOutOfStock: {
    en: 'We have no more of these items in brand new condition',
    fr: "Nous n'avons plus ces articles neufs",
  },
  beTheFirstToKnow: {
    en: "Be the first to know when we've added more phones that match your search. Leave your email below to set an in-stock alert.",
    fr: 'Soyez le premier à être informé lorsque nous avons ajouté des téléphones correspondant à votre recherche. Laissez votre courriel ci-dessous pour créer une alerte de stock',
  },
  emailAddressPlaceholder: {
    en: 'Email address...',
    fr: 'Adresse courriel',
  },
  setAlert: {
    en: 'Set Alert',
    fr: 'Créer une alerte',
  },
  alertSet: {
    en: 'Alert set!',
    fr: 'Alerte créée',
  },
  mainSection: {
    en: '',
    fr: '',
  },
  brandNewSection: {
    en: "Orchard's Inventory of Brand New Devices",
    fr: "Les Appareils Neufs d'Orchard",
  },
  overSection: {
    en: 'These {product}s are a bit pricier, but otherwise match your search',
    fr: 'Ces {product}s sont un peu plus chers mais correspondent à votre recherche',
  },
  underSection: {
    en: 'These {product}s cost less, but otherwise match your search',
    fr: 'Ces {product}s sont moins chers mais correspondent à votre recherche',
  },
  similarSection: {
    en: 'People also bought these similar {product}s',
    fr: 'Les gens ont également acheté ces {product}s similaires',
  },
};

export default messages;
