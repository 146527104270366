import Accessory from '../types/Accessories';
import api from '../api';
import { InventoryItem } from '../types/Inventory';

export const initializeTracking = () => {
  if (window.CAME_FROM_FACEBOOK) {
    window.dataLayer.push({
      event: 'fbq_event',
      eventName: 'CameFromFacebookAd',
    });
  }
};

const formatId = (id: number, leadingDigit = 1) => `${leadingDigit}${id.toString().padStart(5, '0')}`;

const alreadySeen: {
  [key: number]: boolean;
} = {};

const alreadyPurchased: {
  [key: number]: boolean;
} = {};

const checkoutStepsCompleted: {
  [key: number]: boolean;
} = {};

export const trackPixelSimple = (event: string) => {
  window.dataLayer.push({
    event: 'fbq_event',
    eventName: event,
    fbParams: {},
  });
  api.get(`checkout-api/fbq-event/?event=${event}`);
};
const trackPixel = (event: string, item: InventoryItem, eventId: number) => {
  const dataLayerEvent = {
    event: 'fbq_event',
    eventName: event,
    fbParams: {
      content_ids: [formatId(item.sku)],
      content_type: 'product',
      value: item.price - item.sale,
      currency: 'CAD',
    },
  };
  let apiString = `checkout-api/fbq-event/?event=${event}&content_id=${formatId(item.sku)}&value=${item.price - item.sale}`;
  if (eventId) {
    dataLayerEvent.fbParams.eventId = eventId;
    apiString += "&event_id=" + eventId;
  }
  window.dataLayer.push(dataLayerEvent);
  api.get(apiString);
};

export const trackOrderCompleted = (order: OrderReceipt) => {
  const products = [];
  const ga4products = [];
  const phoneIds = [];
  const tikTokContents = [];
  order.items.forEach((item) => {
    let category = '';
    let brand = '';
    switch (item.type_id) {
      case 0:
        category = 'Phone';
        if (item.name.includes("iPad")) {
          category = "iPad";
          brand = "Apple";
        } else if (item.name.includes("Android")) {
          category = "Android";
          if (item.name.includes("Galaxy")) {
            brand = "Samsung";
          } else if (item.name.includes("Pixel")) {
            brand = "Google";
          }
        } else {
          category = "iPhone";
          brand = "Apple";
        }
        phoneIds.push(item.id);
        tikTokContents.push({
          content_id: item.id,
          content_type: 'product',
          content_category: category,
          content_name: item.name,
          quantity: item.qty,
          price: item.price,
        });
        break;
      case 1:
        category = 'Accessory';
        break;
      case 2:
        category = 'Promocode';
        break;
      default:
        break;
    }
    products.push({
      category,
      id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.qty,
    });
    ga4products.push({
      item_id: item.id,
      item_name: item.name,
      price: item.price,
      quantity: item.qty,
      item_category: category,
      item_brand: brand,
    });
  });
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      purchase: {
        actionField: {
          piie: order.email,
          id: order.number,
          affiliation: 'Orchard Labs Inc.',
          revenue: order.amount,
          tax: order.tax,
        },
        products,
      },
    },
    tikTokContents,
    phoneIds,
    userData: {
      email: order.email,
      address: {
        first_name: order.shipping.firstName,
        last_name: order.shipping.lastName,
        street: order.shipping.street,
        city: order.shipping.city,
        postal_code: order.shipping.postalCode,
        region: order.shipping.province,
        country: 'CA',
      },
    },
  });
  gtag('event', 'purchase', {
    send_to: window.OR_GA4_CODE,
    transaction_id: order.number,
    affiliation: 'Orchard Labs Inc.',
    currency: 'CAD',
    value: order.amount,
    tax: order.tax,
    items: ga4products,
  });
};
export const measureCheckoutOption = (step, option) => {
  const actionField = { step, option };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField,
      },
    },
  });
};
export const measureCheckoutStep = (cart, step, option = '') => {
  if (checkoutStepsCompleted[step]) {
    return;
  }
  const products = cart.map((item) => {
    let category = '';
    let brand = '';
    switch (item.options.itemType) {
      case 0:
        category = 'Phone';
        if (item.name.includes("iPad")) {
          category = "iPad";
          brand = "Apple";
        } else if (item.name.includes("Android")) {
          category = "Android";
          if (item.name.includes("Galaxy")) {
            brand = "Samsung";
          } else if (item.name.includes("Pixel")) {
            brand = "Google";
          }
        } else {
          category = "iPhone";
          brand = "Apple";
        }
        break;
      case 1:
        category = 'Accessory';
        break;
      case 2:
        category = 'Promocode';
        break;
      default:
        break;
    }
    return {
      category,
      id: item.id,
      name: item.name,
      price: item.price,
      quantity: item.qty,
      category: category,
      brand: brand,
    }
  });
  let cart_total = 0;
  const ga4products = products.map((item) => {
    cart_total += item.price
    return {
      item_id: item.id,
      item_name: item.name,
      currency: 'CAD',
      item_brand: item.brand,
      item_category: item.category,
      price: item.price,
      quantity: item.qty,
    };
  });
  const actionField = { step };
  if (option !== '') {
    actionField.option = option;
  }
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField,
        products,
      },
    },
  });
  if (step == 1) {
    gtag('event', 'begin_checkout', {
      send_to: window.OR_GA4_CODE,
      currency: 'CAD',
      value: cart_total,
      items: ga4products,
    });
  }
  if (step == 3) {
    gtag('event', 'add_shipping_info', {
      send_to: window.OR_GA4_CODE,
      currency: 'CAD',
      value: cart_total,
      items: ga4products,
    })
  }
  if (step == 4) {
    gtag('event', 'add_payment_info', {
      send_to: window.OR_GA4_CODE,
      event: 'add_payment_info',
      currency: 'CAD',
      value: cart_total,
      items: ga4products,
    })
  }
  checkoutStepsCompleted[step] = true;
};

export const trackProductPurchased = (item: InventoryItem, orderId) => {
  if (alreadyPurchased[item.sku]) {
    return;
  }
  alreadyPurchased[item.sku] = true;

  window.dataLayer.push({
    event: 'product_purchased',
    product: {
      category: 'phone',
      ecomm_pagetype: 'product',
      ecomm_prodid: formatId(item.sku),
      ecomm_totalvalue: item.price - item.sale,
      isSaleItem: item.sale > 0,
      available: item.available,
      pinterest_id: formatId(item.sku),
    },
  });
  if (item.type_id == 0) {
    trackPixel('Purchase', item, orderId);
  }
};

export const trackProductViewed = (item: InventoryItem) => {
  if (alreadySeen[item.sku]) {
    return;
  }
  alreadySeen[item.sku] = true;
  let category = "";
  let brand = "";
  if (item.name.includes("iPad")) {
    category = "iPad";
    brand = "Apple";
  } else if (item.name.includes("Android")) {
    category = "Android";
    if (item.name.includes("Galaxy")) {
      brand = "Samsung";
    } else if (item.name.includes("Pixel")) {
      brand = "Google";
    }
  } else {
    category = "iPhone";
    brand = "Apple";
  }

  window.dataLayer.push({
    event: 'product_viewed',
    product: {
      category: 'phone',
      ecomm_pagetype: 'product',
      ecomm_prodid: formatId(item.sku),
      ecomm_totalvalue: item.price - item.sale,
      isSaleItem: item.sale > 0,
      available: item.available,
      pinterest_id: formatId(item.sku),
      variant: item.color,
      name: item.name,
      true_category: category,
      true_id: item.sku,
      url: window.location.href,
      brand: brand,
      imageUrl: BASE_URL + item.imageUrl, 
    },
  });
  gtag('event', 'view_item', {
    send_to: window.OR_GA4_CODE,
    currency: 'CAD',
    value: item.price - item.sale,
    items: [{
      index: 0,
      item_id: formatId(item.sku),
      item_name: item.name,
      currency: 'CAD',
      item_brand: brand,
      price: item.price - item.sale,
      item_category: category,
      item_variant: item.color,
      quantity: 1,
    }],
  })
  trackPixel('ViewContent', item);
};

export const trackProductAddedToCart = (item: InventoryItem, quantity: number, CheckoutURL?: string) => {
  let category = "";
  let brand = "";
  if (item.name.includes("iPad")) {
    category = "iPad";
    brand = "Apple";
  } else if (item.name.includes("Android")) {
    category = "Android";
    if (item.name.includes("Galaxy")) {
      brand = "Samsung";
    } else if (item.name.includes("Pixel")) {
      brand = "Google";
    }
  } else {
    category = "iPhone";
    brand = "Apple";
  }
  window.dataLayer.push({
    event: 'add_to_cart_gtag',
    product: {
      id: formatId(item.sku),
      category: 'phone',
      name: item.name,
      variant: item.color,
      quantity,
      price: item.price - item.sale,
      pinterest_id: formatId(item.sku),
      true_category: category,
      true_id: item.sku,
      url: window.location.href,
      brand: brand,
      imageUrl: BASE_URL + item.imageUrl, 
    },
    CheckoutURL,
  });
  gtag('event', 'add_to_cart', {
    send_to: window.OR_GA4_CODE,
    currency: 'CAD',
    value: item.price - item.sale,
    items: [{
      item_id: formatId(item.sku),
      item_name: item.name,
      item_variant: item.color,
      quantity,
      price: item.price - item.sale,
      item_category: category,
      item_brand: brand,
    }],
    CheckoutURL,
  });
  trackPixel('AddToCart', item);
};

export const trackSimpleEvent = (event) => {
  gtag('event', event, {
    send_to: window.OR_GA4_CODE,
    eventName: event,
  });
};

export const trackAccessoryAddedToCart = (accessory: Accessory, quantity: number) => {
  const id = formatId(accessory.accessoryId, 2);
  const price = accessory.price - (accessory.saleAmount || 0);

  window.dataLayer.push({
    event: 'add_to_cart',
    product: {
      id,
      category: 'accessory',
      name: accessory.name,
      quantity,
      price,
      pinterest_id: id,
    },
  });
  gtag('event', 'add_to_cart', {
    send_to: window.OR_GA4_CODE,
    currency: 'CAD',
    value: price,
    items: [{
      item_id: id,
      item_name: accessory.name,
      quantity,
      price,
      item_category: 'accessory',
    }],
  });
};

export default {
  initializeTracking,
  trackProductViewed,
  trackProductPurchased,
  trackOrderCompleted,
  trackProductAddedToCart,
  trackAccessoryAddedToCart,
  trackPixelSimple,
  trackSimpleEvent,
};
