import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { createContext } from 'react';

export const ScreenSizeContext = createContext({
  isMobile: true,
  isDesktop: false,
});

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

export const Provider = ({ children }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  return (
    <ScreenSizeContext.Provider value={{ isMobile, isDesktop }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

export default Provider;
