import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import SimsImagePng from '../../assets/images/home/sims.png';
import SimsImage from '../../assets/images/home/sims.webp';
import Boundaries from '../../components/UI/Boundaries';
import LinkButton from '../../components/UI/LinkButton';
import Picture from '../../components/UI/Picture';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';

const useStyles = makeStyles(() => ({
  image: {
    display: 'block',
    width: '90%',
    height: 'auto',
    margin: 'auto',
  },
}));

const UnlockedPhones = () => {
  const { isMobile } = useContext(ScreenSizeContext);

  const classes = useStyles();

  return (
    <Box py={7.5} px={isMobile ? 4 : 7}>
      <Boundaries>
        <Box mb={3}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            color="primary"
            align="center"
          >
            <FormattedMessage id="getConnected" />
          </Typography>
        </Box>
        <Typography
          variant={isMobile ? 'h6' : 'h5'}
          color="textPrimary"
          align="center"
        >
          <FormattedMessage id="bringToYourPlan" />
        </Typography>
        <Picture
          className={classes.image}
          alt="sims"
          webp={SimsImage}
          png={SimsImagePng}
          height={250}
        />
        <Box mt={2} maxWidth={280} mx="auto">
          <LinkButton to="/search/shop-for-phones-for-unlocked/">
            <FormattedMessage id="shopOurUnlockedPhones" />
          </LinkButton>
        </Box>
      </Boundaries>
    </Box>
  );
};

export default UnlockedPhones;
