import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomerServiceImagePng from '../../assets/images/home/customer-service.png';
import CustomerServiceImage from '../../assets/images/home/customer-service.webp';
import Boundaries from '../../components/UI/Boundaries';
import LinkButton from '../../components/UI/LinkButton';
import { ScreenSizeContext } from '../../contexts/ScreenSizeContext';
import Reviews from './Reviews';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('mobile')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('desktop')]: {
      padding: theme.spacing(8.5),
    },
    '&::before': {
      top: 0,
      left: 0,
      content: '" "',
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: `url(${CustomerServiceImage}), url(${CustomerServiceImagePng})`,
      backgroundSize: 'cover',
      backgroundPositionX: '86%',
      opacity: 0.1,
      zIndex: 1,
      [theme.breakpoints.up('mobile')]: {
        backgroundPositionX: '65%',
        opacity: 1,
      },
      [theme.breakpoints.up(2500)]: {
        backgroundPositionX: '50%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
  },
}));

const WhyOrchard = () => {
  const { isMobile, isDesktop } = useContext(ScreenSizeContext);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Boundaries>
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          maxWidth={isMobile ? '100%' : '55%'}
          my={4}
          zIndex={2}
        >
          <Box mb={5}>
            <Typography
              variant={isMobile ? 'h4' : 'h3'}
              color="primary"
              align={isMobile ? 'center' : 'inherit'}
            >
              <FormattedMessage id="safeAndHassleFree" />
            </Typography>
          </Box>
          <Box mb={6} width={isMobile ? '100%' : '80%'}>
            <Typography
              variant={isMobile ? 'h6' : 'h5'}
              color="textPrimary"
              align={isMobile ? 'center' : 'inherit'}
            >
              <FormattedMessage id="orchardBuysUsed" />
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems={isDesktop || isMobile ? 'center' : 'start'}
          >
            <Reviews />
            <Box width={280} mt={isDesktop ? 0 : 3}>
              <LinkButton
                to={{
                  pathname:
                    'https://www.google.ca/#q=Orchard,+1139+College+St,+Toronto,+ON+M6H+1B5&ludocid=14213782829690899286&lrd=0x882b3450570ace13:0xc541802cfe45cb56,1',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="readMoreOnGooglePlus" />
              </LinkButton>
            </Box>
          </Box>
        </Box>
      </Boundaries>
    </div>
  );
};

export default WhyOrchard;
