import { TypographyVariant } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useIntl } from 'react-intl';
import LocaleNavLink from '../../UI/LocaleNavLink';

const useStyles = makeStyles((theme) => ({
  link: {
    width: '100%',
    color: theme.palette.primary.secondary,
    textDecoration: 'none',
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontSize: "23px",
  },
  lighter: {
    width: '100%',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontSize: "23px",
  },
  active: {
    textDecoration: 'underline',
    color: theme.palette.primary.secondary,
    fontFamily: '"Panton-Bold", sans-serif, serif',
    fontSize: "23px",
  },
}));

interface Props {
  label: string | React.ReactNode;
  path: string;
  showActive?: boolean;
  ext?: boolean;
  variant?: TypographyVariant;
  lighter?: boolean;
}

const NavItem = ({
  label,
  path,
  showActive = true,
  ext = false,
  variant = 'body1',
  lighter = false,
}: Props) => {
  const classes = useStyles();

  const isFrench = useIntl().locale === "fr";
  const hrefPath= isFrench ? `/fr${path}`: path;
  return !ext ? (
    <LocaleNavLink
      className={lighter ? classes.lighter : classes.link}
      activeClassName={showActive ? classes.active : ''}
      to={path}
    >
      <Typography variant="body2" className={lighter ? classes.lighter : classes.link}>{label}</Typography>
    </LocaleNavLink>
  ) : (
    <a
      href={hrefPath.replace('fr/en', 'en')}
      className={classes.link}
    >
      <Typography className={lighter ? classes.lighter : classes.link} variant={variant}>{label}</Typography>
    </a>
  );
};

NavItem.defaultProps = {
  showActive: true,
  variant: 'body1',
};

export default NavItem;
