const messages = {
  'watch-se-2022-40mm': {
    en: 'Apple Watch SE 40mm (2022)',
    fr: 'Apple Watch SE 40mm (2022)',
  },
  'watch-se-2020-40mm': {
    en: 'Apple Watch SE 40mm (2020)',
    fr: 'Apple Watch SE 40mm (2020)',
  },
  'apple-watch-se-2022-40mm': {
    en: 'Apple Watch SE 40mm (2022)',
    fr: 'Apple Watch SE 40mm (2022)',
  },
  'apple-watch-se-2020-40mm': {
    en: 'Apple Watch SE 40mm (2020)',
    fr: 'Apple Watch SE 40mm (2020)',
  },
  'iphone-se-2022': {
    en: 'iPhone SE (2022)',
    fr: 'iPhone SE (2022)',
  },
  'iphone-14-pro-max': {
    en: 'iPhone 14 Pro Max',
    fr: 'iPhone 14 Pro Max',
  },
  'iphone-14-pro': {
    en: 'iPhone 14 Pro',
    fr: 'iPhone 14 Pro',
  },
  'iphone-14-plus': {
    en: 'iPhone 14 Plus',
    fr: 'iPhone 14 Plus',
  },
  'iphone-14': {
    en: 'iPhone 14',
    fr: 'iPhone 14',
  },
  'iphone-13-pro-max': {
    en: 'iPhone 13 Pro Max',
    fr: 'iPhone 13 Pro Max',
  },
  'iphone-13-pro': {
    en: 'iPhone 13 Pro',
    fr: 'iPhone 13 Pro',
  },
  'iphone-13': {
    en: 'iPhone 13',
    fr: 'iPhone 13',
  },
  'iphone-13-mini': {
    en: 'iPhone 13 Mini',
    fr: 'iPhone 13 Mini',
  },
  'iphone-12-pro-max': {
    en: 'iPhone 12 Pro Max',
    fr: 'iPhone 12 Pro Max',
  },
  'iphone-12-pro': {
    en: 'iPhone 12 Pro',
    fr: 'iPhone 12 Pro',
  },
  'iphone-12': {
    en: 'iPhone 12',
    fr: 'iPhone 12',
  },
  'iphone-12-mini': {
    en: 'iPhone 12 Mini',
    fr: 'iPhone 12 Mini',
  },
  'iphone-11-pro-max': {
    en: 'iPhone 11 Pro Max',
    fr: 'iPhone 11 Pro Max',
  },
  'iphone-11-pro': {
    en: 'iPhone 11 Pro',
    fr: 'iPhone 11 Pro',
  },
  'iphone-11': {
    en: 'iPhone 11',
    fr: 'iPhone 11',
  },
  'iphone-xr': {
    en: 'iPhone XR',
    fr: 'iPhone XR',
  },
  'iPhone SE': {
    en: 'iPhone SE (2020)',
    fr: 'iPhone SE (2020)',
  },
  'iphone-xs-max': {
    en: 'iPhone XS Max',
    fr: 'iPhone XS Max',
  },
  'iphone-xs': {
    en: 'iPhone XS',
    fr: 'iPhone XS',
  },
  'iphone-x': {
    en: 'iPhone X',
    fr: 'iPhone X',
  },
  'iphone-8-plus': {
    en: 'iPhone 8 Plus',
    fr: 'iPhone 8 Plus',
  },
  'iphone-8': {
    en: 'iPhone 8',
    fr: 'iPhone 8',
  },
  'iphone-se': {
    en: 'iPhone SE (2020)',
    fr: 'iPhone SE (2020)',
  },
  'iphone-7-plus': {
    en: 'iPhone 7 Plus',
    fr: 'iPhone 7 Plus',
  },
  'iphone-7': {
    en: 'iPhone 7',
    fr: 'iPhone 7',
  },
  'iphone-6s-plus': {
    en: 'iPhone 6S Plus',
    fr: 'iPhone 6S Plus',
  },
  'iphone-6s': {
    en: 'iPhone 6S',
    fr: 'iPhone 6S',
  },
  'iphone-6-plus': {
    en: 'iPhone 6 Plus',
    fr: 'iPhone 6 Plus',
  },
  'iphone-6': {
    en: 'iPhone 6',
    fr: 'iPhone 6',
  },
  'iphone-se-(2016)': {
    en: 'iPhone SE (2016)',
    fr: 'iPhone SE (2016)',
  },
  'iphone-se-(2020)': {
    en: 'iPhone SE (2020)',
    fr: 'iPhone SE (2020)',
  },
  'iphone-5s': {
    en: 'iPhone 5S',
    fr: 'iPhone 5S',
  },
  'pixel-6-pro': {
    en: 'Pixel 6 Pro',
    fr: 'Pixel 6 Pro',
  },
  'pixel-6a': {
    en: 'Pixel 6a',
    fr: 'Pixel 6a',
  },
  'pixel-6': {
    en: 'Pixel 6',
    fr: 'Pixel 6',
  },
  'pixel-5a-5g': {
    en: 'Pixel 5a 5G',
    fr: 'Pixel 5a 5G',
  },
  'pixel-5': {
    en: 'Pixel 5',
    fr: 'Pixel 5',
  },
  'pixel-4-xl': {
    en: 'Pixel 4 XL',
    fr: 'Pixel 4 XL',
  },
  'pixel-4': {
    en: 'Pixel 4',
    fr: 'Pixel 4',
  },
  'pixel-4a': {
    en: 'Pixel 4a',
    fr: 'Pixel 4a',
  },
  'pixel-4a-5g': {
    en: 'Pixel 4a 5G',
    fr: 'Pixel 4a 5G',
  },
  'pixel-3a-xl': {
    en: 'Pixel 3a XL',
    fr: 'Pixel 3a XL',
  },
  'pixel-3a': {
    en: 'Pixel 3a',
    fr: 'Pixel 3a',
  },
  'pixel-3-xl': {
    en: 'Pixel 3 XL',
    fr: 'Pixel 3 XL',
  },
  'pixel-3': {
    en: 'Pixel 3',
    fr: 'Pixel 3',
  },
  'pixel-2-xl': {
    en: 'Pixel 2 XL',
    fr: 'Pixel 2 XL',
  },
  'pixel-2': {
    en: 'Pixel 2',
    fr: 'Pixel 2',
  },
  pixel: {
    en: 'Pixel',
    fr: 'Pixel',
  },
  'galaxy-s21-fe-5g': {
    en: 'Galaxy S21 FE 5G',
    fr: 'Galaxy S21 FE 5G',
  },
  'galaxy-s23-ultra-5g': {
    en: 'Galaxy S23 Ultra 5G',
    fr: 'Galaxy S23 Ultra 5G',
  },
  'galaxy-s23-plus-5g': {
    en: 'Galaxy S23 Plus 5G',
    fr: 'Galaxy S23 Plus 5G',
  },
  'pixel-8': {
    en: 'Pixel 8',
    fr: 'Pixel 8',
  },
  'pixel-7-pro': {
    en: 'Pixel 7 Pro',
    fr: 'Pixel 7 Pro',
  },
  'pixel-7a': {
    en: 'Pixel 7a',
    fr: 'Pixel 7a',
  },
  'pixel-7': {
    en: 'Pixel 7',
    fr: 'Pixel 7',
  },
  'galaxy-s23-fe': {
    en: 'Galaxy S23 FE',
    fr: 'Galaxy S23 FE',
  },
  'galaxy-s23-5g': {
    en: 'Galaxy S23 5G',
    fr: 'Galaxy S23 5G',
  },
  'galaxy-s22-ultra-5g': {
    en: 'Galaxy S22 Ultra 5G',
    fr: 'Galaxy S22 Ultra 5G',
  },
  'galaxy-s22-plus-5g': {
    en: 'Galaxy S22 Plus 5G',
    fr: 'Galaxy S22 Plus 5G',
  },
  'galaxy-s22-5g': {
    en: 'Galaxy S22 5G',
    fr: 'Galaxy S22 5G',
  },
  'galaxy-s21-ultra-5g': {
    en: 'Galaxy S21 Ultra 5G',
    fr: 'Galaxy S21 Ultra 5G',
  },
  'galaxy-s21-plus-5g': {
    en: 'Galaxy S21 Plus 5G',
    fr: 'Galaxy S21 Plus 5G',
  },
  'galaxy-s21-5g': {
    en: 'Galaxy S21 5G',
    fr: 'Galaxy S21 5G',
  },
  'galaxy-s20-ultra-5g': {
    en: 'Galaxy S20 Ultra 5G',
    fr: 'Galaxy S20 Ultra 5G',
  },
  'galaxy-s20-plus-5g': {
    en: 'Galaxy S20 Plus 5G',
    fr: 'Galaxy S20 Plus 5G',
  },
  'galaxy-s20-plus': {
    en: 'Galaxy S20 Plus',
    fr: 'Galaxy S20 Plus',
  },
  'galaxy-s20-fe-5g': {
    en: 'Galaxy S20 FE 5G',
    fr: 'Galaxy S20 FE 5G',
  },
  'galaxy-s20-5g': {
    en: 'Galaxy S20 5G',
    fr: 'Galaxy S20 5G',
  },
  'galaxy-s20': {
    en: 'Galaxy S20',
    fr: 'Galaxy S20',
  },
  'galaxy-s10-plus': {
    en: 'Galaxy S10 Plus',
    fr: 'Galaxy S10 Plus',
  },
  'galaxy-s10': {
    en: 'Galaxy S10',
    fr: 'Galaxy S10',
  },
  'galaxy-s9-plus': {
    en: 'Galaxy S9 Plus',
    fr: 'Galaxy S9 Plus',
  },
  'galaxy-s9': {
    en: 'Galaxy S9',
    fr: 'Galaxy S9',
  },
  'galaxy-s8-plus': {
    en: 'Galaxy S8 Plus',
    fr: 'Galaxy S8 Plus',
  },
  'galaxy-s8': {
    en: 'Galaxy S8',
    fr: 'Galaxy S8',
  },
  'galaxy-s7-edge': {
    en: 'Galaxy S7 Edge',
    fr: 'Galaxy S7 Edge',
  },
  'galaxy-s7': {
    en: 'Galaxy S7',
    fr: 'Galaxy S7',
  },
  'galaxy-s6-edge': {
    en: 'Galaxy S6 Edge',
    fr: 'Galaxy S6 Edge',
  },
  'galaxy-s6': {
    en: 'Galaxy S6',
    fr: 'Galaxy S6',
  },
  'galaxy-s5': {
    en: 'Galaxy S5',
    fr: 'Galaxy S5',
  },
  'galaxy-s4': {
    en: 'Galaxy S4',
    fr: 'Galaxy S4',
  },
  'ipad-pro-11-3rd-gen': {
    en: 'iPad Pro 11" 3rd Gen',
    fr: 'iPad Pro 3 - 11',
  },
  'ipad-pro-12.9-5th-gen': {
    en: 'iPad Pro 12.9" 5th Gen',
    fr: 'iPad Pro 5 - 12.9',
  },
  'ipad-pro-11-2nd-gen': {
    en: 'iPad Pro 11" 2nd Gen',
    fr: 'iPad Pro 2 - 11',
  },
  'ipad-pro-12.9-4th-gen': {
    en: 'iPad Pro 12.9" 4th Gen',
    fr: 'iPad Pro 4 - 12.9',
  },
  'ipad-pro-11-1st-gen': {
    en: 'iPad Pro 11" 1st Gen',
    fr: 'iPad Pro 1 - 11',
  },
  'ipad-pro-12.9-3rd-gen': {
    en: 'iPad Pro 12.9" 3rd Gen',
    fr: 'iPad Pro 3 - 12.9',
  },
  'ipad-pro-10.5-1st-gen': {
    en: 'iPad Pro 10.5" 1st Gen',
    fr: 'iPad Pro 1 - 10.5',
  },
  'ipad-pro-12.9-2nd-gen': {
    en: 'iPad Pro 12.9" 2nd Gen',
    fr: 'iPad Pro 2 - 12.9',
  },
  'ipad-pro-9.7-1st-gen': {
    en: 'iPad Pro 9.7" 1st Gen',
    fr: 'iPad Pro - 9.7',
  },
  'ipad-pro-12.9-1st-gen': {
    en: 'iPad Pro 12.9" 1st Gen',
    fr: 'iPad Pro - 12.9',
  },
  'ipad-mini-6th-gen': {
    en: 'iPad Mini 6th Gen',
    fr: 'iPad Mini 6',
  },
  'ipad-mini-5th-gen': {
    en: 'iPad Mini 5th Gen',
    fr: 'iPad Mini 5',
  },
  'ipad-mini-4th-gen': {
    en: 'iPad Mini 4th Gen',
    fr: 'iPad Mini 4',
  },
  'ipad-air-4th-gen': {
    en: 'iPad Air 4th Gen',
    fr: 'iPad Air 4',
  },
  'watch-se-40mm-2022': {
    en: 'Watch SE 40mm (2020)',
    fr: 'Watch SE 40mm (2020)',
  },
  'ipad-air-3rd-gen': {
    en: 'iPad Air 3rd Gen',
    fr: 'iPad Air 3',
  },
  'ipad-air-2nd-gen': {
    en: 'iPad Air 2nd Gen',
    fr: 'iPad Air 2',
  },
  'ipad-air-1st-gen': {
    en: 'iPad Air 1st Gen',
    fr: 'iPad Air',
  },
  'ipad-6th-gen': {
    en: 'iPad 6th Gen',
    fr: 'iPad 6',
  },
  'ipad-5th-gen': {
    en: 'iPad 5th Gen',
    fr: 'iPad 5',
  },
  'ipad-4th-gen': {
    en: 'iPad 4th Gen',
    fr: 'iPad 4',
  },
  'ipad-7th-gen': {
    en: 'iPad 7th Gen',
    fr: 'iPad 7',
  },
  'ipad-8th-gen': {
    en: 'iPad 8th Gen',
    fr: 'iPad 8',
  },
  'ipad-9th-gen': {
    en: 'iPad 9th Gen',
    fr: 'iPad 9',
  },
  'ipad-7': {
    en: 'iPad 7th Gen',
    fr: 'iPad 7',
  },
  'ipad-8': {
    en: 'iPad 8th Gen',
    fr: 'iPad 8',
  },
  'ipad-9': {
    en: 'iPad 9th Gen',
    fr: 'iPad 9',
  },
  'ipad-air-5': {
    en: 'iPad Air 5th Gen',
    fr: 'iPad Air 5',
  },
  'ipad-pro-3---11': {
    en: 'iPad Pro 11" 3rd Gen',
    fr: 'iPad Pro 3 - 11',
  },
  'ipad-pro-5---12.9': {
    en: 'iPad Pro 12.9" 5th Gen',
    fr: 'iPad Pro 5 - 12.9',
  },
  'ipad-pro-2---11': {
    en: 'iPad Pro 11" 2nd Gen',
    fr: 'iPad Pro 2 - 11',
  },
  'ipad-pro-4---12.9': {
    en: 'iPad Pro 12.9" 4th Gen',
    fr: 'iPad Pro 4 - 12.9',
  },
  'ipad-pro-1---11': {
    en: 'iPad Pro 11" 1st Gen',
    fr: 'iPad Pro 1 - 11',
  },
  'ipad-pro-3---12.9': {
    en: 'iPad Pro 12.9" 3rd Gen',
    fr: 'iPad Pro 3 - 12.9',
  },
  'ipad-pro-1---10.5': {
    en: 'iPad Pro 10.5" 1st Gen',
    fr: 'iPad Pro 1 - 10.5',
  },
  'ipad-pro-2---12.9': {
    en: 'iPad Pro 12.9" 2nd Gen',
    fr: 'iPad Pro 2 - 12.9',
  },
  'ipad-pro---9.7': {
    en: 'iPad Pro 9.7" 1st Gen',
    fr: 'iPad Pro - 9.7',
  },
  'ipad-pro---12.9': {
    en: 'iPad Pro 12.9" 1st Gen',
    fr: 'iPad Pro - 12.9',
  },
  'ipad-mini-6': {
    en: 'iPad Mini 6th Gen',
    fr: 'iPad Mini 6',
  },
  'ipad-mini-5': {
    en: 'iPad Mini 5th Gen',
    fr: 'iPad Mini 5',
  },
  'ipad-mini-4': {
    en: 'iPad Mini 4th Gen',
    fr: 'iPad Mini 4',
  },
  'ipad-air-4': {
    en: 'iPad Air 4th Gen',
    fr: 'iPad Air 4',
  },
  'ipad-air-3': {
    en: 'iPad Air 3rd Gen',
    fr: 'iPad Air 3',
  },
  'ipad-air-2': {
    en: 'iPad Air 2nd Gen',
    fr: 'iPad Air 2',
  },
  'ipad-air': {
    en: 'iPad Air 1st Gen',
    fr: 'iPad Air',
  },
  'ipad-6': {
    en: 'iPad 6th Gen',
    fr: 'iPad 6',
  },
  'ipad-5': {
    en: 'iPad 5th Gen',
    fr: 'iPad 5',
  },
  'ipad-4': {
    en: 'iPad 4th Gen',
    fr: 'iPad 4',
  },
  'older-ipads': {
    en: 'Older iPads',
    fr: 'Older iPads',
  },
  'older-pixels': {
    en: 'Pixel 3 & earlier',
    fr: 'Pixel 3 et antérieurs',
  },
  'older-samsungs': {
    en: 'Galaxy S9 & earlier',
    fr: 'Galaxy S9 et antérieurs',
  },
  'older-iphones': {
    en: 'iPhone 7 & earlier',
    fr: 'iPhone 7 et antérieurs',
  },
  '8gb': {
    en: '8 GB',
    fr: '8 Go',
  },
  '16gb': {
    en: '16 GB',
    fr: '16 Go',
  },
  '32gb': {
    en: '32 GB',
    fr: '32 Go',
  },
  '64gb': {
    en: '64 GB',
    fr: '64 Go',
  },
  '128gb': {
    en: '128 GB',
    fr: '128 Go',
  },
  '256gb': {
    en: '256 GB',
    fr: '256 Go',
  },
  '512gb': {
    en: '512 GB',
    fr: '512 Go',
  },
  '1024gb': {
    en: '1024 GB',
    fr: '1024 Go',
  },
  black: {
    en: 'Black',
    fr: 'Noir',
  },
  'space-grey': {
    en: 'Space-Grey',
    fr: 'Gris cosmique',
  },
  silver: {
    en: 'Silver',
    fr: 'Argent',
  },
  gold: {
    en: 'Gold',
    fr: 'Or',
  },
  green: {
    en: 'Green',
    fr: 'Vert',
  },
  blue: {
    en: 'Blue',
    fr: 'Bleu',
  },
  yellow: {
    en: 'Yellow',
    fr: 'Jaune',
  },
  red: {
    en: 'Red',
    fr: 'Rouge',
  },
  white: {
    en: 'White',
    fr: 'Blanc',
  },
  'rose-gold': {
    en: 'Rose Gold',
    fr: 'Rose Doré',
  },
  'jet-black': {
    en: 'Jet Black',
    fr: 'Noir foncé',
  },
  purple: {
    en: 'Purple',
    fr: 'Violet',
  },
  coral: {
    en: 'Coral',
    fr: 'Corail',
  },
  'black-pearl': {
    en: 'Black Pearl',
    fr: 'Perle noire',
  },
  pink: {
    en: 'Pink',
    fr: 'Rose',
  },
  'like-new': {
    en: 'Like New',
    fr: 'Comme neuf',
  },
  'very-good': {
    en: 'Very Good',
    fr: 'Très bon',
  },
  good: {
    en: 'Good',
    fr: 'Bon',
  },
  'wifi-and-cellular': {
    en: 'Wifi+Cellular',
    fr: 'Wifi+Cellulaire',
  },
  'wifi-only': {
    en: 'Wifi Only',
    fr: 'Accès Wi-Fi seulement',
  },
};

export default messages;
